import { makeStyles, Dropdown, Persona, Option } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { useWebexContactCentre } from "../services/webex/contactcenter/WebexContactCentreProvider";
import logger from "../utils/Logger";
const useStyles = makeStyles({
    aniSelectDropdown: {
        width: "100%",
    },
});
export const OutboundANISelector = (props) => {
    var _a, _b;
    const classes = useStyles();
    const webex = useWebexContactCentre();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [value, setValue] = useState("");
    useEffect(() => {
        if (selectedOptions.length > 0 || value.length > 0) {
            props.setHasOutboundNumberSelected(true);
        }
        else {
            props.setHasOutboundNumberSelected(false);
        }
    }, [selectedOptions, value]);
    useEffect(() => {
        const ani = webex.selectedOutboundANI;
        logger.debug("OutboundANISelector", `selectedOutboundANI changed to ${ani === null || ani === void 0 ? void 0 : ani.id}`);
        if (ani === null || ani === void 0 ? void 0 : ani.id) {
            setSelectedOptions([ani.id]);
            setValue(`${ani.name} (${ani.number})`);
        }
        else {
            setSelectedOptions([]);
            setValue("");
        }
    }, [(_a = webex.selectedOutboundANI) === null || _a === void 0 ? void 0 : _a.id, (_b = webex.outdialANIs) === null || _b === void 0 ? void 0 : _b.length]);
    const onOutboundANISelect = (ev, data) => {
        var _a;
        logger.debug("OutboundANISelector", `Will set ani to ${data.optionValue}`);
        setValue((_a = data.optionText) !== null && _a !== void 0 ? _a : "");
        webex.setSelectedOutboundANI(data.optionValue);
    };
    if (webex.outdialANIs.length > 0) {
        return (React.createElement(Dropdown, { placeholder: "Select an outbound caller ID number", className: classes.aniSelectDropdown, selectedOptions: selectedOptions, onOptionSelect: onOutboundANISelect, value: value }, webex.outdialANIs.map((ani) => (React.createElement(Option, { key: ani.id, text: `${ani.name} (${ani.number})`, value: ani.id },
            React.createElement(Persona, { name: ani.name, secondaryText: ani.number }))))));
    }
    else {
        return null;
    }
};
