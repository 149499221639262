import React from "react";
import { RecordCard } from "./RecordCard";
import { RecordsSection } from "./RecordsSection";
import { PersonRegular } from "@fluentui/react-icons";
import { EntityType } from "../../../services/dynamics/types/IDynamicsContext";
export const ContactsRecords = (props) => {
    var _a;
    const contacts = (_a = props.accounts) === null || _a === void 0 ? void 0 : _a.flatMap((account) => {
        const { name: accountName, accountnumber: accountNumber } = account;
        return account.contacts.map((contact) => (Object.assign(Object.assign({}, contact), { accountName,
            accountNumber })));
    });
    return (React.createElement(RecordsSection, { title: props.title }, contacts === null || contacts === void 0 ? void 0 : contacts.map((contact) => (React.createElement(RecordCard, { key: contact.contactid, title: contact.fullname, subtitle: `${contact.accountName} - (${contact.accountNumber})`, entityDisplayName: "Contact", entityType: EntityType.contact, entityId: contact.contactid, icon: React.createElement(PersonRegular, null), onOpenRecord: props.onOpenRecord })))));
};
