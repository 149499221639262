import React from "react";
import { CallRegular, PauseRegular } from "@fluentui/react-icons";
import { makeStyles, tokens } from "@fluentui/react-components";
const styles = makeStyles({
    currentCallTimer: {
        fontSize: "16px",
    },
    currentCallContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        alignItems: "center",
        verticalAlign: "middle",
    },
    callContolContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    currentCallTimerConatiner: {
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        alignItems: "flex-start",
    },
    iconInteractiveRed: {
        fontSize: "22px",
        color: tokens.colorPaletteRedBackground3,
        "&:hover": {
            cursor: "pointer",
        },
    },
    iconInteractiveGreen: {
        fontSize: "22px",
        color: tokens.colorPaletteGreenBackground3,
        "&:hover": {
            cursor: "pointer",
        },
    },
    iconInteractive: {
        fontSize: "22px",
        color: "rgb(61, 184, 56)",
    },
    iconRed: {
        fontSize: "22px",
        color: "rgb(209, 52, 56)",
    },
    icon: {
        fontSize: "22px",
        color: "rgb(61, 184, 56)",
    },
});
export const CallTimer = (props) => {
    const classes = styles();
    const [callDurationDisplay, setCallDurationDisplay] = React.useState("0:00");
    React.useEffect(() => {
        if (props.callState === "ringing") {
            setCallDurationDisplay("Ringing...");
            return;
        }
        if (!props.startTime) {
            setCallDurationDisplay("0:00");
            return;
        }
        const setDisplayFromTimestamp = () => {
            const startTimeNumber = props.timeOffset ? props.startTime + props.timeOffset : props.startTime;
            const callStart = new Date(startTimeNumber);
            const now = new Date();
            const diff = Math.max(0, now.getTime() - callStart.getTime());
            const hours = Math.floor(diff / 1000 / 60 / 60);
            const minutes = Math.floor((diff / 1000 / 60) % 60).toString();
            const seconds = Math.floor((diff / 1000) % 60)
                .toString()
                .padStart(2, "0");
            if (hours > 0) {
                setCallDurationDisplay(`${hours}:${minutes.padStart(2, "0")}:${seconds}`);
            }
            else {
                setCallDurationDisplay(`${minutes}:${seconds}`);
            }
        };
        setDisplayFromTimestamp();
        const interval = setInterval(setDisplayFromTimestamp, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [props.startTime, props.callState, props.timeOffset]);
    const icon = () => {
        if (props.callState === "held") {
            return React.createElement(PauseRegular, { className: classes.iconRed });
        }
        else {
            return React.createElement(CallRegular, { className: classes.icon });
        }
    };
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: classes.currentCallContainer },
            React.createElement("div", { className: classes.currentCallTimerConatiner },
                icon(),
                React.createElement("div", { className: classes.currentCallTimer }, callDurationDisplay)))));
};
