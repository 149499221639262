import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Dropdown, Text, PresenceBadge, Option, Spinner } from "@fluentui/react-components";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { AGENT_STATE, CALL_STATE } from "../../services/webex/contactcenter/models/Enums";
const styles = makeStyles({
    root: {
        width: "100%",
    },
    dropdown: {
        flexGrow: 1,
        width: "100%",
        marginRight: "10px",
    },
    statusContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "0.5rem",
        width: "100%",
        paddingLeft: "10px",
        boxSizing: "border-box",
    },
    spinnerContainer: {
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
    },
});
export const PresenseControl = (props) => {
    const classes = styles();
    const webex = useWebexContactCentre();
    const [isChangingPresence, setIsChangingPresence] = useState(false);
    useEffect(() => {
        if (webex.presence) {
            setIsChangingPresence(false);
        }
    }, [webex.presence]);
    const changePresence = (event, data) => {
        setIsChangingPresence(true);
        const selectedOption = webex.presenceOptions.find((option) => option.id === data.optionValue);
        if (!selectedOption) {
            console.error("Invalid presence option. Cannot be empty!");
            return;
        }
        webex.changePresence({
            newState: selectedOption.type,
            newAuxCodeId: selectedOption.id,
            newLastStateChangeReason: "",
        });
    };
    const selectedOptions = useCallback(() => {
        var _a;
        if (Array.isArray(webex.presenceOptions)) {
            const id = (_a = webex.presenceOptions.find((option) => { var _a; return option.id === ((_a = webex.presence) === null || _a === void 0 ? void 0 : _a.id); })) === null || _a === void 0 ? void 0 : _a.id;
            return id ? [id] : [];
        }
        else {
            return [];
        }
    }, [webex.presenceOptions, webex.presence]);
    const presenceBadgeStatus = () => {
        var _a, _b, _c;
        if (webex.presence.type === AGENT_STATE.AVAILABLE &&
            !(((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED || ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD || ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.WRAP_UP)) {
            return "available";
        }
        return "busy";
    };
    if (props.isWrapUp) {
        return (React.createElement("div", { className: classes.root },
            React.createElement("div", { className: classes.statusContainer },
                React.createElement(PresenceBadge, { status: presenceBadgeStatus() }),
                React.createElement(Dropdown, { appearance: "filled-darker", className: classes.dropdown, defaultValue: "Wrap Up", disabled: true }))));
    }
    const presenceSelectionDisabled = () => {
        var _a, _b, _c, _d, _e;
        return (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED ||
            ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD ||
            ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.CONFERENCE ||
            ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.CONSULT ||
            ((_e = webex.task) === null || _e === void 0 ? void 0 : _e.callState) === CALL_STATE.WRAP_UP);
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.statusContainer },
            React.createElement(PresenceBadge, { status: presenceBadgeStatus() }),
            React.createElement(Dropdown, { appearance: "filled-darker", className: classes.dropdown, value: isChangingPresence ? "loading" : webex.presence.name, selectedOptions: selectedOptions(), onOptionSelect: changePresence, disabled: presenceSelectionDisabled() },
                isChangingPresence && (React.createElement(Option, { id: "loading", className: classes.spinnerContainer, disabled: true, text: "Loading..." },
                    React.createElement(Spinner, { size: "tiny" }),
                    React.createElement(Text, null, "Loading..."))),
                webex.presenceOptions
                    .filter((o) => !o.hidden)
                    .map((option) => (React.createElement(Option, { key: option.id, value: option.id, text: option.name },
                    option.type === AGENT_STATE.AVAILABLE ? (React.createElement(PresenceBadge, { status: "available" })) : option.type === AGENT_STATE.IDLE ? (React.createElement(PresenceBadge, { status: "busy" })) : (React.createElement(PresenceBadge, { status: "offline" })),
                    React.createElement(Text, null, option.name),
                    " ")))))));
};
