export var DEVICE_TYPE;
(function (DEVICE_TYPE) {
    DEVICE_TYPE["EXTENSION"] = "EXTENSION";
    DEVICE_TYPE["BROWSER"] = "BROWSER";
    DEVICE_TYPE["AGENT_DN"] = "AGENT_DN";
})(DEVICE_TYPE || (DEVICE_TYPE = {}));
export var CONTACT_CENTRE_ROLE;
(function (CONTACT_CENTRE_ROLE) {
    CONTACT_CENTRE_ROLE["AGENT"] = "agent";
    CONTACT_CENTRE_ROLE["SUPERVISOR"] = "supervisor";
})(CONTACT_CENTRE_ROLE || (CONTACT_CENTRE_ROLE = {}));
export var AGENT_STATE;
(function (AGENT_STATE) {
    AGENT_STATE["AVAILABLE"] = "Available";
    AGENT_STATE["IDLE"] = "Idle";
})(AGENT_STATE || (AGENT_STATE = {}));
export var ON_HOLD_STATE;
(function (ON_HOLD_STATE) {
    ON_HOLD_STATE["ON_HOLD"] = "ON_HOLD";
    ON_HOLD_STATE["OFF_HOLD"] = "OFF_HOLD";
    ON_HOLD_STATE["LOADING_ON_HOLD"] = "LOADING_ON_HOLD";
    ON_HOLD_STATE["LOADING_OFF_HOLD"] = "LOADING_OFF_HOLD";
})(ON_HOLD_STATE || (ON_HOLD_STATE = {}));
export var CC_EVENTS;
(function (CC_EVENTS) {
    CC_EVENTS["Welcome"] = "Welcome";
    CC_EVENTS["StationLogin"] = "StationLogin";
    CC_EVENTS["AgentStateChange"] = "AgentStateChange";
    CC_EVENTS["Logout"] = "Logout";
    CC_EVENTS["RoutingMessage"] = "RoutingMessage";
    CC_EVENTS["AGENT_MULTI_LOGIN"] = "AGENT_MULTI_LOGIN";
    CC_EVENTS["AgentDesktopMessage"] = "AgentDesktopMessage";
    CC_EVENTS["AgentReloginSuccess"] = "AgentReloginSuccess";
    CC_EVENTS["close"] = "close";
})(CC_EVENTS || (CC_EVENTS = {}));
export var PHONE_STATE;
(function (PHONE_STATE) {
    PHONE_STATE["WAIT_START"] = "WAIT_START";
    PHONE_STATE["AGENT_DN_NOT_REGISTERED"] = "AGENT_DN_NOT_REGISTERED";
    PHONE_STATE["IDLE"] = "idle";
    PHONE_STATE["ACTIVE"] = "active";
    PHONE_STATE["NOT_LOGGED_IN"] = "notLoggedIn";
    PHONE_STATE["INITIALISING"] = "loading";
    PHONE_STATE["INIT_ERROR"] = "initError";
    PHONE_STATE["INACTIVE"] = "inactive";
    PHONE_STATE["AGENT_INFO_REQUIRED"] = "AGENT_INFO_REQUIRED";
    PHONE_STATE["AGENT_SESSION_ALREADY_EXISTS"] = "AGENT_SESSION_ALREADY_EXISTS";
    PHONE_STATE["AGENT_MULTI_LOGIN"] = "AGENT_MULTI_LOGIN";
    PHONE_STATE["WEBSOCKET_EXISTS"] = "WEBSOCKET_EXISTS";
})(PHONE_STATE || (PHONE_STATE = {}));
export var TRANSFER_RECIPIENT_TYPE;
(function (TRANSFER_RECIPIENT_TYPE) {
    TRANSFER_RECIPIENT_TYPE["AGENT"] = "agent";
    TRANSFER_RECIPIENT_TYPE["QUEUE"] = "queue";
    TRANSFER_RECIPIENT_TYPE["DIAL_NUMBER"] = "dialNumber";
})(TRANSFER_RECIPIENT_TYPE || (TRANSFER_RECIPIENT_TYPE = {}));
export var CALL_STATE;
(function (CALL_STATE) {
    CALL_STATE["INBOUND_RINGING"] = "InboundRinging";
    CALL_STATE["OUTBOUND_RINGING"] = "OutboundRinging";
    CALL_STATE["CONNECTED"] = "Connected";
    CALL_STATE["WRAP_UP"] = "WrapUp";
    CALL_STATE["WRAPPED_UP"] = "WrappedUp";
    CALL_STATE["HELD"] = "Held";
    CALL_STATE["ENDED"] = "Ended";
    CALL_STATE["CONSULT"] = "Consult";
    CALL_STATE["CONFERENCE"] = "Conference";
})(CALL_STATE || (CALL_STATE = {}));
