var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// src/services/webex/contactcenter/WebSocket/WebSocket.ts
import logger from "../../../../utils/Logger";
export class WebexWebSocket {
    constructor(webSocketUrl) {
        this.eventListeners = new Map();
        this.url = webSocketUrl;
    }
    // Establish WebSocket connection and set up event listeners
    subscribeAndConnect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.ws = new globalThis.WebSocket(this.url);
            return new Promise((resolve, reject) => {
                this.ws.onopen = () => {
                    logger.info("WebexWebSocket", "WebSocket connection opened.");
                    this.startKeepAlive();
                    resolve();
                };
                this.ws.onmessage = (message) => {
                    const event = JSON.parse(message.data);
                    this.triggerEvent(event.type, event);
                };
                this.ws.onerror = (error) => {
                    logger.error("WebexWebSocket", "WebSocket error:", error);
                    this.triggerEvent("error", { type: "error", error });
                    reject(error);
                };
                this.ws.onclose = () => {
                    logger.info("WebexWebSocket", "WebSocket connection closed.");
                    this.stopKeepAlive();
                    this.triggerEvent("close", { type: "close" });
                };
            });
        });
    }
    startKeepAlive() {
        this.keepAliveInterval = setInterval(() => {
            if (this.ws && this.ws.readyState === globalThis.WebSocket.OPEN) {
                this.ws.send(JSON.stringify({ keepalive: true }));
            }
        }, 4000);
    }
    stopKeepAlive() {
        if (this.keepAliveInterval) {
            clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = undefined;
        }
    }
    on(event, callback) {
        if (!this.eventListeners.has(event)) {
            this.eventListeners.set(event, []);
        }
        this.eventListeners.get(event).push(callback);
    }
    off(event, callback) {
        if (this.eventListeners.has(event)) {
            const listeners = this.eventListeners.get(event).filter((cb) => cb !== callback);
            this.eventListeners.set(event, listeners);
        }
    }
    isConnected() {
        var _a;
        return ((_a = this.ws) === null || _a === void 0 ? void 0 : _a.readyState) === globalThis.WebSocket.OPEN;
    }
    disconnectWebSocket() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                if (!this.ws) {
                    return resolve();
                }
                this.ws.onclose = () => {
                    logger.info("WebexWebSocket", "WebSocket connection closed.");
                    this.stopKeepAlive();
                    resolve();
                };
                this.ws.onerror = (error) => {
                    logger.error("WebexWebSocket", "Error closing WebSocket:", error);
                    reject(error);
                };
                this.ws.close();
                this.ws = undefined;
            });
        });
    }
    triggerEvent(eventType, event) {
        if (eventType) {
            logger.debug("WebexWebSocket", "WebSocket event:", eventType, event);
        }
        if (this.eventListeners.has(eventType)) {
            this.eventListeners.get(eventType).forEach((callback) => callback(event));
        }
    }
}
