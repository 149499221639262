/**
 * Enumeration of possible events for the communication interface.
 */
export var CIF_EVENT;
(function (CIF_EVENT) {
    /**
     * The event is invoked when the outbound communication (ClickToAct) field is enabled.
     */
    CIF_EVENT["OnClickToAct"] = "onclicktoact";
    /**
     * The event is invoked when the panel mode is manually toggled between Minimized (0) and Docked (1).
     */
    CIF_EVENT["OnModeChanged"] = "onmodechanged";
    /**
     * The event is invoked when the panel size is manually changed by dragging.
     */
    CIF_EVENT["OnSizeChanged"] = "onsizechanged";
    /**
     * The event is triggered before a navigation event occurs on the main page.
     */
    CIF_EVENT["OnPageNavigate"] = "onpagenavigate";
    /**
     * The event is invoked when the user selects the Send button on the Knowledge Base (KB) control.
     */
    CIF_EVENT["OnSendKBArticle"] = "onsendkbarticle";
})(CIF_EVENT || (CIF_EVENT = {}));
