import React, { useState } from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { CopyRegular, CheckmarkCircleFilled } from "@fluentui/react-icons";
import { CONTACT_DIRECTION } from "../../services/webex/contactcenter/models/IRoutingMessage";
import logger from "../../utils/Logger";
import { AptosFontFamily } from "../../utils/CustomTokens";
const styles = makeStyles({
    callInfoContainer: {
        fontFamily: AptosFontFamily,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "ellipsis",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    callInfoRow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: "0.1rem",
        "&:last-child": {
            marginBottom: "0",
        },
    },
    callInfoRowNarrow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: "0rem",
    },
    callInfoLabel: {
        fontWeight: "normal",
        minWidth: "80px",
        color: tokens.colorBrandForeground1,
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },
    callInfoValue: {
        flexGrow: 1,
        paddingLeft: "10px",
        display: "flex",
        alignItems: "center",
    },
    copyIcon: {
        cursor: "pointer",
        marginLeft: "10px",
    },
    copyIconClicked: {
        color: "green",
        marginLeft: "10px",
    },
});
const handleCopyToClipboard = (text, onSuccess) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
        logger.log("ActiveCallDetails", `Copied to clipboard: ${text}`);
        onSuccess();
    })
        .catch((error) => {
        logger.error("ActiveCallDetails", "Failed to copy to clipboard", error);
    });
};
export const ActiveCallDetails = (props) => {
    const classes = styles();
    const [isCopiedCallerId, setIsCopiedCallerId] = useState(false);
    const [isCopiedCustomerAccount, setIsCopiedCustomerAccount] = useState(false);
    const callInfoRowClass = props.isHeldCallSummary ? classes.callInfoRowNarrow : classes.callInfoRow;
    // Function to handle the copy action and temporarily change the icon
    const handleCopy = (text, setCopiedState) => {
        handleCopyToClipboard(text, () => {
            setCopiedState(true);
            setTimeout(() => {
                setCopiedState(false);
            }, 2000);
        });
    };
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: classes.callInfoContainer },
            props.callDirection === CONTACT_DIRECTION.OUTBOUND && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Call Type:"),
                React.createElement("div", { className: classes.callInfoValue }, props.callDirection))),
            React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, props.isConsultLeg ? "Consult To:" : props.callDirection === CONTACT_DIRECTION.INBOUND ? "Call From:" : "Call To:"),
                React.createElement("div", { className: classes.callInfoValue },
                    props.callerId,
                    props.callerId &&
                        (isCopiedCallerId ? (React.createElement(CheckmarkCircleFilled, { className: classes.copyIconClicked })) : (React.createElement(CopyRegular, { className: classes.copyIcon, onClick: () => handleCopy(props.callerId, setIsCopiedCallerId) }))))),
            props.callDirection === CONTACT_DIRECTION.INBOUND && !props.isHeldCallSummary && props.callFor && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Call To:"),
                React.createElement("div", { className: classes.callInfoValue }, props.callFor))),
            props.toDescription && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Description:"),
                React.createElement("div", { className: classes.callInfoValue }, props.toDescription))),
            props.customerDescription && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Customer:"),
                React.createElement("div", { className: classes.callInfoValue }, props.customerDescription))),
            props.customerAccount && !props.isHeldCallSummary && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Customer Account:"),
                React.createElement("div", { className: classes.callInfoValue },
                    props.customerAccount,
                    isCopiedCustomerAccount ? (React.createElement(CheckmarkCircleFilled, { className: classes.copyIconClicked })) : (React.createElement(CopyRegular, { className: classes.copyIcon, onClick: () => handleCopy(props.customerAccount, setIsCopiedCustomerAccount) }))))),
            props.customerBranch && !props.isHeldCallSummary && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Customer Assigned Branch:"),
                React.createElement("div", { className: classes.callInfoValue }, props.customerBranch))))));
};
