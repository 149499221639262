import { v4 as uuid } from 'uuid';
import { ERROR_CODE } from '../../Errors/types';
import { emitFinalFailure, handleRegistrationErrors } from '../../common';
import { METRIC_EVENT, METRIC_TYPE, REG_ACTION } from '../../Metrics/types';
import { getMetricManager } from '../../Metrics';
import { getCallManager } from '../calling';
import log from '../../Logger';
import SDKConnector from '../../SDKConnector';
import { ALLOWED_SERVICES, HTTP_METHODS, RegistrationStatus, ServiceIndicator, } from '../../common/types';
import { CALLING_USER_AGENT, CISCO_DEVICE_URL, DEVICES_ENDPOINT_RESOURCE, SPARK_USER_AGENT, WEBEX_WEB_CLIENT, BASE_REG_RETRY_TIMER_VAL_IN_SEC, BASE_REG_TIMER_MFACTOR, SEC_TO_MSEC_MFACTOR, REG_RANDOM_T_FACTOR_UPPER_LIMIT, REG_TRY_BACKUP_TIMER_VAL_IN_SEC, MINUTES_TO_SEC_MFACTOR, FAILBACK_429_RETRY_UTIL, REG_FAILBACK_429_MAX_RETRIES, FAILBACK_UTIL, REGISTRATION_FILE, DEFAULT_REHOMING_INTERVAL_MIN, DEFAULT_REHOMING_INTERVAL_MAX, DEFAULT_KEEPALIVE_INTERVAL, REG_TRY_BACKUP_TIMER_VAL_FOR_CC_IN_SEC, } from '../constants';
import { LINE_EVENTS } from '../line/types';
export class Registration {
    sdkConnector;
    webex;
    userId = '';
    serviceData;
    failback429RetryAttempts;
    registrationStatus;
    failbackTimer;
    activeMobiusUrl;
    keepaliveTimer;
    rehomingIntervalMin;
    rehomingIntervalMax;
    mutex;
    metricManager;
    lineEmitter;
    callManager;
    deviceInfo = {};
    primaryMobiusUris;
    backupMobiusUris;
    registerRetry = false;
    reconnectPending = false;
    jwe;
    isCCFlow = false;
    failoverImmediately = false;
    constructor(webex, serviceData, mutex, lineEmitter, logLevel, jwe) {
        this.jwe = jwe;
        this.sdkConnector = SDKConnector;
        this.serviceData = serviceData;
        this.isCCFlow = serviceData.indicator === ServiceIndicator.CONTACT_CENTER;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.userId = this.webex.internal.device.userId;
        this.registrationStatus = RegistrationStatus.IDLE;
        this.failback429RetryAttempts = 0;
        log.setLogger(logLevel, REGISTRATION_FILE);
        this.rehomingIntervalMin = DEFAULT_REHOMING_INTERVAL_MIN;
        this.rehomingIntervalMax = DEFAULT_REHOMING_INTERVAL_MAX;
        this.mutex = mutex;
        this.callManager = getCallManager(this.webex, serviceData.indicator);
        this.metricManager = getMetricManager(this.webex, serviceData.indicator);
        this.lineEmitter = lineEmitter;
        this.primaryMobiusUris = [];
        this.backupMobiusUris = [];
    }
    getActiveMobiusUrl() {
        return this.activeMobiusUrl;
    }
    setActiveMobiusUrl(url) {
        log.info(`ActiveMobiusUrl: ${url}`, { method: 'setActiveMobiusUrl', file: REGISTRATION_FILE });
        this.activeMobiusUrl = url;
        this.callManager.updateActiveMobius(url);
    }
    setMobiusServers(primaryMobiusUris, backupMobiusUris) {
        this.primaryMobiusUris = primaryMobiusUris;
        this.backupMobiusUris = backupMobiusUris;
    }
    async postKeepAlive(url) {
        return this.webex.request({
            uri: `${url}/status`,
            method: HTTP_METHODS.POST,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            service: ALLOWED_SERVICES.MOBIUS,
        });
    }
    async deleteRegistration(url, deviceId, deviceUrl) {
        let response;
        try {
            response = await fetch(`${url}${DEVICES_ENDPOINT_RESOURCE}/${deviceId}`, {
                method: HTTP_METHODS.DELETE,
                headers: {
                    [CISCO_DEVICE_URL]: deviceUrl,
                    Authorization: await this.webex.credentials.getUserToken(),
                    trackingId: `${WEBEX_WEB_CLIENT}_${uuid()}`,
                    [SPARK_USER_AGENT]: CALLING_USER_AGENT,
                },
            });
        }
        catch (error) {
            log.warn(`Delete failed with Mobius`, {});
        }
        this.setStatus(RegistrationStatus.INACTIVE);
        this.lineEmitter(LINE_EVENTS.UNREGISTERED);
        return response?.json();
    }
    async postRegistration(url) {
        const deviceInfo = {
            userId: this.userId,
            clientDeviceUri: this.webex.internal.device.url,
            serviceData: this.jwe ? { ...this.serviceData, jwe: this.jwe } : this.serviceData,
        };
        return this.webex.request({
            uri: `${url}device`,
            method: HTTP_METHODS.POST,
            headers: {
                [CISCO_DEVICE_URL]: deviceInfo.clientDeviceUri,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: deviceInfo,
            service: ALLOWED_SERVICES.MOBIUS,
        });
    }
    async restorePreviousRegistration(caller) {
        let abort = false;
        if (this.activeMobiusUrl) {
            abort = await this.attemptRegistrationWithServers(caller, [this.activeMobiusUrl]);
        }
        return abort;
    }
    async scheduleFailback429Retry() {
        if (this.failback429RetryAttempts >= REG_FAILBACK_429_MAX_RETRIES) {
            return;
        }
        this.clearFailbackTimer();
        this.failback429RetryAttempts += 1;
        log.log(`Received 429 while rehoming, 429 retry count : ${this.failback429RetryAttempts}`, {
            file: REGISTRATION_FILE,
            method: FAILBACK_429_RETRY_UTIL,
        });
        const interval = this.getRegRetryInterval(this.failback429RetryAttempts);
        this.startFailbackTimer(interval);
        const abort = await this.restorePreviousRegistration(FAILBACK_429_RETRY_UTIL);
        if (!abort && !this.isDeviceRegistered()) {
            await this.restartRegistration(FAILBACK_429_RETRY_UTIL);
        }
    }
    getRegRetryInterval(attempt = 1) {
        return (BASE_REG_RETRY_TIMER_VAL_IN_SEC +
            BASE_REG_TIMER_MFACTOR ** attempt +
            Math.floor((Math.random() * (REG_RANDOM_T_FACTOR_UPPER_LIMIT - SEC_TO_MSEC_MFACTOR + 1) +
                SEC_TO_MSEC_MFACTOR) /
                SEC_TO_MSEC_MFACTOR));
    }
    async startFailoverTimer(attempt = 1, timeElapsed = 0) {
        const loggerContext = {
            file: REGISTRATION_FILE,
            method: this.startFailoverTimer.name,
        };
        let interval = this.getRegRetryInterval(attempt);
        const TIMER_THRESHOLD = this.isCCFlow
            ? REG_TRY_BACKUP_TIMER_VAL_FOR_CC_IN_SEC
            : REG_TRY_BACKUP_TIMER_VAL_IN_SEC;
        if (timeElapsed + interval > TIMER_THRESHOLD) {
            const excessVal = timeElapsed + interval - TIMER_THRESHOLD;
            interval -= excessVal;
        }
        let abort;
        if (interval > BASE_REG_RETRY_TIMER_VAL_IN_SEC && !this.failoverImmediately) {
            const scheduledTime = Math.floor(Date.now() / 1000);
            setTimeout(async () => {
                await this.mutex.runExclusive(async () => {
                    abort = await this.attemptRegistrationWithServers(this.startFailoverTimer.name);
                    const currentTime = Math.floor(Date.now() / 1000);
                    if (!abort && !this.isDeviceRegistered()) {
                        await this.startFailoverTimer(attempt + 1, timeElapsed + (currentTime - scheduledTime));
                    }
                });
            }, interval * SEC_TO_MSEC_MFACTOR);
            log.log(`Scheduled retry with primary in ${interval} seconds, number of attempts : ${attempt}`, loggerContext);
        }
        else if (this.backupMobiusUris.length) {
            log.log('Failing over to backup servers.', loggerContext);
            this.failoverImmediately = false;
            abort = await this.attemptRegistrationWithServers(this.startFailoverTimer.name, this.backupMobiusUris);
            if (!abort && !this.isDeviceRegistered()) {
                interval = this.getRegRetryInterval();
                setTimeout(async () => {
                    await this.mutex.runExclusive(async () => {
                        abort = await this.attemptRegistrationWithServers(this.startFailoverTimer.name, this.backupMobiusUris);
                        if (!abort && !this.isDeviceRegistered()) {
                            emitFinalFailure((clientError) => {
                                this.lineEmitter(LINE_EVENTS.ERROR, undefined, clientError);
                            }, loggerContext);
                        }
                    });
                }, interval * SEC_TO_MSEC_MFACTOR);
                log.log(`Scheduled retry with backup servers in ${interval} seconds.`, loggerContext);
            }
        }
        else {
            emitFinalFailure((clientError) => {
                this.lineEmitter(LINE_EVENTS.ERROR, undefined, clientError);
            }, loggerContext);
        }
    }
    clearFailbackTimer() {
        if (this.failbackTimer) {
            clearTimeout(this.failbackTimer);
            this.failbackTimer = undefined;
        }
    }
    isFailbackRequired() {
        return this.isDeviceRegistered() && this.primaryMobiusUris.indexOf(this.activeMobiusUrl) === -1;
    }
    getFailbackInterval() {
        return Math.floor(Math.random() * (this.rehomingIntervalMax - this.rehomingIntervalMin + 1) +
            this.rehomingIntervalMin);
    }
    initiateFailback() {
        if (this.isFailbackRequired()) {
            if (!this.failbackTimer) {
                this.failback429RetryAttempts = 0;
                const intervalInMinutes = this.getFailbackInterval();
                this.startFailbackTimer(intervalInMinutes * MINUTES_TO_SEC_MFACTOR);
            }
        }
        else {
            this.failback429RetryAttempts = 0;
            this.clearFailbackTimer();
        }
    }
    startFailbackTimer(intervalInSeconds) {
        this.failbackTimer = setTimeout(async () => this.executeFailback(), intervalInSeconds * SEC_TO_MSEC_MFACTOR);
        log.log(`Failback scheduled after ${intervalInSeconds} seconds.`, {
            file: REGISTRATION_FILE,
            method: this.startFailbackTimer.name,
        });
    }
    async executeFailback() {
        await this.mutex.runExclusive(async () => {
            if (this.isFailbackRequired()) {
                if (Object.keys(this.callManager.getActiveCalls()).length === 0) {
                    log.info(`Attempting failback to primary.`, {
                        file: REGISTRATION_FILE,
                        method: this.executeFailback.name,
                    });
                    await this.deregister();
                    const abort = await this.attemptRegistrationWithServers(FAILBACK_UTIL);
                    if (!abort && !this.isDeviceRegistered()) {
                        const abortNew = await this.restorePreviousRegistration(FAILBACK_UTIL);
                        if (abortNew) {
                            this.clearFailbackTimer();
                            return;
                        }
                        if (!this.isDeviceRegistered()) {
                            await this.restartRegistration(this.executeFailback.name);
                        }
                        else {
                            this.failbackTimer = undefined;
                            this.initiateFailback();
                        }
                    }
                }
                else {
                    log.info('Active calls present, deferring failback to next cycle.', {
                        file: REGISTRATION_FILE,
                        method: this.executeFailback.name,
                    });
                    this.failbackTimer = undefined;
                    this.initiateFailback();
                }
            }
        });
    }
    setIntervalValues(deviceInfo) {
        if (this.primaryMobiusUris.indexOf(this.activeMobiusUrl) !== -1) {
            this.rehomingIntervalMin = deviceInfo?.rehomingIntervalMin
                ? deviceInfo.rehomingIntervalMin
                : DEFAULT_REHOMING_INTERVAL_MIN;
            this.rehomingIntervalMax = deviceInfo?.rehomingIntervalMax
                ? deviceInfo.rehomingIntervalMax
                : DEFAULT_REHOMING_INTERVAL_MAX;
        }
    }
    getDeviceInfo() {
        return this.deviceInfo;
    }
    isDeviceRegistered() {
        return this.registrationStatus === RegistrationStatus.ACTIVE;
    }
    getStatus() {
        return this.registrationStatus;
    }
    setStatus(value) {
        this.registrationStatus = value;
    }
    async restartRegistration(caller) {
        this.clearFailbackTimer();
        this.failback429RetryAttempts = 0;
        const abort = await this.attemptRegistrationWithServers(caller, this.primaryMobiusUris);
        if (!abort && !this.isDeviceRegistered()) {
            await this.startFailoverTimer();
        }
    }
    async handleConnectionRestoration(retry) {
        await this.mutex.runExclusive(async () => {
            if (retry) {
                log.info('Mercury connection is up again, re-registering with Webex Calling if needed', {
                    file: REGISTRATION_FILE,
                    method: this.handleConnectionRestoration.name,
                });
                this.clearKeepaliveTimer();
                if (this.isDeviceRegistered()) {
                    await this.deregister();
                }
                if (this.activeMobiusUrl) {
                    const abort = await this.restorePreviousRegistration(this.handleConnectionRestoration.name);
                    if (!abort && !this.isDeviceRegistered()) {
                        await this.restartRegistration(this.handleConnectionRestoration.name);
                    }
                }
                retry = false;
            }
        });
        return retry;
    }
    restoreRegistrationCallBack() {
        return async (restoreData, caller) => {
            const logContext = { file: REGISTRATION_FILE, method: caller };
            if (!this.isRegRetry()) {
                log.info('Registration restoration in progress.', logContext);
                const restore = this.getExistingDevice(restoreData);
                if (restore) {
                    this.setRegRetry(true);
                    await this.deregister();
                    const finalError = await this.restorePreviousRegistration(caller);
                    this.setRegRetry(false);
                    if (this.isDeviceRegistered()) {
                        log.info('Registration restored successfully.', logContext);
                    }
                    return finalError;
                }
                this.lineEmitter(LINE_EVENTS.UNREGISTERED);
            }
            else {
                this.lineEmitter(LINE_EVENTS.UNREGISTERED);
            }
            return false;
        };
    }
    async triggerRegistration() {
        if (this.primaryMobiusUris.length > 0) {
            const abort = await this.attemptRegistrationWithServers(this.triggerRegistration.name, this.primaryMobiusUris);
            if (!this.isDeviceRegistered() && !abort) {
                await this.startFailoverTimer();
            }
        }
    }
    async attemptRegistrationWithServers(caller, servers = this.primaryMobiusUris) {
        let abort = false;
        if (this.failoverImmediately) {
            return abort;
        }
        if (this.isDeviceRegistered()) {
            log.log(`[${caller}] : Device already registered with : ${this.activeMobiusUrl}`, {
                file: REGISTRATION_FILE,
                method: this.attemptRegistrationWithServers.name,
            });
            return abort;
        }
        for (const url of servers) {
            try {
                abort = false;
                this.registrationStatus = RegistrationStatus.INACTIVE;
                this.lineEmitter(LINE_EVENTS.CONNECTING);
                log.log(`[${caller}] : Mobius url to contact: ${url}`, {
                    file: REGISTRATION_FILE,
                    method: this.attemptRegistrationWithServers.name,
                });
                const resp = await this.postRegistration(url);
                this.deviceInfo = resp.body;
                this.registrationStatus = RegistrationStatus.ACTIVE;
                this.lineEmitter(LINE_EVENTS.REGISTERED, resp.body);
                this.setActiveMobiusUrl(url);
                this.setIntervalValues(this.deviceInfo);
                this.metricManager.setDeviceInfo(this.deviceInfo);
                this.metricManager.submitRegistrationMetric(METRIC_EVENT.REGISTRATION, REG_ACTION.REGISTER, METRIC_TYPE.BEHAVIORAL, undefined);
                this.startKeepaliveTimer(this.deviceInfo.device?.uri, this.deviceInfo.keepaliveInterval);
                this.initiateFailback();
                break;
            }
            catch (err) {
                const body = err;
                abort = await handleRegistrationErrors(body, (clientError, finalError) => {
                    if (finalError) {
                        this.lineEmitter(LINE_EVENTS.ERROR, undefined, clientError);
                    }
                    else {
                        this.lineEmitter(LINE_EVENTS.UNREGISTERED);
                    }
                    this.metricManager.submitRegistrationMetric(METRIC_EVENT.REGISTRATION_ERROR, REG_ACTION.REGISTER, METRIC_TYPE.BEHAVIORAL, clientError);
                }, { method: this.attemptRegistrationWithServers.name, file: REGISTRATION_FILE }, this.restoreRegistrationCallBack());
                if (this.registrationStatus === RegistrationStatus.ACTIVE) {
                    log.info(`[${caller}] : Device is already restored, active mobius url: ${this.activeMobiusUrl}`, {
                        file: REGISTRATION_FILE,
                        method: this.attemptRegistrationWithServers.name,
                    });
                    break;
                }
                if (abort) {
                    this.setStatus(RegistrationStatus.INACTIVE);
                    break;
                }
                else if (caller === this.executeFailback.name) {
                    const error = body.statusCode;
                    if (error === ERROR_CODE.TOO_MANY_REQUESTS) {
                        await this.scheduleFailback429Retry();
                        abort = true;
                        break;
                    }
                }
            }
        }
        return abort;
    }
    startKeepaliveTimer(url, interval) {
        let keepAliveRetryCount = 0;
        this.clearKeepaliveTimer();
        const RETRY_COUNT_THRESHOLD = this.isCCFlow ? 4 : 5;
        this.keepaliveTimer = setInterval(async () => {
            const logContext = {
                file: REGISTRATION_FILE,
                method: this.startKeepaliveTimer.name,
            };
            await this.mutex.runExclusive(async () => {
                if (this.isDeviceRegistered() && keepAliveRetryCount < RETRY_COUNT_THRESHOLD) {
                    try {
                        const res = await this.postKeepAlive(url);
                        log.info(`Sent Keepalive, status: ${res.statusCode}`, logContext);
                        if (keepAliveRetryCount > 0) {
                            this.lineEmitter(LINE_EVENTS.RECONNECTED);
                        }
                        keepAliveRetryCount = 0;
                    }
                    catch (err) {
                        keepAliveRetryCount += 1;
                        const error = err;
                        log.warn(`Keep-alive missed ${keepAliveRetryCount} times. Status -> ${error.statusCode} `, logContext);
                        const abort = await handleRegistrationErrors(error, (clientError, finalError) => {
                            if (finalError) {
                                this.lineEmitter(LINE_EVENTS.ERROR, undefined, clientError);
                            }
                            this.metricManager.submitRegistrationMetric(METRIC_EVENT.REGISTRATION, REG_ACTION.KEEPALIVE_FAILURE, METRIC_TYPE.BEHAVIORAL, clientError);
                        }, { method: this.startKeepaliveTimer.name, file: REGISTRATION_FILE });
                        if (abort || keepAliveRetryCount >= RETRY_COUNT_THRESHOLD) {
                            this.failoverImmediately = this.isCCFlow;
                            this.setStatus(RegistrationStatus.INACTIVE);
                            this.clearKeepaliveTimer();
                            this.clearFailbackTimer();
                            this.lineEmitter(LINE_EVENTS.UNREGISTERED);
                            if (!abort) {
                                await this.reconnectOnFailure(this.startKeepaliveTimer.name);
                            }
                        }
                        else {
                            this.lineEmitter(LINE_EVENTS.RECONNECTING);
                        }
                    }
                }
            });
        }, interval * 1000);
    }
    clearKeepaliveTimer() {
        if (this.keepaliveTimer) {
            clearInterval(this.keepaliveTimer);
            this.keepaliveTimer = undefined;
        }
    }
    isReconnectPending() {
        return this.reconnectPending;
    }
    async deregister() {
        try {
            await this.deleteRegistration(this.activeMobiusUrl, this.deviceInfo.device?.deviceId, this.deviceInfo.device?.clientDeviceUri);
        }
        catch (err) {
            log.warn(`Delete failed with Mobius`, {});
        }
        this.clearKeepaliveTimer();
        this.setStatus(RegistrationStatus.INACTIVE);
    }
    isRegRetry() {
        return this.registerRetry;
    }
    setRegRetry(value) {
        this.registerRetry = value;
    }
    getExistingDevice(restoreData) {
        if (restoreData.devices && restoreData.devices.length > 0) {
            this.deviceInfo = {
                userId: restoreData.userId,
                device: restoreData.devices[0],
                keepaliveInterval: DEFAULT_KEEPALIVE_INTERVAL,
                rehomingIntervalMax: DEFAULT_REHOMING_INTERVAL_MAX,
                rehomingIntervalMin: DEFAULT_REHOMING_INTERVAL_MIN,
            };
            const stringToReplace = `${DEVICES_ENDPOINT_RESOURCE}/${restoreData.devices[0].deviceId}`;
            const uri = restoreData.devices[0].uri.replace(stringToReplace, '');
            this.setActiveMobiusUrl(uri);
            this.registrationStatus = RegistrationStatus.ACTIVE;
            return true;
        }
        return false;
    }
    async reconnectOnFailure(caller) {
        this.reconnectPending = false;
        if (!this.isDeviceRegistered()) {
            if (Object.keys(this.callManager.getActiveCalls()).length === 0) {
                const abort = await this.restorePreviousRegistration(caller);
                if (!abort && !this.isDeviceRegistered()) {
                    await this.restartRegistration(caller);
                }
            }
            else {
                this.reconnectPending = true;
                log.info('Active call(s) present, deferred reconnect till call cleanup.', {
                    file: REGISTRATION_FILE,
                    method: this.reconnectOnFailure.name,
                });
            }
        }
    }
}
export const createRegistration = (webex, serviceData, mutex, lineEmitter, logLevel, jwe) => new Registration(webex, serviceData, mutex, lineEmitter, logLevel, jwe);
