import React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { CONTACT_DIRECTION, MEDIA_MTYPE } from "../../services/webex/contactcenter/models/IRoutingMessage";
import { CallTimer } from "./CallTimer";
import { ActiveCallDetails } from "./ActiveCallDetails";
const styles = makeStyles({
    container: {
        display: "flex",
        gap: "1rem",
        flexDirection: "row",
        width: "100%",
        alignItems: "stretch",
        boxSizing: "border-box",
        backgroundColor: tokens.colorBrandBackground2,
        padding: "15px 15px",
        verticalAlign: "middle",
        borderRadius: "6px",
        border: `1px solid ${tokens.colorBrandForegroundInverted}`,
    },
    root: {
        padding: "15px",
        boxSizing: "border-box",
        width: "100%",
    },
    timerContainter: {
        display: "flex",
        flexDirection: "column",
        gap: "0",
        alignItems: "center",
        verticalAlign: "middle",
    },
    timer: {
        // backgroundColor: tokens.colorBrandBackground,
        borderRadius: "4px",
        // padding: "10px 10px",
    },
    timerDescription: {
        fontSize: "12px",
        color: tokens.colorBrandForeground2,
    },
});
export const HeldCallBar = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const webex = useWebexContactCentre();
    const classes = styles();
    const heldCalls = ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.heldMedias) || [];
    const MainCallCallerId = ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.interaction.contactDirection.type) === CONTACT_DIRECTION.OUTBOUND
        ? (_d = (_c = webex.task) === null || _c === void 0 ? void 0 : _c.interaction.callAssociatedDetails.dn) !== null && _d !== void 0 ? _d : "Unknown"
        : ((_e = webex.task) === null || _e === void 0 ? void 0 : _e.interaction.contactDirection.type) === CONTACT_DIRECTION.INBOUND
            ? (_g = (_f = webex.task) === null || _f === void 0 ? void 0 : _f.interaction.callAssociatedDetails.ani) !== null && _g !== void 0 ? _g : "Unknown"
            : "Unknown";
    if (heldCalls.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null, heldCalls.map((heldCall) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return (React.createElement("div", { className: classes.root },
            React.createElement("div", { className: classes.container },
                React.createElement("div", { className: classes.timerContainter },
                    React.createElement(CallTimer, { timeOffset: webex.routeEventTimeOffset, callState: "held", startTime: heldCall.mType === MEDIA_MTYPE.MAINCALL
                            ? webex.task.agentState.joinTimestamp
                            : (_a = webex.task.agentState.consultTimestamp) !== null && _a !== void 0 ? _a : heldCall.holdTimestamp, className: classes.timer }),
                    React.createElement("div", { className: classes.timerDescription }, heldCall.mType === MEDIA_MTYPE.MAINCALL ? "Main Call" : "Consult Call")),
                heldCall.mType === MEDIA_MTYPE.MAINCALL ? (React.createElement(ActiveCallDetails, { isHeldCallSummary: true, callDirection: (_b = webex.task) === null || _b === void 0 ? void 0 : _b.interaction.contactDirection.type, callerId: MainCallCallerId, customerDescription: (_c = webex.task) === null || _c === void 0 ? void 0 : _c.contactCenterVariables.CustomerDisplayName })) : (React.createElement(ActiveCallDetails, { isHeldCallSummary: true, callDirection: (_d = webex.task) === null || _d === void 0 ? void 0 : _d.interaction.contactDirection.type, callerId: (_f = (_e = webex.task) === null || _e === void 0 ? void 0 : _e.consultCallDetails) === null || _f === void 0 ? void 0 : _f.destination, toDescription: (_h = (_g = webex.task) === null || _g === void 0 ? void 0 : _g.consultCallDetails) === null || _h === void 0 ? void 0 : _h.displayName, isConsultLeg: true })))));
    })));
};
