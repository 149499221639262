import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { PresenseControl } from "./PresenseControl";
import { UserAccount } from "./UserAccount";
import { Menu } from "./Menu";
const styles = makeStyles({
    root: {
        height: "40px",
        width: "100%",
        margin: "0",
        padding: "0 0 10px 0 ",
        backgroundColor: "#111111",
    },
    statusBar: {
        display: "flex",
        margin: "10px",
        alignItems: "center", // Vertically center the items
        justifyContent: "space-between", // Optional: space items evenly
    },
});
export const StatusBar = (props) => {
    const classes = styles();
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.statusBar },
            React.createElement(Menu, null),
            React.createElement(PresenseControl, { isWrapUp: props.isWrapUp }),
            React.createElement(UserAccount, null))));
};
