import { tokens } from "@fluentui/react-components";
export const ScrollBarStyle = {
    overflowY: "auto", // Ensure this property is included if needed
    scrollbarWidth: "thin", // Explicitly cast to the correct type
    scrollbarColor: `${tokens.colorBrandForeground1} ${tokens.colorNeutralBackground1}`,
    "&::-webkit-scrollbar": {
        width: "8px",
    },
    "&::-webkit-scrollbar-track": {
        background: tokens.colorNeutralBackground1,
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        background: tokens.colorBrandForeground1,
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: tokens.colorCompoundBrandForeground1Hover,
    },
    "&::-webkit-scrollbar-button": {
        background: tokens.colorBrandForeground1,
        height: "16px",
        width: "16px",
    },
    "&::-webkit-scrollbar-button:hover": {
        background: tokens.colorCompoundBrandForeground1Hover,
    },
};
export const AptosFontFamily = `Aptos "Segoe UI", Roboto, sans-serif`;
