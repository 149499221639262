import React, { memo, useEffect, useState } from "react";
import { makeStyles, Text, Caption1, Card, CardPreview, Tooltip, tokens } from "@fluentui/react-components";
import { CallInboundRegular, CallMissedRegular, CallOutboundRegular } from "@fluentui/react-icons";
import { formatDateTime } from "../../utils/FormatDateTime";
const cardImageBase = {
    width: "26px",
    height: "26px",
    flexShrink: 0,
};
const useStyles = makeStyles({
    card: {
        width: "100%",
        margin: "0 0 0 16px",
        backgroundColor: tokens.colorNeutralBackground1,
        flexGrow: 1,
        flexShrink: 0,
    },
    cardImage: {
        width: "26px",
        height: "26px",
        flexShrink: 0,
    },
    danger: {
        color: tokens.colorStatusDangerBackground3,
    },
    subtitleCaption: {
        color: tokens.colorNeutralForeground2,
    },
    entityTypeCaption: {
        color: tokens.colorNeutralForeground3,
        textOverflow: "ellipsis",
    },
    cardHeader: {
        flexShrink: 1,
        flexGrow: 1,
        gridAutoColumns: "min-content 1fr min-content",
        display: "grid",
        alignItems: "center",
        "--fui-CardHeader--gap": "12px",
    },
    cardHeaderTitle: {
        display: "flex",
        textOverflow: "ellipsis",
        gridColumnStart: 2,
        gridRowStart: 1,
    },
    cardHeaderDescription: {
        display: "flex",
        flexDirection: "column",
        textOverflow: "ellipsis",
        overflow: "hidden",
        gridRowStart: 2,
        gridColumnStart: 2,
    },
    cardHeaderAction: {
        gridColumnStart: 3,
        gridRowStart: "span 2",
        marginLeft: "12px",
    },
    iconInbound: Object.assign(Object.assign({}, cardImageBase), { color: tokens.colorPaletteGreenForeground1 }),
    iconOutbound: Object.assign(Object.assign({}, cardImageBase), { color: tokens.colorPaletteBlueForeground2 }),
    iconMissed: Object.assign(Object.assign({}, cardImageBase), { color: tokens.colorPaletteRedForeground1 })
});
export const CallHistoryItem = memo((props) => {
    const styles = useStyles();
    const [timestampDisplay, setTimestampDisplay] = useState("");
    useEffect(() => {
        const updateTimestampDisplay = () => {
            const dateTime = formatDateTime(new Date(props.timestamp));
            setTimestampDisplay(dateTime.ago
                ? `${dateTime.ago} (${dateTime.formattedDateTime})`
                : dateTime.formattedDateTime);
        };
        const determineInterval = () => {
            const now = Date.now();
            const diffInSeconds = Math.floor((now - props.timestamp) / 1000);
            return diffInSeconds < 60 ? 1000 : 60000; // Update every second if < 1 min, otherwise every minute
        };
        let interval = determineInterval();
        updateTimestampDisplay(); // Initialize immediately
        const intervalId = setInterval(() => {
            updateTimestampDisplay();
            const newInterval = determineInterval();
            if (newInterval !== interval) {
                clearInterval(intervalId);
                interval = newInterval;
                setInterval(updateTimestampDisplay, interval);
            }
        }, interval);
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [props.timestamp]);
    const originSummary = props.orginDescription
        ? `${props.orginDescription} (${props.origin})`
        : props.origin;
    const destinationSummary = props.destinationDescription
        ? `${props.destinationDescription} (${props.destination})`
        : props.destination;
    const icon = props.type === "inbound" ? (React.createElement(CallInboundRegular, { className: styles.iconInbound })) : props.status === "abandoned" ? (React.createElement(CallMissedRegular, { className: styles.iconMissed })) : (React.createElement(CallOutboundRegular, { className: styles.iconOutbound }));
    const title = props.type === "inbound" ? originSummary : destinationSummary;
    const subtitle = props.type === "inbound"
        ? `To: ${destinationSummary}`
        : `From: ${originSummary}`;
    const tooltipText = props.type === "inbound" ? "Inbound Call" : props.status === "abandoned" ? "Abandoned Call" : "Outbound Call";
    return (React.createElement(Card, { className: styles.card, orientation: "horizontal", appearance: "filled" },
        React.createElement(CardPreview, { className: styles.cardImage },
            React.createElement(Tooltip, { appearance: "inverted", content: tooltipText, relationship: "label" }, icon)),
        React.createElement("div", { className: styles.cardHeader },
            React.createElement("div", { className: styles.cardHeaderTitle },
                React.createElement(Text, { weight: "regular" }, title)),
            React.createElement("div", { className: styles.cardHeaderDescription },
                React.createElement(Text, { weight: "regular", size: 200, block: true, truncate: true, wrap: false }, subtitle),
                React.createElement(Caption1, { className: styles.entityTypeCaption }, timestampDisplay)))));
});
