import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { useWebexContactCentre } from "../../../services/webex/contactcenter/WebexContactCentreProvider";
import { SpeedTransferSection } from "./SpeedTransferSection";
import logger from "../../../utils/Logger";
const styles = makeStyles({
    root: {
        display: "flex",
        gap: "3rem",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
    },
});
const testDestination = {
    name: "Matt Test",
    destinations: [
        {
            type: "mobile",
            value: "+61436675271",
        },
        {
            type: "work",
            value: "+61746315996",
        },
    ],
};
export const SpeedTransfers = () => {
    const classes = styles();
    const webex = useWebexContactCentre();
    if (!webex.speedTransferOptions) {
        return null;
    }
    const testSpeedTransfers = [...webex.speedTransferOptions.salesReps, testDestination];
    const onBeginConsult = (destination, destinationDisplayName, transferType) => {
        var _a;
        const interactionId = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.interaction.interactionId;
        if (interactionId) {
            logger.info("SpeedTransferButton", `beginConsult id:${interactionId} to ${destination}`);
            webex.consultCall(interactionId, destination, destinationDisplayName, transferType);
        }
        else {
            logger.error("SpeedTransferButton", "beginConsult: interactionId is null");
        }
    };
    const onBeginForward = (destination, transferType) => {
        var _a;
        const interactionId = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.interaction.interactionId;
        if (interactionId) {
            logger.info("SpeedTransferButton", `beginForward id:${interactionId} to ${destination}`);
            webex.forwardCall(interactionId, destination, transferType);
        }
        else {
            logger.error("SpeedTransferButton", "beginConsult: interactionId is null");
        }
    };
    return (React.createElement("div", { className: classes.root },
        webex.speedTransferOptions.branch.length > 0 && (React.createElement(SpeedTransferSection, { title: `${webex.speedTransferOptions.branchName} Branch`, options: webex.speedTransferOptions.branch, onBeginConsult: onBeginConsult, onForwardCall: onBeginForward })),
        webex.speedTransferOptions.salesReps.length > 0 && (React.createElement(SpeedTransferSection, { title: `${webex.speedTransferOptions.branchName} Sales Reps`, options: testSpeedTransfers, onBeginConsult: onBeginConsult, onForwardCall: onBeginForward })),
        webex.speedTransferOptions.support.length > 0 && (React.createElement(SpeedTransferSection, { title: "Support", options: webex.speedTransferOptions.support, onBeginConsult: onBeginConsult, onForwardCall: onBeginForward }))));
};
