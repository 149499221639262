export var MobiusEventType;
(function (MobiusEventType) {
    MobiusEventType["CALL_SETUP"] = "mobius.call";
    MobiusEventType["CALL_PROGRESS"] = "mobius.callprogress";
    MobiusEventType["CALL_CONNECTED"] = "mobius.callconnected";
    MobiusEventType["CALL_MEDIA"] = "mobius.media";
    MobiusEventType["CALL_DISCONNECTED"] = "mobius.calldisconnected";
})(MobiusEventType || (MobiusEventType = {}));
export var MediaState;
(function (MediaState) {
    MediaState["OFFER"] = "OFFER";
    MediaState["ANSWER"] = "ANSWER";
    MediaState["OFFER_REQUEST"] = "OFFER_REQUEST";
    MediaState["OK"] = "OK";
    MediaState["ERROR"] = "ERROR";
})(MediaState || (MediaState = {}));
export var DisconnectCode;
(function (DisconnectCode) {
    DisconnectCode[DisconnectCode["BUSY"] = 115] = "BUSY";
    DisconnectCode[DisconnectCode["NORMAL"] = 0] = "NORMAL";
    DisconnectCode[DisconnectCode["MEDIA_INACTIVITY"] = 131] = "MEDIA_INACTIVITY";
})(DisconnectCode || (DisconnectCode = {}));
export var DisconnectCause;
(function (DisconnectCause) {
    DisconnectCause["BUSY"] = "User Busy.";
    DisconnectCause["NORMAL"] = "Normal Disconnect.";
    DisconnectCause["MEDIA_INACTIVITY"] = "Media Inactivity.";
})(DisconnectCause || (DisconnectCause = {}));
export var MidCallEventType;
(function (MidCallEventType) {
    MidCallEventType["CALL_INFO"] = "callInfo";
    MidCallEventType["CALL_STATE"] = "callState";
})(MidCallEventType || (MidCallEventType = {}));
export var RoapScenario;
(function (RoapScenario) {
    RoapScenario["ANSWER"] = "ANSWER";
    RoapScenario["OK"] = "OK";
    RoapScenario["OFFER"] = "OFFER";
    RoapScenario["ERROR"] = "ERROR";
    RoapScenario["OFFER_RESPONSE"] = "OFFER_RESPONSE";
})(RoapScenario || (RoapScenario = {}));
export var MobiusCallState;
(function (MobiusCallState) {
    MobiusCallState["PROCEEDING"] = "sig_proceeding";
    MobiusCallState["PROGRESS"] = "sig_progress";
    MobiusCallState["ALERTING"] = "sig_alerting";
    MobiusCallState["CONNECTED"] = "sig_connected";
})(MobiusCallState || (MobiusCallState = {}));
export var TransferType;
(function (TransferType) {
    TransferType["BLIND"] = "BLIND";
    TransferType["CONSULT"] = "CONSULT";
})(TransferType || (TransferType = {}));
export var MUTE_TYPE;
(function (MUTE_TYPE) {
    MUTE_TYPE["USER"] = "user_mute";
    MUTE_TYPE["SYSTEM"] = "system_mute";
})(MUTE_TYPE || (MUTE_TYPE = {}));
