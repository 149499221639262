import React, { useEffect, useState } from "react";
import { useWebexContactCentre } from "../../../services/webex/contactcenter/WebexContactCentreProvider";
import { Button, Dropdown, Label, makeStyles, Option, tokens } from "@fluentui/react-components";
import { ApprovalsAppRegular } from "@fluentui/react-icons";
import { ActiveCallDetails } from "../ActiveCallDetails";
import { CONTACT_DIRECTION } from "../../../services/webex/contactcenter/models/IRoutingMessage";
import logger from "../../../utils/Logger";
const styles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        padding: "0 20px", // Add left and right padding of 20px
        margin: "20px 0", // Adjust margin to not interfere with padding
        boxSizing: "border-box",
    },
    currentCallTimerContainer: {
        backgroundColor: "#333",
        padding: "10px 20px",
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        color: "#fff",
    },
    currentCallTimer: {
        fontSize: "16px",
        color: "#fff",
    },
    currentCallIconRed: {
        fontSize: "22px",
        color: "rgb(209, 52, 56)",
        marginRight: "8px",
    },
    activeCallDetailsContainer: {
        padding: "15px",
        boxSizing: "border-box",
        width: "100%",
    },
    activeCallDetails: {
        padding: "12px 20px",
        boxSizing: "border-box",
        backgroundColor: tokens.colorNeutralBackground1Hover,
        width: "100%",
        borderRadius: "6px",
        border: `1px solid ${tokens.colorBrandForegroundInverted}`,
    },
    dropdown: {
        width: "100%",
    },
    button: {
        width: "100%",
        marginTop: "15px",
    },
});
export const WrapUpSection = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const webex = useWebexContactCentre();
    const classes = styles();
    const [wrapUpTime, setWrapUpTime] = useState(0);
    const [selectedWrapUpCode, setSelectedWrapUpCode] = useState(null);
    // Utility function to format time in hh:mm:ss or mm:ss format
    const formatTime = (seconds) => {
        const days = Math.floor(seconds / 86400);
        const hrs = Math.floor((seconds % 86400) / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        if (days > 0) {
            return `${days}d ${hrs}h ${mins < 10 ? "0" : ""}${mins}m ${secs < 10 ? "0" : ""}${secs}s`;
        }
        else if (hrs > 0) {
            return `${hrs}:${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
        }
        else if (mins > 0) {
            return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
        }
        else {
            return `${secs} Sec`;
        }
    };
    // Update the wrap up time every second
    useEffect(() => {
        var _a, _b;
        const wrapUpStartTime = (_b = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.agentState) === null || _b === void 0 ? void 0 : _b.wrapUpTimestamp;
        if (wrapUpStartTime) {
            const interval = setInterval(() => {
                const currentTime = Date.now();
                const elapsedSeconds = Math.floor((currentTime - wrapUpStartTime) / 1000);
                setWrapUpTime(elapsedSeconds);
            }, 1000);
            return () => clearInterval(interval);
        }
        else {
            setWrapUpTime(0);
        }
    }, [(_b = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.agentState) === null || _b === void 0 ? void 0 : _b.wrapUpTimestamp]);
    const handleCloseWrapUp = () => {
        var _a;
        logger.log("WrapUpSection", "Wrap-up closed with code:", selectedWrapUpCode);
        if ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.interaction) {
            webex.wrapUpCall(webex.task.interaction.interactionId, selectedWrapUpCode);
        }
        else {
            logger.error("WrapUpSection", "No active call to wrap up");
        }
    };
    const handleWrapUpCodeChange = (event, data) => {
        setSelectedWrapUpCode(data.optionValue);
    };
    const callerId = ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.interaction.contactDirection.type) === CONTACT_DIRECTION.OUTBOUND
        ? (_e = (_d = webex.task) === null || _d === void 0 ? void 0 : _d.interaction.callAssociatedDetails.dn) !== null && _e !== void 0 ? _e : "Unknown"
        : ((_f = webex.task) === null || _f === void 0 ? void 0 : _f.interaction.contactDirection.type) === CONTACT_DIRECTION.INBOUND
            ? (_h = (_g = webex.task) === null || _g === void 0 ? void 0 : _g.interaction.callAssociatedDetails.ani) !== null && _h !== void 0 ? _h : "Unknown"
            : "Unknown";
    const callFor = ((_j = webex.task) === null || _j === void 0 ? void 0 : _j.interaction.contactDirection.type) === CONTACT_DIRECTION.INBOUND
        ? (_o = (_l = (_k = webex.task) === null || _k === void 0 ? void 0 : _k.contactCenterVariables.CallFor) !== null && _l !== void 0 ? _l : (_m = webex.task) === null || _m === void 0 ? void 0 : _m.interaction.callAssociatedDetails.dn) !== null && _o !== void 0 ? _o : "Unknown"
        : undefined;
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.currentCallTimerContainer },
            React.createElement(ApprovalsAppRegular, { className: classes.currentCallIconRed }),
            React.createElement(Label, { className: classes.currentCallTimer }, `Wrapup timer: ${formatTime(wrapUpTime)}`)),
        React.createElement("div", { className: classes.activeCallDetailsContainer },
            React.createElement(ActiveCallDetails, { className: classes.activeCallDetails, callDirection: (_p = webex.task) === null || _p === void 0 ? void 0 : _p.interaction.contactDirection.type, customerDescription: (_q = webex.task) === null || _q === void 0 ? void 0 : _q.contactCenterVariables.CustomerDisplayName, callerId: callerId, customerAccount: (_r = webex.task) === null || _r === void 0 ? void 0 : _r.contactCenterVariables.CustomerAccountNumber, customerBranch: (_s = webex.task) === null || _s === void 0 ? void 0 : _s.contactCenterVariables.CustomerBranch, callFor: callFor })),
        React.createElement(Dropdown, { className: classes.dropdown, placeholder: "Select Wrap-Up Code", onOptionSelect: handleWrapUpCodeChange }, webex.wrapUpOptions.map((option) => (React.createElement(Option, { key: option.id, value: option.id }, option.name)))),
        React.createElement(Button, { className: classes.button, appearance: "primary", onClick: handleCloseWrapUp, disabled: !selectedWrapUpCode }, "Submit Wrap-Up")));
};
