import React, { memo } from "react";
import { RecordCard } from "./RecordCard";
import { RecordsSection } from "./RecordsSection";
import { CallRegular } from "@fluentui/react-icons";
import { makeStyles, tokens } from "@fluentui/react-components";
import { EntityType } from "../../../services/dynamics/types/IDynamicsContext";
const useStyles = makeStyles({
    icon: {
        color: tokens.colorStatusSuccessForeground1,
    },
});
export const PhoneCallRecord = memo((props) => {
    var _a;
    const styles = useStyles();
    if (!props.phoneCall) {
        return null;
    }
    return (React.createElement(RecordsSection, { title: props.title },
        React.createElement(RecordCard, { key: props.phoneCall.activityid, title: "This Phone Call", subtitle: (_a = props.phoneCall.subject) !== null && _a !== void 0 ? _a : undefined, entityDisplayName: "Phone Call", entityType: EntityType.phonecall, entityId: props.phoneCall.activityid, icon: React.createElement(CallRegular, { className: styles.icon }), onOpenRecord: props.onOpenRecord })));
});
