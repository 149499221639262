var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import logger from "../../../utils/Logger";
const CC_CIFV1_BACKEND_API_URL = process.env.CC_CIFV1_BACKEND_API_URL;
const TASK_COUNT_TO_FETCH = process.env.CC_CIFV1_TASKS_TO_FETCH ? parseInt(process.env.CC_CIFV1_TASKS_TO_FETCH) : 10;
// Helper function to fetch with Authorization header
const fetchWithAuth = (url_1, token_1, ...args_1) => __awaiter(void 0, [url_1, token_1, ...args_1], void 0, function* (url, token, options = {}) {
    const headers = Object.assign({ "Content-Type": "application/json", Authorization: `Bearer ${token}` }, options.headers);
    return fetch(url, Object.assign(Object.assign({}, options), { headers }));
});
// Fetch teams
export const fetchTeams = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/team`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch teams: ${response.status}`);
    }
    return response.json();
});
// Fetch aux codes
export const fetchAuxCodes = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/aux-code`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch aux codes: ${response.status}`);
    }
    return response.json();
});
// Fetch outbound ANIs
export const fetchOutboundANIs = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/outbound-ani`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch outbound ANIs: ${response.status}`);
    }
    return response.json();
});
// Fetch Desktop Profiles
export const fetchDesktopProfiles = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/desktop-profile`, token);
    if (!response.ok) {
        throw new Error(`Failed to desktop profiles:   ${response.status}`);
    }
    return response.json();
});
// Fetch user info
export const fetchUserInfo = (token, userId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/user/${userId}`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch user info: ${response.status}`);
    }
    return response.json();
});
// Fetch agent config (includes teams, aux codes, outbound ANIs, desktop profiles etc)
export const fetchAgentInfo = (token, userId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/agent-config/${userId}`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch agent info: ${response.status}`);
    }
    return response.json();
});
// Fetch a new beacon code
export const getNewBeaconCode = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/beacon-code`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch beacon code: ${response.status}`);
    }
    const data = yield response.json();
    return data.beaconCode;
});
// Send logout beacon to tell backend to log the user out
export const sendLogoutBeacon = (code) => {
    if (code) {
        fetch(`${CC_CIFV1_BACKEND_API_URL}/beacon-logout/${code}`, {
            method: "GET",
        }).then(() => {
            logger.info("BackendAPIService", `Sent logout beacon to: ${CC_CIFV1_BACKEND_API_URL}/beacon-logout/${code}`);
        });
    }
};
// fetch speed dials
export const fetchSpeedDials = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/contact/speed-transfers`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch speed dials: ${response.status}`);
    }
    return response.json();
});
// fetch contacts
export const fetchContacts = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/contact`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch contacts: ${response.status}`);
    }
    return response.json();
});
// search contacts
export const searchContacts = (token, keyword) => __awaiter(void 0, void 0, void 0, function* () {
    if (keyword.trim().length < 3) {
        return [];
    }
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/contact?search=${encodeURIComponent(keyword.trim())}`, token);
    if (!response.ok) {
        throw new Error(`Failed to search contacts dials: ${response.status}`);
    }
    return response.json();
});
export const fetchTaskHistory = (token, userId, count) => __awaiter(void 0, void 0, void 0, function* () {
    if (!userId) {
        throw new Error("User ID is required to fetch task history");
    }
    logger.info("BackendAPIService", `Fetching task history for user: ${userId}`);
    const response = yield fetchWithAuth(`${CC_CIFV1_BACKEND_API_URL}/task?userid=${userId}&count=${count !== null && count !== void 0 ? count : TASK_COUNT_TO_FETCH}`, token);
    if (!response.ok) {
        throw new Error(`Failed to fetch history: ${response.status}`);
    }
    return response.json();
});
