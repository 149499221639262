import React from "react";
import { useAuth } from "@repo/react-oidc-context";
const CallbackPage = () => {
    var _a;
    const auth = useAuth();
    // Assuming that the auth library completes the login process and makes the token available
    if (auth.isAuthenticated) {
        const token = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.access_token; // or get token from URL params if needed
        // Send token back to the opener window (the iframe in Dynamics)
        window.opener.postMessage(token, "http://localhost:3069"); // use your app's origin
        // Close the popup after sending the token
        // window.close();
    }
    return React.createElement("div", null, "Completing sign-in...");
};
export default CallbackPage;
