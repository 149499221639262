var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// src/services/webex/contactcenter/WebexContactCentreProvider.tsx
import React, { createContext, useContext, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useAuth } from "@repo/react-oidc-context";
import { WebexWebSocket } from "./WebSocket/WebSocket";
import { fetchSpeedDials, fetchContacts, getNewBeaconCode, fetchTaskHistory, fetchAgentInfo as fetchAgentConfig } from "../backend-api/ApiService";
import { INTERACTION_STATE, ROUTING_TYPE } from "./models/IRoutingMessage";
import { ContactCentreAPI } from "./ContactCentreAPI";
import logger from "../../../utils/Logger";
import { buildBranchSalesRepsSpeedDials, buildBranchSpeedDials, buildSupportSpeedDials, determineBranchName } from "./helpers/contactHelpers";
import { AGENT_STATE, CALL_STATE, CC_EVENTS, CONTACT_CENTRE_ROLE, DEVICE_TYPE, PHONE_STATE } from "./models/Enums";
// number of call history items to keep in memory
const TASK_HISTORY_LIMIT = 20;
// Create the context
const WebexContactCentreContext = createContext(undefined);
// Provider component that initializes the WebexContactCentreAPI
export const WebexContactCentreProvider = (props) => {
    var _a, _b, _c, _d, _e;
    const auth = useAuth();
    const [initialisationMessage, setInitialisationMessage] = useState("Getting ready...");
    const [isReady, setIsReady] = useState(false);
    const [phoneState, setPhoneState] = useState(PHONE_STATE.WAIT_START);
    const [presence, setPresence] = useState({ id: "UNKNOWN", type: AGENT_STATE.IDLE, name: "Unknown" });
    const [selectedAni, setSelectedAni] = useState(undefined);
    const [speedTransfers, setSpeedTransfers] = useState(undefined);
    const [activeCall, setActiveCall] = useState(undefined);
    const [routeEventTimeOffset, setRouteEventTimeOffset] = useState(0);
    const taskHistoryRef = useRef([]);
    const [taskHistory, setTaskHistory] = useState([]);
    const [shouldStartApp, setShouldStartApp] = useState(false);
    const isFirstLoginRef = useRef(true);
    const websocketShouldReconnect = useRef(true);
    const currentInteractionRef = useRef(undefined);
    const authUserRef = useRef(auth.user);
    const authClearedSessionRef = useRef(false);
    const eventListenersRef = useRef({});
    const agentIdRef = useRef(undefined);
    const agentConfigRef = useRef(undefined);
    const contactsRef = useRef([]);
    const allOutboundAniEntiresRef = useRef([]);
    const myTeamsRef = useRef(undefined);
    const myIdleCodesRef = useRef(undefined);
    const myPresenceOptionsRef = useRef([]);
    const myOutboundANIsRef = useRef(undefined);
    const myDesktopProfileRef = useRef(undefined);
    const myWrapUpCodesRef = useRef(undefined);
    const allFixedSpeedTransfersRef = useRef([]);
    const allBranchSpeedTransfersRef = useRef([]);
    const logoutBeaconCodeRef = useRef(undefined);
    const isRegisteredRef = useRef(false);
    const consultCallDetailsRef = useRef(localStorage.getItem("consultCallDetails") ? JSON.parse(localStorage.getItem("consultCallDetails")) : undefined);
    const loginDataRef = useRef(localStorage.getItem("webexAgentLoginConfig") ? JSON.parse(localStorage.getItem("webexAgentLoginConfig")) : undefined);
    const shouldLoginAfterLogoutRef = useRef(false);
    const webSocketRef = useRef();
    const contactCenterApiRef = useRef(new ContactCentreAPI(((_a = auth.user) === null || _a === void 0 ? void 0 : _a.access_token) || "", props.apiUrlBase));
    useEffect(() => {
        setTaskHistory([...taskHistoryRef.current]);
        logger.info("WebexContactCentreProvider", "Task History updated:", taskHistoryRef.current);
    }, [taskHistoryRef.current]);
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    //       E V E N T    L I S T E N E R S  (For components to register for webex events directly)
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    const registerEventListener = (eventName, callback) => {
        if (!eventListenersRef.current[eventName]) {
            eventListenersRef.current[eventName] = [];
        }
        eventListenersRef.current[eventName].push(callback);
    };
    const unregisterEventListener = (eventName, callback) => {
        if (!eventListenersRef.current[eventName])
            return;
        eventListenersRef.current[eventName] = eventListenersRef.current[eventName].filter((cb) => cb !== callback);
    };
    const fireEvent = (eventName, ...args) => {
        if (!eventListenersRef.current[eventName])
            return;
        eventListenersRef.current[eventName].forEach((callback) => callback(...args));
    };
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    //        I N I T I A L I S A T I O N    F U N C T I O N S
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    // Update the API's access token when the user's access token changes
    useEffect(() => {
        var _a;
        contactCenterApiRef.current.setAccessToken((_a = auth.user) === null || _a === void 0 ? void 0 : _a.access_token);
    }, [(_b = auth.user) === null || _b === void 0 ? void 0 : _b.access_token]);
    useEffect(() => {
        if (auth.user !== authUserRef.current) {
            authUserRef.current = auth.user;
        }
    }, [auth.user]);
    // Setup API calls that need to occur on interval
    useEffect(() => {
        if (!shouldStartApp) {
            return;
        }
        const updateContacts = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.id_token) {
                const fetchedContacts = yield fetchContacts(auth.user.id_token);
                contactsRef.current = fetchedContacts;
            }
        });
        const getLogoutBeaconCode = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.id_token) {
                try {
                    const code = yield getNewBeaconCode(auth.user.id_token);
                    logger.info("WebexContactCentreProvider", `Have new logout beacon code: ${code}`);
                    logoutBeaconCodeRef.current = code;
                }
                catch (error) {
                    logger.error("WebexContactCentreProvider", "Failed to get new logout beacon code:", error);
                }
            }
        });
        updateContacts();
        getLogoutBeaconCode();
        // Update contacts every 10 seconds
        const intervalId = setInterval(updateContacts, 5000);
        // refresh logout beacon code every 5 minutes
        const logoutBeaconIntervalId = setInterval(getLogoutBeaconCode, 300000);
        // Clear interval when id_token changes or component unmounts
        return () => {
            clearInterval(intervalId);
            clearInterval(logoutBeaconIntervalId);
        };
    }, [(_c = auth.user) === null || _c === void 0 ? void 0 : _c.id_token, shouldStartApp]);
    // Function to initialise Webex Contact Centre
    const initWebSocket = () => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", "init(), Initialising Webex Contact Centre...");
        if (webSocketRef.current) {
            logger.warn("WebexContactCentreProvider", "WebSocket is already initialized.");
            return;
        }
        setPhoneState(PHONE_STATE.INITIALISING);
        setInitialisationMessage("Initialising Webex Contact Center...");
        const subResponse = yield subscribeToNotifications();
        webSocketRef.current = new WebexWebSocket(subResponse.webSocketUrl);
        yield webSocketRef.current.subscribeAndConnect();
        // subscribe to events
        webSocketRef.current.on(CC_EVENTS.Welcome, handleWelcomeEvent);
        webSocketRef.current.on(CC_EVENTS.StationLogin, handleStationLoginEvent);
        webSocketRef.current.on(CC_EVENTS.AgentStateChange, handleAgentStateChange);
        webSocketRef.current.on(CC_EVENTS.RoutingMessage, handleRoutingEvent);
        webSocketRef.current.on(CC_EVENTS.AgentReloginSuccess, handleAgentReloginSuccess);
        webSocketRef.current.on(CC_EVENTS.AGENT_MULTI_LOGIN, handleAgentMultiLogin);
        webSocketRef.current.on(CC_EVENTS.Logout, handleAgentLogout);
        webSocketRef.current.on(CC_EVENTS.close, handleWebSocketClose);
    });
    const buildPresenceOptions = (idleCodes) => {
        const idlePresences = idleCodes.map((code) => {
            return {
                type: AGENT_STATE.IDLE,
                id: code.id,
                name: `Unavailable - ${code.name}`,
                hidden: code.isSystemCode,
            };
        });
        return [
            {
                type: AGENT_STATE.AVAILABLE,
                id: AGENT_STATE.AVAILABLE,
                name: "Available",
            },
            ...idlePresences,
        ];
    };
    const loadBackendData = (agentId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        logger.info("WebexContactCentreProvider", "loadBackendData - Begin loading Data From Backend...");
        try {
            if (!((_a = authUserRef.current) === null || _a === void 0 ? void 0 : _a.id_token)) {
                logger.error("WebexContactCentreProvider", "loadBackendData- No id_token found in auth user. Setting phone state to NOT_LOGGED_IN and returning.");
                setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
                return;
            }
            const agentConfig = yield fetchAgentConfig(authUserRef.current.id_token, agentId);
            const speedDials = yield fetchSpeedDials(authUserRef.current.id_token);
            const fetchedContacts = yield fetchContacts(authUserRef.current.id_token);
            const fetchedHistory = yield fetchTaskHistory(authUserRef.current.id_token, agentId);
            allFixedSpeedTransfersRef.current = speedDials.filter((contact) => { var _a; return (_a = contact.groups) === null || _a === void 0 ? void 0 : _a.some((group) => group.name === process.env.CC_CIFV1_WEBEX_CC_CONTACTGROUP_FIXEDSPEEDTRANSFERS_NAME); });
            allBranchSpeedTransfersRef.current = speedDials.filter((contact) => { var _a; return (_a = contact.groups) === null || _a === void 0 ? void 0 : _a.some((group) => group.name === process.env.CC_CIFV1_WEBEX_CC_CONTACTGROUP_BRANCHES_NAME); });
            agentConfigRef.current = agentConfig;
            contactsRef.current = fetchedContacts;
            myTeamsRef.current = agentConfig.teams;
            myIdleCodesRef.current = agentConfig.idleCodes;
            myPresenceOptionsRef.current = buildPresenceOptions(agentConfig.idleCodes);
            myDesktopProfileRef.current = agentConfig.desktopProfile;
            myWrapUpCodesRef.current = agentConfig.wrapUpCodes;
            myOutboundANIsRef.current = agentConfig.outdialAnis;
            // add tasks to task history - must be done after setting contacts and outbound ANIs
            addToTaskHistory(fetchedHistory);
            logger.info("WebexContactCentreProvider", "Loaded backend data. Agent Config:", agentConfig);
            logger.info("WebexContactCentreProvider", "Loaded backend data. Speed Dials:", {
                allFixedSpeedTransfers: allFixedSpeedTransfersRef.current,
                allBranchSpeedTransfers: allBranchSpeedTransfersRef.current,
            });
        }
        catch (error) {
            logger.error("WebexContactCentreProvider", "Error loading backend data:", error);
            setPhoneState(PHONE_STATE.INIT_ERROR);
            setInitialisationMessage("Failed to load backend data. See your administrator.");
            yield logoutEverything();
        }
        finally {
            logger.info("WebexContactCentreProvider", "loadBackendData ended. (FINALLY)");
        }
    });
    const subscribeToNotifications = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield contactCenterApiRef.current.subscribeToNotifications();
            if (!response.ok) {
                if (response.status === 409) {
                    logger.info("WebexContactCentreProvider", "WEBSOCKET IS 409");
                    setPhoneState(PHONE_STATE.WEBSOCKET_EXISTS);
                    setInitialisationMessage("Websocket already exists.");
                    return;
                }
                setPhoneState(PHONE_STATE.INIT_ERROR);
                setInitialisationMessage(`Failed to subscribe to notifications: ${response.status} ${response.statusText} ${response.text}`);
                throw new Error(`Failed to subscribe to notifications: ${response.status}`);
            }
            return response.json();
        }
        catch (error) {
            setPhoneState(PHONE_STATE.INIT_ERROR);
            setInitialisationMessage(`Failed to subscribe to notifications.`);
            logger.error("WebexContactCentreProvider", "Error in subscribeToNotifications:", error);
            throw error;
        }
    });
    const handleWebSocketClose = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (websocketShouldReconnect.current === false) {
            logger.info("WebexContactCentreProvider", "WebSocket connection closed. websocketShouldReconnect is false so will not reconnect");
            return;
        }
        logger.info("WebexContactCentreProvider", "WebSocket connection lost. Attempting to reconnect...");
        webSocketRef.current = undefined;
        let attempts = 0;
        const maxAttempts = 5;
        const reconnectInterval = 1000;
        const tryReconnect = () => __awaiter(void 0, void 0, void 0, function* () {
            if (attempts < maxAttempts) {
                try {
                    logger.info("WebexContactCentreProvider", `Reconnect attempt #${attempts + 1}...`);
                    yield initWebSocket();
                    logger.info("WebexContactCentreProvider", "WebSocket reconnected successfully.");
                }
                catch (error) {
                    attempts++;
                    logger.error("WebexContactCentreProvider", "Reconnection attempt failed:", error);
                    setTimeout(tryReconnect, reconnectInterval);
                }
            }
            else {
                logger.error("WebexContactCentreProvider", "Max reconnection attempts reached. WebSocket could not be reconnected.");
                setPhoneState(PHONE_STATE.INIT_ERROR);
                setInitialisationMessage("Failed to reconnect WebSocket. Please refresh the page or contact support.");
            }
        });
        tryReconnect();
    }), [initWebSocket, setPhoneState]);
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    //        E V E N T    H A N D L E R S
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    //-------------------------------------------------------------------------------------------------------
    // -                    GENERAL HANDLERS (USER LOGIN | AGENT STATE | ETC)                               -
    //-------------------------------------------------------------------------------------------------------
    const handleWelcomeEvent = (event) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", "WelcomeEvent", event);
        agentIdRef.current = event.data.agentId;
        yield loadBackendData(event.data.agentId);
        if (loginDataRef.current && loginDataRef.current.agentId === event.data.agentId) {
            logger.info("WebexContactCentreProvider", "WelcomeEvent, have login data, will login...");
            login();
        }
        else {
            logger.info("WebexContactCentreProvider", "WelcomeEvent, do not have login data, setting login state to AGENT_INFO_REQUIRED");
            setPhoneState(PHONE_STATE.AGENT_INFO_REQUIRED);
        }
    });
    const handleAgentMultiLogin = (event) => {
        var _a;
        logger.error("WebexContactCentreProvider", "AgentMultiLoginEvent", event);
        setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
        websocketShouldReconnect.current = false;
        (_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.disconnectWebSocket();
        webSocketRef.current = undefined;
        isFirstLoginRef.current = true;
        setShouldStartApp(false);
    };
    // - - - - - - - - Presence - - - - - - - - -
    const setPresenceFromEvent = (event) => {
        var _a, _b;
        // Handle setting to available
        if (event.subStatus === AGENT_STATE.AVAILABLE) {
            setPresence({
                id: AGENT_STATE.AVAILABLE,
                type: AGENT_STATE.AVAILABLE,
                name: "Available",
            });
            return;
        }
        // Handle setting to idle state
        const matchingIdleCode = (_a = myIdleCodesRef.current) === null || _a === void 0 ? void 0 : _a.find((code) => code.id === event.auxCodeId);
        if (!matchingIdleCode) {
            logger.error("WebexContactCentreProvider", "Aux code not found in idle codes:", event.auxCodeId, myIdleCodesRef.current);
        }
        setPresence({
            id: event.auxCodeId,
            type: event.subStatus,
            name: `${event.subStatus} - ${(_b = matchingIdleCode === null || matchingIdleCode === void 0 ? void 0 : matchingIdleCode.name) !== null && _b !== void 0 ? _b : "Unknown"}`,
        });
    };
    const handleAgentStateChangeSuccess = (data) => {
        logger.info("WebexContactCentreProvider", "SETTING AGENT STATE");
        // setAgentState(data.status);
        // setAgentSubStatus(data.subStatus);
        setPresenceFromEvent(data);
    };
    const handleAgentStateChangeFailed = (data) => {
        // to do
    };
    const handleAgentStateChange = (event) => {
        if (event.data.type == "AgentStateChangeSuccess") {
            handleAgentStateChangeSuccess(event.data);
        }
        else if (event.data.type == "AgentStateChangeFailed") {
            logger.error("WebexContactCentreProvider", "Agent state change failed:", event.data);
            handleAgentStateChangeFailed(event.data);
        }
    };
    // - - - - - - - - Login - - - - - - - - -
    const handleStationLoginEvent = (event) => {
        logger.info("WebexContactCentreProvider", `StationLoginEvent -> "${event.data.type}"`, event);
        if (event.data.type == "AgentStationLoginSuccess") {
            handleAgentLoginSuccess(event.data);
        }
        else if (event.data.type == "AgentStationLoginFailed") {
            handleAgentLoginFailed(event.data);
        }
        else {
            logger.error("WebexContactCentreProvider", `Unknown StationLogin event! Type: ${event.type}`, event);
        }
    };
    const handleAgentLoginSuccess = (data) => {
        logger.info("WebexContactCentreProvider", "Agent login success:", data);
        agentIdRef.current = data.agentId;
        setPhoneState(PHONE_STATE.IDLE);
        setIsReady(true);
        setPresenceFromEvent(data);
        shouldLoginAfterLogoutRef.current = false;
    };
    const handleAgentLoginFailed = (data) => __awaiter(void 0, void 0, void 0, function* () {
        logger.error("WebexContactCentreProvider", "Agent login failed:", data);
        if (data.reason === PHONE_STATE.AGENT_SESSION_ALREADY_EXISTS) {
            logger.error("WebexContactCentreProvider", "Agent session already exists.");
            setPhoneState(PHONE_STATE.AGENT_SESSION_ALREADY_EXISTS);
            shouldLoginAfterLogoutRef.current = true;
            yield reloadAgentLogin();
        }
        else {
            setPhoneState(PHONE_STATE.INIT_ERROR);
        }
    });
    const handleAgentReloginSuccess = (event) => __awaiter(void 0, void 0, void 0, function* () {
        setPhoneState(PHONE_STATE.IDLE);
        handleAgentLoginSuccess(event.data);
    });
    // - - - - - - - - Logout - - - - - - - - -
    const handleAgentLogout = (event) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `AgentLogoutEvent -> "${event.data.type}"`, event);
        if (shouldLoginAfterLogoutRef.current === true) {
            logger.log("WebexContactCentreProvider", "Agent logout event received. Now, lets try log in again...");
            shouldLoginAfterLogoutRef.current = false;
            return yield login();
        }
        else if (event.data.type === "AgentLogoutFailed") {
            logger.error("WebexContactCentreProvider", "Agent logout failed:", event);
            setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
        }
        else if (event.data.type === "AgentLogoutSuccess") {
            setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
        }
    });
    //-------------------------------------------------------------------------------------------------------
    // -                              ROUTING HANDLERS                                                      -
    //-------------------------------------------------------------------------------------------------------
    const handleRoutingEvent = (event) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `RoutingEvent -> "${event.data.type}"`, event);
        currentInteractionRef.current = event.data ? event.data.interaction : undefined;
        updateEventTimeOffset(event);
        fireEvent(event.data.type, event);
        switch (event.data.type) {
            case ROUTING_TYPE.AgentDNRegistered:
                handleAgentDNRegistered();
                break;
            case ROUTING_TYPE.AgentContact:
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentOfferContact: // Agent is being offered a phone call
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentContactAssigned: // Agent answered the phone call
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentContactHeld:
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentContactUnheld:
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentConsultCreated:
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentConsulting:
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentConsultConferenceEnded:
                consultCallDetailsRef.current = undefined;
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentConsultEnded:
                consultCallDetailsRef.current = undefined;
                updateActiveCallFromEvent(event);
                break;
            case ROUTING_TYPE.AgentOutboundFailed:
                handleRoutingFailureEvent(event.data);
                break;
            case ROUTING_TYPE.ContactRecordingStarted:
                break;
            case ROUTING_TYPE.AgentWrapup:
                handleWrapUpEvent(event);
                break;
            case ROUTING_TYPE.AgentWrappedUp:
                handleWrappedUpEvent(event.data);
                break;
            case ROUTING_TYPE.ContactWrapupDone:
                handleWrappedUpEvent(event.data);
                break;
            case ROUTING_TYPE.GenericFailure:
                logger.error("WebexContactCentreProvider", "Generic Failure Event. Will process as handleWrappedUpEvent", event.data);
                handleWrappedUpEvent(event.data);
                break;
            case ROUTING_TYPE.ContactEnded:
                setAgentIsAvailable();
                break;
            case ROUTING_TYPE.AgentOfferContactRona:
                setAgentIsRONA();
                break;
            case ROUTING_TYPE.AgentInviteFailed:
                handleRoutingFailureEvent(event.data);
                break;
            default:
                logger.info("WebexContactCentreProvider", "Unknown routing event type: ", event.data.type);
        }
    });
    const updateEventTimeOffset = (event) => {
        if (Object.hasOwnProperty.call(event.data, "eventTime")) {
            const routeEvent = event.data;
            if (routeEvent.eventTime) {
                const offset = Date.now() - routeEvent.eventTime;
                logger.log("WebexContactCentreProvider", `Event time offset: ${offset}ms`);
                setRouteEventTimeOffset(offset);
            }
        }
    };
    const addDescriptionsToTaskHistoryItem = (task) => {
        var _a, _b, _c, _d;
        if (((_a = task.attributes) === null || _a === void 0 ? void 0 : _a.direction) === "inbound" && !task.destinationDescription) {
            // if inbound, then find the destination from contacts
            const contact = contactsRef.current.find((contact) => contact.phoneNumbers.find((p) => p.value.replace(/[^0-9]/g, "") === task.attributes.destination.replace(/[^0-9]/g, "")));
            const destinationDescription = (_b = contact === null || contact === void 0 ? void 0 : contact.displayName) !== null && _b !== void 0 ? _b : undefined;
            return Object.assign(Object.assign({}, task), { destinationDescription });
        }
        else if (((_c = task.attributes) === null || _c === void 0 ? void 0 : _c.direction) && ["outbound", "outdial"].includes(task.attributes.direction) && !task.originDescription) {
            // if outbound, then find the orgin from outbound entries or contacts
            const outboundAni = allOutboundAniEntiresRef.current.find((entry) => entry.number === task.attributes.origin);
            if (outboundAni) {
                return Object.assign(Object.assign({}, task), { originDescription: outboundAni.name });
            }
            else {
                const contact = contactsRef.current.find((contact) => contact.phoneNumbers.find((p) => p.value.replace(/[^0-9]/g, "") === task.attributes.origin.replace(/[^0-9]/g, "")));
                if (contact === null || contact === void 0 ? void 0 : contact.displayName) {
                    return Object.assign(Object.assign({}, task), { originDescription: (_d = contact === null || contact === void 0 ? void 0 : contact.displayName) !== null && _d !== void 0 ? _d : undefined });
                }
                return task;
            }
        }
        else {
            logger.warn("WebexContactCentreProvider", `Task history has unexpected attributes:`, task);
            return task;
        }
    };
    const addToTaskHistory = (newItem) => {
        // Add descriptions to new item(s)
        const newItemWithDescriptions = Array.isArray(newItem)
            ? newItem.map((i) => addDescriptionsToTaskHistoryItem(i))
            : [addDescriptionsToTaskHistoryItem(newItem)];
        logger.info("WebexContactCentreProvider", `Adding to task history:`, newItemWithDescriptions);
        // Merge new items with existing history
        taskHistoryRef.current = [...taskHistoryRef.current, ...newItemWithDescriptions];
        // Ensure only unique items based on `attributes.lastUpdatedTime`
        const uniqueTaskHistory = new Map();
        taskHistoryRef.current.forEach((item) => {
            uniqueTaskHistory.set(item.attributes.lastUpdatedTime, item);
        });
        taskHistoryRef.current = Array.from(uniqueTaskHistory.values());
        // Sort by `lastUpdatedTime` descending
        taskHistoryRef.current.sort((a, b) => b.attributes.lastUpdatedTime - a.attributes.lastUpdatedTime);
        // Trim to keep only the latest `TASK_HISTORY_LIMIT` items
        taskHistoryRef.current = taskHistoryRef.current.slice(0, TASK_HISTORY_LIMIT);
        // Update state
        setTaskHistory([...taskHistoryRef.current]);
        logger.info("WebexContactCentreProvider", `Task history updated:`, taskHistoryRef.current);
    };
    const saveTaskHistoryFromEvent = (data) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const direction = data.interaction.contactDirection.type === "INBOUND" ? "inbound" : "outbound";
        // note: origin should always be the ANI no matter the direction
        const taskHistoryItem = {
            id: data.interaction.interactionId,
            attributes: {
                owner: {
                    id: agentIdRef.current,
                    name: (_b = (_a = agentConfigRef.current) === null || _a === void 0 ? void 0 : _a.agentName) !== null && _b !== void 0 ? _b : auth.user.profile.name,
                },
                queue: {
                    id: (_d = (_c = data.interaction.callProcessingDetails) === null || _c === void 0 ? void 0 : _c.QueueId) !== null && _d !== void 0 ? _d : undefined,
                    name: (_f = (_e = data.interaction.callProcessingDetails) === null || _e === void 0 ? void 0 : _e.virtualTeamName) !== null && _f !== void 0 ? _f : undefined,
                },
                channelType: "telephony",
                status: "completed",
                createdTime: data.interaction.createdTimestamp,
                lastUpdatedTime: data.eventTime,
                captureRequested: false,
                origin: data.interaction.callAssociatedDetails.ani,
                destination: data.interaction.callAssociatedDetails.dn,
                direction,
                variables: {
                    CustomerDisplayName: (_h = (_g = data.interaction.callAssociatedData) === null || _g === void 0 ? void 0 : _g.CustomerDisplayName) === null || _h === void 0 ? void 0 : _h.value,
                    AccountNumber: (_k = (_j = data.interaction.callAssociatedData) === null || _j === void 0 ? void 0 : _j.CustomerAccountNumber) === null || _k === void 0 ? void 0 : _k.value,
                },
            },
        };
        addToTaskHistory(taskHistoryItem);
    };
    const handleRoutingFailureEvent = (data) => {
        if (data.reason && ["DEVICE_NOT_RESPONDING", "USER_UNAVAILABLE"].includes(data.reason)) {
            logger.error("WebexContactCentreProvider", `${data.type}: ${data.reason} -> Setting phone state to PHONE_STATE.AGENT_DN_NOT_REGISTERED`, data);
            setPhoneState(PHONE_STATE.AGENT_DN_NOT_REGISTERED);
            isRegisteredRef.current = false;
            logger.error("WebexContactCentreProvider", `${data.type}: ${data.reason} -> Setting phone state to PHONE_STATE.AGENT_DN_NOT_REGISTERED`, data);
        }
        else {
            logger.error("WebexContactCentreProvider", `${data.type}: ${data.reason} -> Uhandled Reason!!!`, data);
        }
    };
    const handleWrapUpEvent = (event) => {
        updateActiveCallFromEvent(event);
    };
    const handleWrappedUpEvent = (data) => {
        try {
            saveTaskHistoryFromEvent(data);
        }
        finally {
            setActiveCall(undefined);
            setAgentIsAvailable();
        }
    };
    const setAgentIsAvailable = () => __awaiter(void 0, void 0, void 0, function* () {
        logger.debug("WebexContactCentreProvider", `setAgentIsAvailable. isRegisteredRef.current=${isRegisteredRef.current}`);
        setSpeedTransfers(undefined);
        setPhoneState(PHONE_STATE.IDLE);
    });
    const setAgentIsRONA = () => __awaiter(void 0, void 0, void 0, function* () {
        setActiveCall(undefined);
        setSpeedTransfers(undefined);
        setPhoneState(PHONE_STATE.IDLE);
        // setAgentState(AGENT_STATE.IDLE);
        // setAgentSubStatus("RONA");
    });
    const determineInteractionState = (event) => {
        if (!event.data.agentId) {
            throw new Error("Agent ID not found in event data.");
        }
        const data = event.data;
        const interactionState = data.interaction.state;
        const agentState = data.interaction.participants[event.data.agentId];
        const allMediaIsHeld = Object.entries(data.interaction.media || {}).every(([_, value]) => value.isHold === true);
        if (agentState.isWrappedUp) {
            return CALL_STATE.WRAPPED_UP;
        }
        else if (agentState.isWrapUp || interactionState === INTERACTION_STATE.WRAPUP || event.data.type === "AgentWrapup") {
            return CALL_STATE.WRAP_UP;
        }
        else if (interactionState === INTERACTION_STATE.HOLD) {
            return CALL_STATE.HELD;
        }
        else if (data.interaction.contactDirection.type === "INBOUND" &&
            (interactionState === INTERACTION_STATE.RINGING || interactionState === INTERACTION_STATE.NEW)) {
            return CALL_STATE.INBOUND_RINGING;
        }
        else if (allMediaIsHeld) {
            return CALL_STATE.HELD;
        }
        else if (interactionState === INTERACTION_STATE.CONNECTED) {
            return CALL_STATE.CONNECTED;
        }
        else if (interactionState === INTERACTION_STATE.CONFERENCE) {
            return CALL_STATE.CONFERENCE;
        }
        else if (interactionState === INTERACTION_STATE.CONSULT || interactionState === INTERACTION_STATE.CONSULTING) {
            return CALL_STATE.CONSULT;
        }
        else {
            logger.info("WebexContactCentreProvider", `DETERMINE INTERACTION STATE: UNKNOWN OR ENDED:`, data.interaction);
            return CALL_STATE.ENDED;
        }
    };
    const determineContactCenterVariables = (data) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        let vars = {
            CustomerAccountNumber: (_b = (_a = data.interaction.callAssociatedData) === null || _a === void 0 ? void 0 : _a.CustomerAccountNumber) === null || _b === void 0 ? void 0 : _b.value,
            CustomerDisplayName: (_d = (_c = data.interaction.callAssociatedData) === null || _c === void 0 ? void 0 : _c.CustomerDisplayName) === null || _d === void 0 ? void 0 : _d.value,
            CustomerBranch: (_f = (_e = data.interaction.callAssociatedData) === null || _e === void 0 ? void 0 : _e.CustomerBranch) === null || _f === void 0 ? void 0 : _f.value,
            Branch: (_h = (_g = data.interaction.callAssociatedData) === null || _g === void 0 ? void 0 : _g.Branch) === null || _h === void 0 ? void 0 : _h.value,
            Department: (_k = (_j = data.interaction.callAssociatedData) === null || _j === void 0 ? void 0 : _j.Department) === null || _k === void 0 ? void 0 : _k.value,
        };
        return vars;
    };
    const determineSpeedTransfers = (data) => {
        const agentParticipant = data.interaction.participants[data.agentId];
        const flowVars = determineContactCenterVariables(data);
        logger.info("WebexContactCentreProvider", "determineSpeedTransfers, flowVars:", flowVars);
        if (data.interaction.contactDirection.type === "INBOUND" &&
            agentParticipant.hasJoined &&
            !agentParticipant.hasLeft &&
            !agentParticipant.isWrapUp &&
            !agentParticipant.isWrappedUp) {
            const calledNumber = data.interaction.callAssociatedDetails.dn;
            const branchName = determineBranchName(calledNumber, flowVars, allBranchSpeedTransfersRef.current);
            const speedTransfers = {
                branchName,
                branch: buildBranchSpeedDials(branchName, allBranchSpeedTransfersRef.current),
                salesReps: buildBranchSalesRepsSpeedDials(branchName, contactsRef.current),
                support: buildSupportSpeedDials(allFixedSpeedTransfersRef.current),
            };
            return speedTransfers;
        }
        else {
            return undefined;
        }
    };
    const buildActiveHeldMedias = (data) => {
        const media = Object.values(data.interaction.media);
        // if only one media, then its the active one
        if (media.length === 1) {
            return {
                active: media,
                held: [],
            };
        }
        // if multiple media, and one is not held, then its the active
        const activeMedias = media.filter((m) => !m.isHold);
        const heldMedias = media.filter((m) => m.isHold);
        if (activeMedias.length > 0) {
            return {
                active: activeMedias,
                held: heldMedias,
            };
        }
        // at this point, must have multiple media and all are held. Return the main call as the active one.
        const mainCall = media.filter((m) => m.mType === "mainCall");
        if (mainCall.length === 0) {
            throw new Error("Main call not found");
        }
        return {
            active: mainCall,
            held: media.filter((m) => m.mediaResourceId !== mainCall[0].mediaResourceId),
        };
    };
    const updateActiveCallFromEvent = (event) => {
        var _a;
        const data = event.data;
        const medias = buildActiveHeldMedias(data);
        const newTaskData = {
            routeEventData: data,
            interaction: data.interaction,
            agentState: data.interaction.participants[data.agentId],
            callState: determineInteractionState(event),
            contactCenterVariables: determineContactCenterVariables(data),
            activeMedias: medias.active,
            heldMedias: medias.held,
            consultCallDetails: ((_a = consultCallDetailsRef.current) === null || _a === void 0 ? void 0 : _a.interactionId) === data.interaction.interactionId ? consultCallDetailsRef.current : undefined,
        };
        setActiveCall(newTaskData);
        const speedTransfers = determineSpeedTransfers(data);
        setSpeedTransfers(speedTransfers);
        setPhoneState(PHONE_STATE.ACTIVE);
        logger.info("WebexContactCentreProvider", "Speed Transfers for this call:", speedTransfers);
        logger.info("WebexContactCentreProvider", "Updated Task Data:", newTaskData);
    };
    const handleAgentDNRegistered = () => {
        logger.info("WebexContactCentreProvider", "Agent DN Registered");
        isRegisteredRef.current = true;
        setIsReady(true);
    };
    //=======================================================================================================
    //=======================================================================================================
    //=======================================================================================================
    const cleanUp = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield ((_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.disconnectWebSocket());
        yield logout();
        setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
    });
    useEffect(() => {
        window.addEventListener("beforeunload", () => logout);
        return () => {
            window.removeEventListener("beforeunload", () => logout);
        };
    }, [(_d = auth.user) === null || _d === void 0 ? void 0 : _d.id_token]);
    useEffect(() => {
        const cleanUpAsync = () => __awaiter(void 0, void 0, void 0, function* () {
            yield cleanUp();
        });
        return () => {
            cleanUpAsync();
        };
    }, []);
    const reloadAgentLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield contactCenterApiRef.current.reloadUserLogin();
        return response.ok;
    });
    const login = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        logger.info("WebexContactCentreProvider", "[IWebexContactCentreProvider] login()");
        setShouldStartApp(true);
        logger.debug("WebexContactCentreProvider", "login -> Setting phone state to INITIALISING");
        setPhoneState(PHONE_STATE.INITIALISING);
        // If trying to login and websocket not connected, then connect and return.
        // When the websocket connects, it will try logging in again and skip this step.
        websocketShouldReconnect.current = true;
        if (!((_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.isConnected)) {
            logger.debug("WebexContactCentreProvider", "login -> WebSocket not connected. Attempting to connect...");
            yield initWebSocket();
            logger.debug("WebexContactCentreProvider", "login -> WebSocket connected. Will try login again after connected.");
            return;
        }
        // if we dont have the agent info, then request it from the user first before trying to login.
        // Once updated, this function will get called again and skip this step
        if (!loginDataRef.current || !loginDataRef.current.loginPayload || loginDataRef.current.agentId !== agentIdRef.current) {
            logger.info("WebexContactCentreProvider", "login -> Dont have the agent info (eg team). Requesting this from user first.");
            setPhoneState(PHONE_STATE.AGENT_INFO_REQUIRED);
            return;
        }
        // If trying to login and its the first time logging in, then attempt a logout first to clear any old sessions.
        // Once logged out, then loggedOutHandlers will attempt to login again and skip this step.
        if (isFirstLoginRef.current === true) {
            logger.debug("WebexContactCentreProvider", "login -> First login. Attempting to logout first...");
            isFirstLoginRef.current = false;
            shouldLoginAfterLogoutRef.current = true;
            yield logout();
            return;
        }
        // If we got here, then we are finally ready to login! Perform the login.
        logger.info("WebexContactCentreProvider", "login -> Have agent info. Sending login request...");
        const response = yield contactCenterApiRef.current.login(loginDataRef.current.loginPayload);
        if (!response.ok) {
            setPhoneState(PHONE_STATE.INIT_ERROR);
            setInitialisationMessage(`Failed to login: ${response.status} ${response.statusText}`);
            logger.error("WebexContactCentreProvider", `Error in login: ${response.status}: ${yield response.text()}`);
            throw new Error(`Failed to login: ${response.status}`);
        }
    });
    const logoutEverything = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            authClearedSessionRef.current = true;
            yield logout();
        }
        finally {
            try {
                yield ((_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.disconnectWebSocket());
            }
            finally {
                yield auth.removeUser();
                setPhoneState(PHONE_STATE.NOT_LOGGED_IN);
            }
        }
    });
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", "logout()");
        if (!agentIdRef.current) {
            logger.error("WebexContactCentreProvider", "Agent ID not found. Cannot logout.");
            return;
        }
        try {
            const response = yield contactCenterApiRef.current.logout(agentIdRef.current);
            if (!response.ok) {
                throw new Error(`Failed to logout: ${response.status}`);
            }
        }
        catch (error) {
            logger.error("WebexContactCentreProvider", "Error in logout:", error);
        }
    });
    const makeCall = (destination) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (agentIdRef.current && ((_b = (_a = currentInteractionRef.current) === null || _a === void 0 ? void 0 : _a.participants[agentIdRef.current]) === null || _b === void 0 ? void 0 : _b.isWrapUp)) {
            throw new Error("Cannot make call while in wrap up state)");
        }
        if (!((_c = myDesktopProfileRef.current) === null || _c === void 0 ? void 0 : _c.outdialEntryPointId)) {
            throw new Error("User has no outdial entry point");
        }
        if (!selectedAni && ((_d = myOutboundANIsRef.current) === null || _d === void 0 ? void 0 : _d.length) === 0) {
            throw new Error("No outbound ANIs available.");
        }
        else if (!selectedAni) {
            setSelectedAni(myOutboundANIsRef.current[0]);
        }
        let number = selectedAni ? selectedAni.number : myOutboundANIsRef.current[0].number;
        yield contactCenterApiRef.current.makeCall(destination, number, myDesktopProfileRef.current.outdialEntryPointId);
    });
    const holdCall = (interactionId, mediaResourceId) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Holding call. InteractionId=${interactionId}. MediaResourceId=${mediaResourceId}`);
        yield contactCenterApiRef.current.holdCall(interactionId, mediaResourceId);
    });
    const resumeCall = (interactionId, mediaResourceId) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Resuming call. InteractionId=${interactionId}. MediaResourceId=${mediaResourceId}`);
        yield contactCenterApiRef.current.resumeCall(interactionId, mediaResourceId);
    });
    const consultCall = (interactionId, destination, displayName, destinationType) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Start consulting call to ${destination} - ${displayName}. InteractionId=${interactionId}. DestinationType=${destinationType}`);
        consultCallDetailsRef.current = {
            interactionId,
            destination,
            displayName,
            destinationType,
        };
        localStorage.setItem("consultCallDetails", JSON.stringify(consultCallDetailsRef.current));
        yield contactCenterApiRef.current.consultCall(interactionId, destination, destinationType);
    });
    const consultTransferCall = (interactionId, destination, destinationType) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Transfering consult call to ${destination}. InteractionId=${interactionId}. DestinationType=${destinationType}`);
        yield contactCenterApiRef.current.consultTransferCall(interactionId, destination, destinationType);
    });
    const consultEndCall = (interactionId, queueId) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Ending consult call. InteractionId=${interactionId}.`);
        yield contactCenterApiRef.current.consultEndCall(interactionId, queueId);
    });
    const consultConferenceCall = (interactionId, destination, destinationType, agentId) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Conference call to ${destination}. InteractionId=${interactionId}. DestinationType=${destinationType}, AgentId=${agentId}`);
        yield contactCenterApiRef.current.consultConferenceCall(interactionId, destination, destinationType, agentId);
    });
    const forwardCall = (interactionId, destination, destinationType) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("WebexContactCentreProvider", `Start forwarding call to ${destination}. InteractionId=${interactionId}. DestinationType=${destinationType}`);
        yield contactCenterApiRef.current.forwardCall(interactionId, destination, destinationType);
    });
    const answerCall = (interactionId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield contactCenterApiRef.current.answerCall(interactionId);
        if (!response.ok) {
            throw new Error(`Failed to answer call: ${response.status}`);
        }
    });
    const wrapUpCall = (interactionId, wrapUpCodeId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const wrapUpReason = (_b = (_a = myWrapUpCodesRef.current) === null || _a === void 0 ? void 0 : _a.find((code) => code.id === wrapUpCodeId)) === null || _b === void 0 ? void 0 : _b.name;
        if (!wrapUpReason) {
            throw new Error(`Wrap up reason not found for code: ${wrapUpCodeId}`);
        }
        const response = yield contactCenterApiRef.current.wrapUpCall(interactionId, wrapUpCodeId, wrapUpReason);
        if (!response.ok) {
            throw new Error(`Failed to wrapUp call: ${response.status}`);
        }
    });
    const rejectCall = (interactionId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield contactCenterApiRef.current.rejectCall(interactionId);
        if (!response.ok) {
            throw new Error(`Failed to reject call: ${response.status}`);
        }
    });
    const endCall = (interactionId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield contactCenterApiRef.current.endCall(interactionId);
        if (!response.ok) {
            throw new Error(`Failed to end call: ${response.status}`);
        }
    });
    const getWrapUpCodeById = (auxCodeId) => {
        var _a;
        return (_a = myWrapUpCodesRef.current) === null || _a === void 0 ? void 0 : _a.find((code) => code.id === auxCodeId);
    };
    const changeOutboundANI = (aniId) => {
        var _a;
        const ani = (_a = myOutboundANIsRef.current) === null || _a === void 0 ? void 0 : _a.find((ani) => ani.id === aniId);
        if (!ani) {
            logger.error("WebexContactCentreProvider", "Outbound ANI not found for ID:", aniId);
            return;
        }
        setSelectedAni(ani);
    };
    const activateThisPhone = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        logger.info("WebexContactCentreProvider", "Activating this phone...");
        shouldLoginAfterLogoutRef.current = true;
        websocketShouldReconnect.current = true;
        isFirstLoginRef.current = true;
        yield ((_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.disconnectWebSocket());
        webSocketRef.current = undefined;
        yield login();
    });
    const deactivateThisPhone = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setPhoneState(PHONE_STATE.INACTIVE);
        websocketShouldReconnect.current = false;
        shouldLoginAfterLogoutRef.current = false;
        (_a = webSocketRef.current) === null || _a === void 0 ? void 0 : _a.disconnectWebSocket();
    });
    const changePresence = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const payload = {
            agentId: agentIdRef.current,
            state: options.newState,
            auxCodeId: options.newAuxCodeId,
            lastStateChangeReason: options.newLastStateChangeReason,
        };
        logger.info("WebexContactCentreProvider", "Changing presence to:", payload);
        const response = yield contactCenterApiRef.current.setPresence(payload);
        if (!response.ok) {
            throw new Error(`Failed to change presence: ${response.status}`);
        }
    });
    const setLoginConfig = (config) => {
        var _a, _b, _c;
        if (!agentIdRef.current) {
            logger.error("WebexContactCentreProvider", "Agent ID not found. Cannot set login config.");
            throw new Error("Agent ID not found. Cannot set login config.");
        }
        if (!config.extension && !config.agentDn && !((_a = agentConfigRef.current) === null || _a === void 0 ? void 0 : _a.agentExtension) && !((_b = agentConfigRef.current) === null || _b === void 0 ? void 0 : _b.agentDirectNumber)) {
            logger.error("WebexContactCentreProvider", "Agent extension or agent DN not found. Cannot set login config.");
            throw new Error("Agent extension or agent DN not found. Cannot set login config.");
        }
        let deviceId;
        let deviceType;
        let isExtension = false;
        if (config.extension) {
            deviceId = config.extension;
            deviceType = DEVICE_TYPE.EXTENSION;
            isExtension = true;
        }
        else if (config.agentDn) {
            deviceId = config.agentDn;
            deviceType = DEVICE_TYPE.AGENT_DN;
            isExtension = false;
        }
        else if ((_c = agentConfigRef.current) === null || _c === void 0 ? void 0 : _c.agentExtension) {
            deviceId = agentConfigRef.current.agentExtension;
            deviceType = DEVICE_TYPE.EXTENSION;
            isExtension = true;
        }
        else {
            deviceId = agentConfigRef.current.agentDirectNumber;
            deviceType = DEVICE_TYPE.AGENT_DN;
            isExtension = false;
        }
        const data = {
            agentId: agentIdRef.current,
            loginPayload: {
                dialNumber: deviceId,
                deviceId: deviceId,
                deviceType,
                roles: [CONTACT_CENTRE_ROLE.AGENT],
                teamId: config.teamId,
                isExtension,
            },
        };
        loginDataRef.current = data;
        localStorage.setItem("webexAgentLoginConfig", JSON.stringify(data));
    };
    const providerValue = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return ({
            agentId: agentIdRef.current,
            agentName: (_b = (_a = agentConfigRef.current) === null || _a === void 0 ? void 0 : _a.agentName) !== null && _b !== void 0 ? _b : (_c = auth.user) === null || _c === void 0 ? void 0 : _c.profile.name,
            isReady,
            presence,
            presenceOptions: myPresenceOptionsRef.current,
            wrapUpOptions: (_d = myWrapUpCodesRef.current) !== null && _d !== void 0 ? _d : [],
            getWrapUpCodeById,
            phoneState,
            isMuted: false,
            initialisationMessage,
            activateThisPhone,
            deactivateThisPhone,
            changePresence,
            myTeams: myTeamsRef.current,
            logout: logoutEverything,
            routeEventTimeOffset,
            task: activeCall,
            answerCall,
            makeCall,
            wrapUpCall,
            rejectCall,
            endCall,
            holdCall,
            resumeCall,
            forwardCall,
            consultCall,
            consultEndCall,
            consultTransferCall,
            consultConferenceCall,
            contacts: contactsRef.current,
            outdialANIs: (_e = myOutboundANIsRef.current) !== null && _e !== void 0 ? _e : [],
            selectedOutboundANI: selectedAni,
            setSelectedOutboundANI: changeOutboundANI,
            login,
            speedTransferOptions: speedTransfers,
            registerEventListener,
            unregisterEventListener,
            taskHistory,
            setLoginConfig,
        });
    }, [agentIdRef.current, (_e = agentConfigRef.current) === null || _e === void 0 ? void 0 : _e.agentName, isReady, presence, phoneState, initialisationMessage, speedTransfers, activeCall, selectedAni, logoutEverything]);
    return React.createElement(WebexContactCentreContext.Provider, { value: providerValue }, props.children);
};
// Custom hook to use the WebexContactCentre context
export const useWebexContactCentre = () => {
    const context = useContext(WebexContactCentreContext);
    if (!context) {
        throw new Error("useWebexContactCentre must be used within a WebexContactCentreProvider");
    }
    return context;
};
