import React from "react";
import { Image, makeStyles } from "@fluentui/react-components";
const styles = makeStyles({
    image: {
        marginTop: "180px",
    },
});
export const SwoopImage = () => {
    const classes = styles();
    return React.createElement(Image, { className: classes.image, src: `${process.env.CC_CIFV1_SERVER_URL}/img/swoop.svg`, alt: "Webex Logo", width: "100%" });
};
