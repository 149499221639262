import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FluentProvider } from "@fluentui/react-components";
import { AuthProvider } from "@repo/react-oidc-context";
import { webexCallingAuthConfig } from "./auth/OpenIdConnect/conf";
import { App } from "./App";
import { customDarkTheme } from "./utils/Theme";
import CallbackPage from "./pages/CallbackPage";
import { DynamicsProvidor } from "./services/dynamics/DynamicsProvidor";
import { WebexContactCentreProvider } from "./services/webex/contactcenter/WebexContactCentreProvider";
import loggerInstance from "./utils/Logger";
const CC_CIFV1_API_URL_BASE = process.env.CC_CIFV1_API_URL_BASE;
if (!CC_CIFV1_API_URL_BASE) {
    throw new Error("CC_CIFV1_API_URL_BASE is not defined in environment variables");
}
loggerInstance.info("MainComponent", "webexCallingAuthConfig:", webexCallingAuthConfig);
export const MainComponent = () => {
    return (React.createElement(FluentProvider, { theme: customDarkTheme },
        React.createElement(AuthProvider, Object.assign({}, webexCallingAuthConfig),
            React.createElement(Router, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/redirect", element: React.createElement(CallbackPage, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(WebexContactCentreProvider, { apiUrlBase: CC_CIFV1_API_URL_BASE },
                            React.createElement(DynamicsProvidor, null,
                                React.createElement(App, null))) }))))));
};
