var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import loggerInstance from "../../../utils/Logger";
export class ContactCentreAPI {
    constructor(accessToken, apiUrlBase) {
        this.fetchWithAuth = (url, options) => __awaiter(this, void 0, void 0, function* () {
            if (!this.accessToken) {
                throw new Error("Access token not available");
            }
            const headers = Object.assign({ "Content-Type": "application/json", Accept: "application/json", Authorization: `Bearer ${this.accessToken}` }, options.headers);
            return fetch(url, Object.assign(Object.assign({}, options), { headers }));
        });
        this.accessToken = accessToken;
        this.apiUrlBase = apiUrlBase;
    }
    setAccessToken(accessToken) {
        if (accessToken)
            this.accessToken = accessToken;
    }
    login(loginPayload) {
        return __awaiter(this, void 0, void 0, function* () {
            loggerInstance.info("ContactCentreAPI", "login", "Logging in with payload", loginPayload);
            const result = yield this.fetchWithAuth(`${this.apiUrlBase}/v1/agents/login`, {
                method: "POST",
                body: JSON.stringify(loginPayload),
            });
            if (!result.ok) {
                loggerInstance.error("ContactCentreAPI", "login", "Failed to login with payload", loginPayload);
                throw new Error(`Failed to login to contact center: ${result.status} ${yield result.text()}`);
            }
            return result;
        });
    }
    logout(agentId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/agents/logout`, {
                method: "PUT",
                body: JSON.stringify({
                    agentId,
                    logoutReason: "User logout",
                }),
            });
        });
    }
    reloadUserLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/agents/reload`, {
                method: "POST",
            });
        });
    }
    setPresence(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/agents/session/state`, {
                method: "PUT",
                body: JSON.stringify(payload),
            });
        });
    }
    subscribeToNotifications() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/notification/subscribe`, {
                method: "POST",
                body: JSON.stringify({
                    isKeepAliveEnabled: true,
                    clientType: "RDODynamicsEmbedded",
                    allowMultiLogin: false,
                    force: true,
                }),
            });
        });
    }
    makeCall(phoneNumber, outdialAni, entryPointId) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                destination: phoneNumber,
                origin: outdialAni,
                entryPointId,
                mediaType: "telephony",
                outboundType: "OUTDIAL",
            };
            const response = yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks`, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            const data = yield response.json();
            return data.data.id;
        });
    }
    answerCall(interactionId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/accept`, {
                method: "POST",
            });
        });
    }
    rejectCall(interactionId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/reject`, {
                method: "POST",
            });
        });
    }
    wrapUpCall(interactionId, wrapUpCode, wrapUpReason) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/wrapup`, {
                method: "POST",
                body: JSON.stringify({ auxCodeId: wrapUpCode, wrapUpReason }),
            });
        });
    }
    endCall(interactionId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/end`, {
                method: "POST",
            });
        });
    }
    holdCall(interactionId, mediaResourceId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/hold`, {
                method: "POST",
                body: JSON.stringify({
                    mediaResourceId,
                }),
            });
        });
    }
    resumeCall(interactionId, mediaResourceId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/unhold`, {
                method: "POST",
                body: JSON.stringify({
                    mediaResourceId,
                }),
            });
        });
    }
    consultCall(interactionId, destination, destinationType) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/consult`, {
                method: "POST",
                body: JSON.stringify({
                    to: destination,
                    holdParticipants: true,
                    destinationType,
                }),
            });
        });
    }
    consultTransferCall(interactionId, destination, destinationType) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/consult/transfer`, {
                method: "POST",
                body: JSON.stringify({
                    to: destination,
                    holdParticipants: true,
                    destinationType,
                }),
            });
        });
    }
    consultConferenceCall(interactionId, to, destinationType, agentId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/consult/conference`, {
                method: "POST",
                body: JSON.stringify(agentId
                    ? {
                        to,
                        destinationType,
                        agentId,
                    }
                    : {
                        to,
                        destinationType,
                    }),
            });
        });
    }
    consultEndCall(interactionId, queueId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/consult/end`, {
                method: "POST",
                body: queueId ? JSON.stringify({ queueId }) : "{}",
            });
        });
    }
    forwardCall(interactionId, destination, destinationType) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchWithAuth(`${this.apiUrlBase}/v1/tasks/${interactionId}/transfer`, {
                method: "POST",
                body: JSON.stringify({
                    to: destination,
                    destinationType,
                }),
            });
        });
    }
}
