// src/pages/InitialisingPage.tsx
import React from "react";
import { Button, makeStyles, Spinner, tokens, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, } from "@fluentui/react-components";
import { useWebexContactCentre } from "../services/webex/contactcenter/WebexContactCentreProvider";
import { SwoopImage } from "../components/SwoopImage";
import { AptosFontFamily } from "../utils/CustomTokens";
import { PHONE_STATE } from "../services/webex/contactcenter/models/Enums";
const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        margin: "0",
        padding: "0",
    },
    errorContainer: {
        fontFamily: AptosFontFamily,
        padding: "30px",
    },
    messageContainer: {
        fontFamily: AptosFontFamily,
        fontSize: "Large",
        padding: "30px",
    },
    dialog: {
        backgroundColor: tokens.colorBrandForeground1,
        margin: "10px",
    },
    dialogContent: {
        margin: "20px 0",
    },
});
export const InitialisingPage = () => {
    const styles = useStyles();
    const webex = useWebexContactCentre();
    if (webex.phoneState === PHONE_STATE.AGENT_DN_NOT_REGISTERED) {
        return (React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.errorContainer }, "Check that Webex is running."),
            React.createElement(SwoopImage, null)));
    }
    if ((webex === null || webex === void 0 ? void 0 : webex.phoneState) === PHONE_STATE.AGENT_MULTI_LOGIN) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.root },
                React.createElement("div", { className: styles.errorContainer },
                    React.createElement("div", null, "Multiple logins detected. Please check your active sessions."))),
            React.createElement(Dialog, { open: true, modalType: "modal" },
                React.createElement(DialogSurface, { className: styles.dialog },
                    React.createElement(DialogTitle, null, "Contact Center multiple logins detected"),
                    React.createElement(DialogContent, { className: styles.dialogContent }, "You have multiple active Contact Center sessions. Click Activate to make this window your active session, which will close any other active"),
                    React.createElement(DialogActions, null,
                        React.createElement(Button, { appearance: "primary", onClick: webex.deactivateThisPhone }, "Cancel"),
                        React.createElement(Button, { appearance: "primary", onClick: webex.activateThisPhone }, "Activate"))))));
    }
    if (webex && webex.phoneState === PHONE_STATE.INACTIVE) {
        return (React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.errorContainer }, "Phone is inactive"),
            React.createElement(Button, { appearance: "primary", onClick: webex.activateThisPhone }, "Activate Phone"),
            React.createElement(SwoopImage, null)));
    }
    if ((webex === null || webex === void 0 ? void 0 : webex.phoneState) === PHONE_STATE.AGENT_SESSION_ALREADY_EXISTS || (webex === null || webex === void 0 ? void 0 : webex.phoneState) === PHONE_STATE.WEBSOCKET_EXISTS) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.root },
                React.createElement("div", { className: styles.errorContainer }, "Session already exists"),
                React.createElement(SwoopImage, null)),
            React.createElement(Dialog, { open: true, modalType: "modal" },
                React.createElement(DialogSurface, { className: styles.dialog },
                    React.createElement(DialogTitle, null, "Contact Center session already exists"),
                    React.createElement(DialogContent, { className: styles.dialogContent }, "You have an existing Contact Center session open. Click Activate to make this window your active session, which will close any other active sessions. Or, press cancel to use your existing session (don't switch your phone to this window)."),
                    React.createElement(DialogActions, null,
                        React.createElement(Button, { appearance: "primary", onClick: webex.activateThisPhone }, "Activate"),
                        React.createElement(Button, { appearance: "primary", onClick: webex.deactivateThisPhone }, "Cancel"))))));
    }
    if (webex.phoneState === PHONE_STATE.INIT_ERROR) {
        return (React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.errorContainer }, webex.initialisationMessage),
            React.createElement(SwoopImage, null)));
    }
    return (React.createElement("div", { className: styles.root },
        React.createElement(Spinner, { label: webex.initialisationMessage || "Initializing...", labelPosition: "below", size: "large" }),
        React.createElement(SwoopImage, null)));
};
