export var ROUTING_TYPE;
(function (ROUTING_TYPE) {
    ROUTING_TYPE["AgentDNRegistered"] = "AgentDNRegistered";
    ROUTING_TYPE["AgentWrapup"] = "AgentWrapup";
    ROUTING_TYPE["AgentContact"] = "AgentContact";
    ROUTING_TYPE["AgentContactAssigned"] = "AgentContactAssigned";
    ROUTING_TYPE["AgentContactUnheld"] = "AgentContactUnheld";
    ROUTING_TYPE["AgentContactUnHoldFailed"] = "AgentContactUnHoldFailed";
    ROUTING_TYPE["AgentOfferContactRona"] = "AgentOfferContactRona";
    ROUTING_TYPE["ContactEnded"] = "ContactEnded";
    ROUTING_TYPE["ContactRecordingStarted"] = "ContactRecordingStarted";
    ROUTING_TYPE["ContactRecordingPaused"] = "ContactRecordingPaused";
    ROUTING_TYPE["ContactRecordingPauseFailed"] = "ContactRecordingPauseFailed";
    ROUTING_TYPE["ContactRecordingResumed"] = "ContactRecordingResumed";
    ROUTING_TYPE["ContactRecordingResumeFailed"] = "ContactRecordingResumeFailed";
    ROUTING_TYPE["AgentContactUnassigned"] = "AgentContactUnassigned";
    ROUTING_TYPE["AgentBlindTransferred"] = "AgentBlindTransferred";
    ROUTING_TYPE["AgentBlindTransferFailed"] = "AgentBlindTransferFailed";
    ROUTING_TYPE["AgentConsultTransferred"] = "AgentConsultTransferred";
    ROUTING_TYPE["AgentConsultTransferFailed"] = "AgentConsultTransferFailed";
    ROUTING_TYPE["AgentConsultFailed"] = "AgentConsultFailed";
    ROUTING_TYPE["AgentConsultEndFailed"] = "AgentConsultEndFailed";
    ROUTING_TYPE["AgentCtqCancelled"] = "AgentCtqCancelled";
    ROUTING_TYPE["AgentCtqCancelFailed"] = "AgentCtqCancelFailed";
    ROUTING_TYPE["AgentConsultCreated"] = "AgentConsultCreated";
    ROUTING_TYPE["AgentConsulting"] = "AgentConsulting";
    ROUTING_TYPE["AgentConsultEnded"] = "AgentConsultEnded";
    ROUTING_TYPE["AgentConsultConferenced"] = "AgentConsultConferenced";
    ROUTING_TYPE["AgentConsultConferenceEnded"] = "AgentConsultConferenceEnded";
    ROUTING_TYPE["AgentVteamTransferred"] = "AgentVteamTransferred";
    ROUTING_TYPE["AgentVteamTransferFailed"] = "AgentVteamTransferFailed";
    ROUTING_TYPE["AgentContactEndFailed"] = "AgentContactEndFailed";
    ROUTING_TYPE["AgentContactHeld"] = "AgentContactHeld";
    ROUTING_TYPE["AgentCtqFailed"] = "AgentCtqFailed";
    ROUTING_TYPE["AgentBlindTransferFailedEvent"] = "AgentBlindTransferFailedEvent";
    ROUTING_TYPE["AgentOfferCampaignReservation"] = "AgentOfferCampaignReservation";
    ROUTING_TYPE["AgentAddCampaignReservation"] = "AgentAddCampaignReservation";
    ROUTING_TYPE["AgentOutboundFailed"] = "AgentOutboundFailed";
    ROUTING_TYPE["AgentOfferContact"] = "AgentOfferContact";
    ROUTING_TYPE["AgentInviteFailed"] = "AgentInviteFailed";
    ROUTING_TYPE["AgentWrappedUp"] = "AgentWrappedUp";
    ROUTING_TYPE["ContactWrapupDone"] = "ContactWrapupDone";
    ROUTING_TYPE["GenericFailure"] = "GenericFailure";
})(ROUTING_TYPE || (ROUTING_TYPE = {}));
export var CONTACT_DIRECTION;
(function (CONTACT_DIRECTION) {
    CONTACT_DIRECTION["INBOUND"] = "INBOUND";
    CONTACT_DIRECTION["OUTBOUND"] = "OUTBOUND";
})(CONTACT_DIRECTION || (CONTACT_DIRECTION = {}));
export var INTERACTION_STATE;
(function (INTERACTION_STATE) {
    INTERACTION_STATE["NEW"] = "new";
    INTERACTION_STATE["RINGING"] = "ringing";
    INTERACTION_STATE["CONNECTED"] = "connected";
    INTERACTION_STATE["HOLD"] = "hold";
    INTERACTION_STATE["WRAPUP"] = "wrapUp";
    INTERACTION_STATE["TERMINATED"] = "terminated";
    INTERACTION_STATE["CONFERENCE"] = "conference";
    INTERACTION_STATE["CONSULT"] = "consult";
    INTERACTION_STATE["CONSULTING"] = "consulting";
})(INTERACTION_STATE || (INTERACTION_STATE = {}));
export var PARTICIPANT_TYPE;
(function (PARTICIPANT_TYPE) {
    PARTICIPANT_TYPE["CUSTOMER"] = "Customer";
    PARTICIPANT_TYPE["AGENT"] = "Agent";
})(PARTICIPANT_TYPE || (PARTICIPANT_TYPE = {}));
export var MEDIA_MTYPE;
(function (MEDIA_MTYPE) {
    MEDIA_MTYPE["CONSULT"] = "consult";
    MEDIA_MTYPE["MAINCALL"] = "mainCall";
})(MEDIA_MTYPE || (MEDIA_MTYPE = {}));
