// DialPad.tsx
import React from "react";
import { Button, makeStyles, shorthands, tokens } from "@fluentui/react-components";
const digits = [
    { digit: 1, letters: "" },
    { digit: 2, letters: "ABC" },
    { digit: 3, letters: "DEF" },
    { digit: 4, letters: "GHI" },
    { digit: 5, letters: "JKL" },
    { digit: 6, letters: "MNO" },
    { digit: 7, letters: "PQRS" },
    { digit: 8, letters: "TUV" },
    { digit: 9, letters: "WXYZ" },
    { digit: "*", letters: "" },
    { digit: 0, letters: "+" },
    { digit: "#", letters: "" },
];
const useStyles = makeStyles({
    container: Object.assign({ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }, shorthands.padding("20px")),
    dialPad: Object.assign({ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "2px 20px" }, shorthands.margin("2px 0 0 0")),
    dialPadNarrow: Object.assign({ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px 10px" }, shorthands.margin("2px 0 0 0")),
    inputBox: {
        width: "100%",
    },
    digitLetters: {
        fontSize: "10px",
        height: "10px",
    },
    button: {
        minWidth: "60px",
        padding: "12px",
        height: "60px",
        fontSize: "20px",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: tokens.colorBrandForeground1,
        cursor: "pointer",
        "&:hover": {
            color: tokens.colorBrandForeground2Hover,
        },
    },
    buttonPrimary: {
        minWidth: "60px",
        padding: "12px",
        height: "60px",
        fontSize: "20px",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: tokens.colorBrandForeground1,
        color: tokens.colorBrandBackgroundInverted,
        "&:hover": {
            backgroundColor: tokens.colorBrandForeground2Hover,
        },
    },
    callButton: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#444444", // Slightly lighter on hover
            boxShadow: "0 0 1px rgba(108, 99, 255, 1)", // Stronger glow on hover
            color: "#6C63FF",
        },
    },
});
export const DialPadDigits = (props) => {
    const classes = useStyles();
    return (React.createElement("div", { className: props.narrow ? classes.dialPadNarrow : classes.dialPad }, digits.map((digitCombo) => (React.createElement(Button, { className: props.appearance === "primary" ? classes.buttonPrimary : classes.button, appearance: props.appearance, key: digitCombo.digit, onClick: () => props.handleButtonClick(digitCombo.digit.toString()) },
        React.createElement("div", null,
            React.createElement("div", null, digitCombo.digit),
            React.createElement("div", { className: classes.digitLetters }, digitCombo.letters)))))));
};
