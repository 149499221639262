const ONE_DAY = 86400000; // 86400000 ms = 24 hours
export const formatDateTime = (datetime) => {
    const now = new Date();
    const diffInMs = now.getTime() - datetime.getTime();
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const isToday = now.toDateString() === datetime.toDateString();
    const isYesterday = new Date(now.getTime() - ONE_DAY).toDateString() === datetime.toDateString();
    const isTomorrow = new Date(now.getTime() + ONE_DAY).toDateString() === datetime.toDateString();
    const formatTime = new Intl.DateTimeFormat("en-AU", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    }).format(datetime);
    if (isToday) {
        if (diffInSeconds < 60) {
            return {
                formattedDateTime: formatTime,
                ago: `${diffInSeconds} seconds ago`,
            };
        }
        else if (diffInMinutes < 60) {
            return {
                formattedDateTime: formatTime,
                ago: `${diffInMinutes} minutes ago`,
            };
        }
        else {
            return {
                formattedDateTime: formatTime,
                ago: `${diffInHours} hours ago`,
            };
        }
    }
    else if (isYesterday) {
        return {
            formattedDateTime: `Yesterday ${formatTime}`,
        };
    }
    else if (isTomorrow) {
        return {
            formattedDateTime: `Tomorrow ${formatTime}`,
        };
    }
    else {
        const formattedDate = new Intl.DateTimeFormat("en-AU", {
            weekday: "long",
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).format(datetime);
        return {
            formattedDateTime: formattedDate,
        };
    }
};
