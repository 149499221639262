import React, { memo } from "react";
import { makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
    section: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },
});
export const RecordsSection = memo((props) => {
    const styles = useStyles();
    if (!props.children) {
        return null;
    }
    return (React.createElement("section", { className: styles.section }, props.children));
});
