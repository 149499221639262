import React from "react";
import { StatusBar } from "../components/statusbar/StatusBar";
import { makeStyles } from "@fluentui/react-components";
import { StatusSummary } from "../components/statusbar/StatusSummary";
import { WrapUpSection } from "../components/activeCall/wrapup/WrapUpSection";
const styles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    statusSummary: {
        marginTop: "auto", // Push StatusSummary to the bottom
        width: "100%", // Ensure StatusSummary takes the full width
        boxSizing: "border-box",
    },
});
export const WrapUpPage = () => {
    const classes = styles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(StatusBar, { isWrapUp: true }),
        React.createElement(WrapUpSection, null),
        React.createElement(StatusSummary, null)));
};
