var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const initialise = () => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        // Timeout to reject the promise after 12 seconds
        const timeout = setTimeout(() => {
            reject(new Error("Microsoft CIF initialization timed out after 12 seconds"));
        }, 12000);
        // Event listener for CIF initialization
        window.addEventListener("CIFInitDone", () => {
            var _a, _b, _c;
            // @ts-ignore
            const ms = (_a = window.Microsoft) !== null && _a !== void 0 ? _a : undefined;
            // @ts-ignore
            const xrm = (_c = (_b = window.top) === null || _b === void 0 ? void 0 : _b.Xrm) !== null && _c !== void 0 ? _c : undefined;
            if (ms) {
                clearTimeout(timeout); // Clear timeout if event fires
                resolve({ ms, xrm });
            }
        });
    });
});
