import { createDarkTheme } from "@fluentui/react-components";
const customBrand = {
    "10": "#121212",
    "20": "#2F2F4A",
    "30": "#333357",
    "40": "#383966",
    "50": "#3D3E78",
    "60": "#444791",
    "70": "#4F52B2",
    "80": "#5B5FC7",
    "90": "#7579EB",
    "100": "#7F85F5",
    "110": "#9299F7",
    "120": "#AAB1FA",
    "130": "#B6BCFA",
    "140": "#C5CBFA",
    "150": "#DCE0FA",
    "160": "#E8EBFA",
};
export const customDarkTheme = Object.assign(Object.assign({}, createDarkTheme(customBrand)), { colorNeutralBackground1: "#121212", colorNeutralBackground1Hover: "#2B2B2B", colorNeutralBackground1Pressed: "#1A1A1A", colorNeutralBackground1Selected: "#262626", colorNeutralBackground2: "#1A1A1A", colorNeutralBackground2Hover: "#333333", colorNeutralBackground2Pressed: "#0F0F0F", colorNeutralBackground2Selected: "#292929", colorNeutralBackground3: "#0F0F0F", colorNeutralBackground3Hover: "#262626", colorNeutralBackground3Pressed: "#050505", colorNeutralBackground3Selected: "#202020", colorNeutralBackground4: "#050505", colorNeutralBackground4Hover: "#1A1A1A", colorNeutralBackground4Pressed: "#000000", colorNeutralBackground4Selected: "#141414", colorNeutralBackground5: "#000000", colorNeutralBackground5Hover: "#121212", colorNeutralBackground5Pressed: "#0A0A0A", colorNeutralBackground5Selected: "#0F0F0F", colorNeutralBackground6: "#2B2B2B", colorPaletteGreenBackground3: "#3db838" });
