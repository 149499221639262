export const buildBranchSpeedDials = (branchName, contacts) => {
    if (!branchName)
        return [];
    return contacts
        .filter((c) => { var _a; return ((_a = c.firstName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (branchName === null || branchName === void 0 ? void 0 : branchName.toLowerCase()); })
        .map((c) => {
        var _a;
        return {
            name: (_a = c.displayName) !== null && _a !== void 0 ? _a : "Mystery Contact",
            destinations: c.phoneNumbers,
        };
    })
        .sort((a, b) => { var _a, _b; return (_a = a.destinations[0]) === null || _a === void 0 ? void 0 : _a.value.localeCompare((_b = b.destinations[0]) === null || _b === void 0 ? void 0 : _b.value); });
};
export const buildBranchSalesRepsSpeedDials = (branchName, contacts) => {
    if (!branchName)
        return [];
    return contacts
        .filter((c) => { var _a; return ((_a = c.branch) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === branchName.toLowerCase() && ["sale", "area manager"].some((keyword) => { var _a; return (_a = c.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword); }); })
        .map((c) => {
        var _a;
        return {
            name: (_a = c.displayName) !== null && _a !== void 0 ? _a : "Mystery Sales Rep",
            subtitle: c.title,
            destinations: c.phoneNumbers,
        };
    })
        .sort((a, b) => a.name.localeCompare(b.name));
};
export const buildSupportSpeedDials = (contacts) => {
    if (!contacts)
        return [];
    return contacts
        .map((c) => {
        var _a;
        return {
            name: (_a = c.displayName) !== null && _a !== void 0 ? _a : "Mystery Support",
            destinations: c.phoneNumbers,
        };
    })
        .sort((a, b) => a.name.localeCompare(b.name));
};
export const determineBranchName = (calledNumber, flowVars, branchContacts) => {
    var _a, _b;
    if (flowVars === null || flowVars === void 0 ? void 0 : flowVars.Branch)
        return flowVars.Branch;
    return (_b = (_a = branchContacts.find((c) => c.phoneNumbers[0].value.replace(/[^0-9+]/g, "") === calledNumber)) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : undefined;
};
