import React from "react";
import { DIAL_TYPE, DialPad } from "../components/DialPad/DialPad";
import { StatusBar } from "../components/statusbar/StatusBar";
import { makeStyles } from "@fluentui/react-components";
import { CallHistoryList } from "../components/callHistory/CallHistoryList";
import { StatusSummary } from "../components/statusbar/StatusSummary";
const styles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    statusSummary: {
        marginTop: "auto", // Push StatusSummary to the bottom
        width: "100%", // Ensure StatusSummary takes the full width
        boxSizing: "border-box",
    },
});
export const IdlePage = () => {
    const classes = styles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(StatusBar, null),
        React.createElement(DialPad, { type: DIAL_TYPE.OUTBOUND }),
        React.createElement(CallHistoryList, null),
        React.createElement(StatusSummary, null)));
};
