import React, { useEffect, useState } from "react";
import { useAuth } from "@repo/react-oidc-context";
import { makeStyles } from "@fluentui/react-components";
import { LoginPage } from "./pages/LoginPage";
import { IdlePage } from "./pages/IdlePage";
import { OnCallPage } from "./pages/OnCallPage";
import { InitialisingPage } from "./pages/InitialisingPage";
import { useWebexContactCentre } from "./services/webex/contactcenter/WebexContactCentreProvider";
import { WrapUpPage } from "./pages/WrapUpPage";
import { StatusSummary } from "./components/statusbar/StatusSummary";
import { useDynamics } from "./services/dynamics/DynamicsProvidor";
import { AgentInfoCollection } from "./pages/AgentInfoCollection";
import { CALL_STATE, PHONE_STATE } from "./services/webex/contactcenter/models/Enums";
const styles = makeStyles({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "1rem",
        margin: "0",
        padding: "0",
    },
});
export const App = () => {
    var _a;
    const auth = useAuth();
    const webex = useWebexContactCentre();
    const dynamics = useDynamics();
    const classes = styles();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 120);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    if (isSmallScreen) {
        return React.createElement(StatusSummary, { vertical: true });
    }
    let component;
    if (!auth.isAuthenticated) {
        return React.createElement(LoginPage, null);
    }
    if (!dynamics.isReady || dynamics.isInitialising) {
        return React.createElement(InitialisingPage, null);
    }
    switch (webex === null || webex === void 0 ? void 0 : webex.phoneState) {
        case PHONE_STATE.AGENT_INFO_REQUIRED:
            component = React.createElement(AgentInfoCollection, null);
            break;
        case PHONE_STATE.NOT_LOGGED_IN:
            component = React.createElement(LoginPage, null);
            break;
        case PHONE_STATE.WAIT_START:
            component = React.createElement(LoginPage, null);
            break;
        case PHONE_STATE.IDLE:
            component = React.createElement(IdlePage, null);
            break;
        case PHONE_STATE.ACTIVE:
            if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.WRAP_UP) {
                component = React.createElement(WrapUpPage, null);
                break;
            }
            else {
                component = React.createElement(OnCallPage, null);
                break;
            }
        default:
            component = React.createElement(InitialisingPage, null);
            break;
    }
    return React.createElement("div", { className: classes.root }, component);
};
