var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Button, makeStyles, Spinner, useId, Option, Dropdown, Title3 } from "@fluentui/react-components";
import { useWebexContactCentre } from "../services/webex/contactcenter/WebexContactCentreProvider";
import { SwoopImage } from "../components/SwoopImage";
import logger from "../utils/Logger";
import { RocketRegular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        margin: "0",
        padding: "0",
    },
});
export const AgentInfoCollection = () => {
    var _a, _b, _c;
    const styles = useStyles();
    const webex = useWebexContactCentre();
    const [teamId, setTeamId] = React.useState(((_a = webex.myTeams) === null || _a === void 0 ? void 0 : _a.length) === 1 ? webex.myTeams[0].id : "");
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const teamsSelectionId = useId("teams-selection");
    const teamOptions = (_c = (_b = webex.myTeams) === null || _b === void 0 ? void 0 : _b.map((team) => (React.createElement(Option, { key: team.id, text: team.name, value: team.id }, team.name)))) !== null && _c !== void 0 ? _c : [];
    if (isLoggingIn) {
        return (React.createElement("div", { className: styles.root },
            React.createElement(Spinner, { size: "large", title: "Logging in..." }),
            React.createElement(SwoopImage, null)));
    }
    // useEffect(() => {
    //   if (webex.myTeams?.length === 1) {
    //     onSubmit();
    //   }
    // }, []);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoggingIn(true);
        logger.info("AgentInfoCollection", `onSubmit. Logging in with teamId:${teamId}`);
        yield webex.setLoginConfig({ teamId });
        logger.info("AgentInfoCollection", "onSubmit. Set Login Conf. Now, Logging in...");
        yield webex.login();
        logger.info("AgentInfoCollection", "onSubmit. Loggin request sent.");
        setIsLoggingIn(false);
    });
    return (React.createElement("div", { className: styles.root },
        React.createElement(Title3, null, "Agent Info"),
        React.createElement(Dropdown, { id: teamsSelectionId, placeholder: "Select your team", onOptionSelect: (e, d) => { var _a; return setTeamId((_a = d.optionValue) !== null && _a !== void 0 ? _a : ""); } }, teamOptions),
        React.createElement(Button, { onClick: onSubmit, appearance: "primary", shape: "circular", size: "large", icon: React.createElement(RocketRegular, null) }, "Submit"),
        React.createElement(SwoopImage, null)));
};
