import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { CallControls } from "./CallControls";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { CallTimer } from "./CallTimer";
import { MEDIA_MTYPE } from "../../services/webex/contactcenter/models/IRoutingMessage";
import { CALL_STATE } from "../../services/webex/contactcenter/models/Enums";
const styles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "0",
        borderRadius: "4px",
    },
    callerId: {
        fontSize: "12px",
        fontWeight: "bold",
    },
    callContolContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    callTimer: {
        marginLeft: "15px",
        backgroundColor: "#333",
        borderRadius: "4px",
        padding: "5px 20px",
    },
});
export const ActiveCallHeader = (props) => {
    const classes = styles();
    const webex = useWebexContactCentre();
    const callTimerState = () => {
        var _a, _b;
        if (!(webex === null || webex === void 0 ? void 0 : webex.task)) {
            return undefined;
        }
        return ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.INBOUND_RINGING ? "ringing" : ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD ? "held" : "connected";
    };
    const callTimerStartTime = () => {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.agentState.consultState) === "consulting" &&
            ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.agentState.consultTimestamp) &&
            ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.activeMedias.length) === 1 &&
            ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.activeMedias[0].mType) === MEDIA_MTYPE.CONSULT) {
            return (_e = webex.task) === null || _e === void 0 ? void 0 : _e.agentState.consultTimestamp;
        }
        return (_f = webex.task) === null || _f === void 0 ? void 0 : _f.agentState.joinTimestamp;
    };
    /**
     * If the current active call is the consult call leg:
     *  - the call timer should show the consult call start time.
     */
    return (React.createElement("div", { className: classes.root },
        React.createElement(CallTimer, { timeOffset: webex.routeEventTimeOffset, callState: callTimerState(), startTime: callTimerStartTime(), className: classes.callTimer }),
        React.createElement("div", { className: classes.callContolContainer },
            React.createElement(CallControls, { onTransfer: props.onTransfer, onForward: props.onForward, selectedControl: props.selectedControl }))));
};
