import React, { memo } from "react";
import { RecordCard } from "./RecordCard";
import { RecordsSection } from "./RecordsSection";
import { DocumentFolderRegular } from "@fluentui/react-icons";
import { EntityType } from "../../../services/dynamics/types/IDynamicsContext";
;
export const AccountsRecords = memo((props) => {
    var _a;
    return (React.createElement(RecordsSection, { title: props.title }, (_a = props.accounts) === null || _a === void 0 ? void 0 : _a.map((account) => (React.createElement(RecordCard, { key: account.accountid, title: `${account.name} - (${account.accountnumber})`, entityDisplayName: "Account", entityType: EntityType.account, entityId: account.accountid, icon: React.createElement(DocumentFolderRegular, null), onOpenRecord: props.onOpenRecord })))));
});
