var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CIF_EVENT } from "./types/IMicrosoft";
import { initialise as initDynamics } from "./dynamics";
import logger from "../../utils/Logger";
import { useWebexContactCentre } from "../webex/contactcenter/WebexContactCentreProvider";
import { CONTACT_DIRECTION, ROUTING_TYPE } from "../webex/contactcenter/models/IRoutingMessage";
import { CallDirection, ParticipationTypeMask, } from "./types/IPhoneCall";
import { SearchType } from "./types/IXrm";
import { CALL_STATE } from "../webex/contactcenter/models/Enums";
const DynamicsContext = createContext({
    isInitialising: true,
    isError: false,
    isReady: false,
    matchingRecords: undefined,
    openRecord: () => __awaiter(void 0, void 0, void 0, function* () {
        throw new Error("Dynamics not initialised");
    }),
    callHistory: [],
    customParams: {},
});
const phoneCallSelects = [
    "rdo_webexid",
    "rdo_wrapupcode",
    "subject",
    "phonenumber",
    "directioncode",
    "statuscode",
    "statecode",
    "description",
    "actualstart",
    "actualend",
];
export const DynamicsProvidor = ({ children }) => {
    const [Microsoft, setMicrosoft] = React.useState(undefined);
    const [Xrm, setXrm] = React.useState(undefined);
    const [isError, setIsError] = React.useState(false);
    const [isInitialising, setIsInitialising] = React.useState(true);
    const [isReady, setIsReady] = React.useState(false);
    const [msEnv, setMsEnv] = React.useState(undefined);
    const [customParams, setCustomParams] = React.useState({});
    const webex = useWebexContactCentre();
    const userIdRef = React.useRef(undefined);
    const userDisplayNameRef = React.useRef(undefined);
    const searchResultRef = React.useRef(undefined);
    const activeRecordsRef = React.useRef(undefined);
    const [matchingRecords, setMatchingRecords] = useState(undefined);
    const [callHistory, setCallHistory] = useState([]);
    const descriptionsCache = useRef(new Map()); // Caches descriptions by phone number
    function searchEntityToArray(entityName, query) {
        return __awaiter(this, void 0, void 0, function* () {
            const resultString = yield Microsoft.CIFramework.searchAndOpenRecords(entityName, query, true);
            const result = JSON.parse(resultString);
            const asArray = result.hasOwnProperty("0") ? Object.values(result) : [];
            return asArray;
        });
    }
    const getCustomerDescription = (phoneNumber) => __awaiter(void 0, void 0, void 0, function* () {
        const searchResults = yield searchCallerId(phoneNumber);
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            return searchResults.accounts[0].contacts[0].fullname + " - " + searchResults.accounts[0].name;
        }
        else if (searchResults.accounts.length === 1) {
            return searchResults.accounts[0].name;
        }
        else if (searchResults.accounts.length > 1) {
            return "Multiple Accounts";
        }
        else {
            return undefined;
        }
    });
    const convertWebexTaskHistoryItemToCallHistoryItem = (webexTaskHistoryItem) => {
        const { attributes, originDescription, destinationDescription } = webexTaskHistoryItem;
        return {
            type: attributes.direction,
            timestamp: attributes.createdTime,
            origin: attributes.origin,
            destination: attributes.destination,
            originDescription: originDescription,
            destinationDescription: destinationDescription,
            status: attributes.status,
        };
    };
    // Memoized function to fetch customer description
    const getCachedCustomerDescription = (phoneNumber) => __awaiter(void 0, void 0, void 0, function* () {
        if (descriptionsCache.current.has(phoneNumber)) {
            return descriptionsCache.current.get(phoneNumber); // Return cached description
        }
        const description = yield getCustomerDescription(phoneNumber); // Fetch if not cached
        descriptionsCache.current.set(phoneNumber, description); // Cache the fetched value
        return description;
    });
    useEffect(() => {
        const updateCallHistoryWithCustomerDescriptions = () => __awaiter(void 0, void 0, void 0, function* () {
            const newCallHistoryItems = webex.taskHistory.map(convertWebexTaskHistoryItemToCallHistoryItem).sort((a, b) => b.timestamp - a.timestamp);
            setCallHistory(newCallHistoryItems);
            const updatedCallHistoryItems = yield Promise.all(newCallHistoryItems.map((item) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                const originDescription = (_a = item.originDescription) !== null && _a !== void 0 ? _a : (yield getCachedCustomerDescription(item.origin));
                const destinationDescription = (_b = item.destinationDescription) !== null && _b !== void 0 ? _b : (yield getCachedCustomerDescription(item.destination));
                return Object.assign(Object.assign({}, item), { originDescription, destinationDescription });
            })));
            setCallHistory(updatedCallHistoryItems);
        });
        updateCallHistoryWithCustomerDescriptions();
    }, [webex.taskHistory]);
    const addContactsToAccounts = (accounts) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsExpanded = [];
        for (let account of accounts) {
            const contactsQuery = [`$filter=_parentcustomerid_value eq ${account.accountid}`, "$select=fullname,telephone1,mobilephone,jobtitle"].join("&");
            const accountContacts = yield searchEntityToArray("contact", contactsQuery);
            accountsExpanded.push(Object.assign(Object.assign({}, account), { contacts: accountContacts }));
        }
        return accountsExpanded;
    });
    const addContactsToAccountsFilteredByCallerId = (accounts, callerId) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsExpanded = yield addContactsToAccounts(accounts);
        const accountsWithFilteredContacts = accountsExpanded.map((account) => {
            const contacts = account.contacts
                ? account.contacts.filter((contact) => {
                    return contact.telephone1 === callerId || contact.mobilephone === callerId;
                })
                : [];
            return Object.assign(Object.assign({}, account), { contacts });
        });
        return accountsWithFilteredContacts;
    });
    const searchPhoneCall = (interactionId) => __awaiter(void 0, void 0, void 0, function* () {
        const selects = [
            "subject",
            "rdo_webexid",
            "rdo_wrapupcode",
            "directioncode",
            "statuscode",
            "statecode",
            "phonenumber",
            "description",
            "actualstart",
            "actualend",
            "actualdurationminutes",
            "_regardingobjectid_value",
            "_ownerid_value",
            "_owninguser_value",
            "_owningteam_value",
            "createdon",
            "modifiedon",
        ].join(",");
        const phoneCallQuery = [`$filter=rdo_webexid eq '${interactionId}'`, `$select=${selects}`].join("&");
        return yield searchEntityToArray("phonecall", phoneCallQuery);
    });
    const searchCallerId = (callerId) => __awaiter(void 0, void 0, void 0, function* () {
        if (!Microsoft) {
            console.error("RDO: DynamicsProvidor: searchCallerId: Dynamics not initialised");
            throw new Error("Dynamics not initialised");
        }
        const encodedCallerId = encodeURIComponent(callerId);
        const accountsQuery = [
            `$filter=telephone1 eq '${encodedCallerId}' or telephone2 eq '${encodedCallerId}' or telephone3 eq '${encodedCallerId}' or contact_customer_accounts/any(c:c/mobilephone eq '${encodedCallerId}') or contact_customer_accounts/any(c:c/telephone1 eq '${encodedCallerId}')`,
            "$select=name,accountnumber,telephone1,telephone2,telephone3",
            "$top=10",
        ].join("&");
        // logger.debug("DynamicsProvidor", "searchCallerId: accountsQuery:", accountsQuery);
        const callBackQuery = [
            `$filter=statecode eq 0 and (rdo_callbacknumber eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone1 eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone2  eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone3  eq '${encodedCallerId}' or regardingobjectid_contact_rdo_callbackrequest/mobilephone eq '${encodedCallerId}' or regardingobjectid_contact_rdo_callbackrequest/telephone1 eq '${encodedCallerId}')`,
            "$select=activityid,rdo_callbacknumber,subject,scheduledend,prioritycode,rdo_customercallsecondattempt,_ownerid_value",
            "$top=5",
        ].join("&");
        // logger.debug("DynamicsProvidor", "searchCallerId: searching for callerId:", callerId);
        const accounts = yield searchEntityToArray("account", accountsQuery);
        const callBacks = yield searchEntityToArray("rdo_callbackrequest", callBackQuery);
        const accountsWithContacts = yield addContactsToAccountsFilteredByCallerId(accounts, callerId);
        return {
            accounts: accountsWithContacts,
            callBacks,
        };
    });
    const popFromSearchResults = (searchResults, callerId) => __awaiter(void 0, void 0, void 0, function* () {
        // if single matching call-back-request, then pop that
        if (searchResults.callBacks.length === 1) {
            const callBack = searchResults.callBacks[0];
            const callBackId = callBack.activityid;
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single call-back-request:", callBackId);
            Microsoft.CIFramework.searchAndOpenRecords("rdo_callbackrequest", `$filter=activityid eq '${callBackId}'`, false);
        }
        // else if multiple matching call-back-requests, then show search page.
        else if (searchResults.callBacks.length > 1) {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: multiple mactching call-back-requests for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
        // else if single matching contact, then pop contact
        else if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single contact:", searchResults.accounts[0].contacts[0].contactid);
            Microsoft.CIFramework.searchAndOpenRecords("contact", `$filter=contactid eq '${searchResults.accounts[0].contacts[0].contactid}'`, false);
        }
        // else if single matching account (including multiple matching contacts but single account), then pop account
        else if (searchResults.accounts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single account:", searchResults.accounts[0].accountid);
            Microsoft.CIFramework.searchAndOpenRecords("account", `$filter=accountid eq '${searchResults.accounts[0].accountid}'`, false);
        }
        // else if multiple matching accounts, then show search page.
        else if (searchResults.accounts.length > 1) {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: multiple mactching accounts for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
        // else if no matching records, then... show search page???
        else {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: no matching records for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
    });
    const findAndOpenRecords = (callerId, interactionId, directionCode) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const searchResults = yield searchCallerId(callerId);
        logger.info("DynamicsProvidor", `handleAgentOfferContact: searchResults for '${callerId}':`, searchResults);
        searchResultRef.current = {
            interactionId: interactionId,
            callerId: callerId,
            searchResults,
        };
        updateActiveRecords({
            interactionId: interactionId,
            callerId: callerId,
            accounts: searchResults.accounts,
            callBacks: searchResults.callBacks,
            directionCode,
        });
        if (!((_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.phoneCall)) {
            const phoneCalls = yield searchPhoneCall(interactionId);
            if (phoneCalls.length > 0) {
                updateActiveRecords({ interactionId: interactionId, phoneCall: phoneCalls[0] });
            }
        }
        return searchResults;
    });
    const updateActiveRecords = (updates) => {
        var _a;
        // If update is undefined, then clear active records
        if (updates === undefined) {
            logger.info("DynamicsProvidor", "updateActiveRecords: clearing active records");
            activeRecordsRef.current = undefined;
            setMatchingRecords(undefined);
            // if update has a new interactionId, then replace the active records with the update
        }
        else if (((_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.interactionId) !== (updates === null || updates === void 0 ? void 0 : updates.interactionId)) {
            logger.info("DynamicsProvidor", "updateActiveRecords: replacing active records with new updates");
            activeRecordsRef.current = updates;
            setMatchingRecords(updates);
            // else, merge the updates into the active records
        }
        else {
            logger.info("DynamicsProvidor", "updateActiveRecords: merging updates into active records");
            activeRecordsRef.current = Object.assign(Object.assign({}, activeRecordsRef.current), updates);
            setMatchingRecords(activeRecordsRef.current);
        }
    };
    const updatePhoneCallActivity = (activityId, payload) => __awaiter(void 0, void 0, void 0, function* () {
        if (Object.keys(payload).length === 0) {
            logger.info("DynamicsProvidor", "updatePhoneCallActivity: no payload to update");
            return;
        }
        logger.info("DynamicsProvidor", "updatePhoneCallActivity: updating phone call activity:", payload);
        yield Microsoft.CIFramework.updateRecord("phonecall", activityId, JSON.stringify(payload));
    });
    const endPhoneCallActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
        yield updatePhoneCallActivity(activityId, { actualend: new Date().toISOString() });
    });
    const closePhoneCallActivity = (activityId, wrapupCode) => __awaiter(void 0, void 0, void 0, function* () {
        const phoneCall = yield fetchPhoneCallById(activityId);
        let update = { statecode: 1, statuscode: 2, rdo_wrapupcode: wrapupCode };
        if ((phoneCall === null || phoneCall === void 0 ? void 0 : phoneCall.actualstart) && (phoneCall === null || phoneCall === void 0 ? void 0 : phoneCall.actualend)) {
            const start = new Date(phoneCall.actualstart);
            const end = new Date(phoneCall.actualend);
            const durationMins = Math.floor((end.getTime() - start.getTime()) / 60000);
            update.actualdurationminutes = durationMins;
        }
        yield updatePhoneCallActivity(activityId, update);
    });
    const fetchPhoneCallById = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const phoneCallReponse = yield Microsoft.CIFramework.retrieveRecord("phonecall", activityId, `?$select=${phoneCallSelects.join(",")}`);
            return JSON.parse(phoneCallReponse);
        }
        catch (error) {
            logger.error("DynamicsProvidor", "fetchPhoneCallById: Failed to fetch phone call by id. Error:", error);
            throw error;
        }
    });
    const doCreatePhoneCallActivity = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const createPhoneCallResponse = yield Microsoft.CIFramework.createRecord("phonecall", JSON.stringify(payload));
        logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: createPhoneCallResponse:", createPhoneCallResponse);
        const phoneCallResponseObject = JSON.parse(createPhoneCallResponse);
        logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phoneCallResponseObject:", phoneCallResponseObject);
        if (phoneCallResponseObject.id) {
            logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phone call created with id:", phoneCallResponseObject.id);
            const phoneCall = yield fetchPhoneCallById(phoneCallResponseObject.id);
            logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phone call fetched:", phoneCall);
            return phoneCall;
        }
        else {
            logger.error("DynamicsProvidor", "Failed to create phone call activity. Unexpected response from Dynamics:", createPhoneCallResponse);
            throw new Error("Failed to create phone call activity. Unexpected response from Dynamics");
        }
    });
    const createPhoneCallActivity = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("DynamicsProvidor", "createPhoneCallActivity: creating phone call activity:", payload);
        const existingPhoneCall = yield searchPhoneCall(payload.rdo_webexid);
        if (existingPhoneCall.length > 0) {
            const errMessage = `createPhoneCallActivity: phone call id='${existingPhoneCall[0].activityid}' already exists` +
                ` for rdo_webexid '${payload.rdo_webexid}'. Code should update, not create a new activity!`;
            logger.error("DynamicsProvidor", errMessage);
            throw new Error(errMessage);
        }
        try {
            const phoneCall = yield doCreatePhoneCallActivity(payload);
            logger.info("DynamicsProvidor", "createPhoneCallActivity: createPhoneCallResponse:", phoneCall);
            return phoneCall;
        }
        catch (error) {
            logger.error("DynamicsProvidor", "createPhoneCallActivity: error:", error);
            throw error;
        }
    });
    const phoneCallBaseBuilder = (routeEventData) => {
        var _a, _b, _c, _d;
        const direction = routeEventData.interaction.contactDirection.type;
        if (direction === CONTACT_DIRECTION.INBOUND) {
            const vars = routeEventData.interaction.callAssociatedData;
            const callTo = ((_a = vars === null || vars === void 0 ? void 0 : vars.Branch) === null || _a === void 0 ? void 0 : _a.value) && ((_b = vars === null || vars === void 0 ? void 0 : vars.Department) === null || _b === void 0 ? void 0 : _b.value)
                ? `${vars.Branch.value} ${vars.Department.value}`
                : (_d = (_c = vars === null || vars === void 0 ? void 0 : vars.CallTo) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : routeEventData.interaction.callAssociatedDetails.ani;
            return {
                rdo_webexid: routeEventData.interaction.interactionId,
                phonenumber: routeEventData.interaction.callAssociatedDetails.ani,
                directioncode: CallDirection.Incoming,
                subject: `Called ${callTo}, answered by ${userDisplayNameRef.current}`,
                actualstart: new Date().toISOString(),
            };
        }
        else {
            return {
                rdo_webexid: routeEventData.interaction.interactionId,
                phonenumber: routeEventData.interaction.callAssociatedDetails.dn,
                directioncode: CallDirection.Outgoing,
                subject: ` ${userDisplayNameRef.current} called ${routeEventData.interaction.callAssociatedDetails.dn}`,
                actualstart: new Date().toISOString(),
            };
        }
    };
    const createPhoneCallFromSearchResults = (searchResults, routeEventData) => __awaiter(void 0, void 0, void 0, function* () {
        const activityBase = phoneCallBaseBuilder(routeEventData);
        // if single matching contact, then create phone call against contact
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            const contact = searchResults.accounts[0].contacts[0];
            logger.info("DynamicsProvidor", "createInboundPhoneCallFromSearchResults: create phone call against contact:", contact.contactid);
            return yield createPhoneCallActivity(Object.assign(Object.assign({}, activityBase), { phonecall_activity_parties: [
                    { participationtypemask: ParticipationTypeMask.From, "partyid_contact@odata.bind": `/contacts(${contact.contactid})` },
                    { participationtypemask: ParticipationTypeMask.To, "partyid_systemuser@odata.bind": `/systemusers(${userIdRef.current})` },
                ], "regardingobjectid_contact@odata.bind": `/contacts(${contact.contactid})` }));
            // if single matching account (or multiple contacts from one account), then create phone call against account
        }
        else if (searchResults.accounts.length === 1) {
            const account = searchResults.accounts[0];
            logger.info("DynamicsProvidor", "createInboundPhoneCallFromSearchResults: create phone call against account:", account.accountid);
            return yield createPhoneCallActivity(Object.assign(Object.assign({}, activityBase), { phonecall_activity_parties: [
                    { participationtypemask: ParticipationTypeMask.From, "partyid_account@odata.bind": `/accounts(${account.accountid})` },
                    { participationtypemask: ParticipationTypeMask.To, "partyid_systemuser@odata.bind": `/systemusers(${userIdRef.current})` },
                ], "regardingobjectid_account@odata.bind": `/accounts(${account.accountid})` }));
        }
        else {
            logger.info("DynamicsProvidor", "handleTaskAccepted: no matching records to create phone call against.");
        }
    });
    const createPhoneCallOnHandleAnswer = (routeEventData) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const direction = routeEventData.interaction.contactDirection.type;
            const callerId = direction === CONTACT_DIRECTION.INBOUND ? routeEventData.interaction.callAssociatedDetails.ani : routeEventData.interaction.callAssociatedDetails.dn;
            const callerIdSearchResults = ((_a = searchResultRef.current) === null || _a === void 0 ? void 0 : _a.callerId) === callerId ? searchResultRef.current.searchResults : yield searchCallerId(callerId);
            const phoneCall = yield createPhoneCallFromSearchResults(callerIdSearchResults, routeEventData);
            setTimeout(() => {
                if (phoneCall) {
                    updateActiveRecords({ interactionId: routeEventData.interaction.interactionId, phoneCall });
                    Xrm === null || Xrm === void 0 ? void 0 : Xrm.Page.ui.refresh();
                }
                updateActiveRecords({ interactionId: routeEventData.interaction.interactionId, phoneCall });
            }, 1000);
        }
        catch (error) {
            logger.error("DynamicsProvidor", "createInboundPhoneCallOnHandleAnswer: error:", error);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: "Failed to create phone call activity", details: JSON.stringify(error) });
        }
    });
    const openRecord = useCallback((entityType, entityId) => __awaiter(void 0, void 0, void 0, function* () {
        yield (Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "entityrecord", entityName: entityType, entityId: entityId }));
    }), [Microsoft]);
    useEffect(() => {
        const parseRawCustomParams = (rawCustomParams) => {
            let result = {};
            try {
                const parsed = JSON.parse(rawCustomParams);
                logger.debug("DynamicsProvidor", "parseRawCustomParams: parsed custom params:", parsed);
                if (typeof parsed !== "object") {
                    return result;
                }
                if (parsed.hasOwnProperty("width")) {
                    result.width = parseInt(parsed.width);
                }
                if (parsed.hasOwnProperty("featuresEnabled") &&
                    Array.isArray(parsed.featuresEnabled) &&
                    parsed.featuresEnabled.every((f) => typeof f === "string")) {
                    result.featuresEnabled = parsed.featuresEnabled;
                }
                else if (parsed.hasOwnProperty("featuresEnabled")) {
                    logger.error("DynamicsProvidor", "parseRawCustomParams: featuresEnabled is not an array of strings:", parsed.featuresEnabled);
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "parseRawCustomParams: error parsing custom params:", error);
                window.alert("Error parsing custom params for RDO Webex CIFv1. Please contact your system administrator." +
                    `\n\nCustom Params: \n${rawCustomParams}`);
            }
            finally {
                return result;
            }
        };
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                logger.info("DynamicsProvidor", "initialising...");
                const { ms, xrm } = yield initDynamics();
                logger.info("DynamicsProvidor", "initDynamics completed...");
                setIsInitialising(false);
                setMicrosoft(ms);
                setXrm(xrm);
                setIsReady(true);
                const env = JSON.parse(yield ms.CIFramework.getEnvironment());
                logger.info;
                userIdRef.current = (env === null || env === void 0 ? void 0 : env.userId) ? env === null || env === void 0 ? void 0 : env.userId.toLowerCase().replace(/[{}]/g, "") : undefined;
                if (!userIdRef.current) {
                    logger.error("DynamicsProvidor", "User Id not found in environment!!");
                }
                userDisplayNameRef.current = env === null || env === void 0 ? void 0 : env.username;
                logger.info("DynamicsProvidor", "initialised with env:", env);
                setMsEnv(env);
                setCustomParams(parseRawCustomParams(env === null || env === void 0 ? void 0 : env.customParams));
                if (!(customParams === null || customParams === void 0 ? void 0 : customParams.width)) {
                    logger.error("DynamicsProvidor", "Custom Params: width is not set!");
                }
                else {
                    ms.CIFramework.setWidth(customParams.width);
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "initialising error:", error);
                setIsInitialising(false);
                setIsError(true);
            }
        });
        init();
    }, []);
    // Register CIF event handlers:
    useEffect(() => {
        if (!Microsoft || !webex || isInitialising)
            return;
        const handleClickToAct = (eventMessage) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const event = JSON.parse(eventMessage);
            logger.info("DynamicsProvidor", "Received ClickToAct Event:", event);
            const phoneNumber = (_b = (_a = event.value) === null || _a === void 0 ? void 0 : _a.replace(/[^+0-9]/g, "")) !== null && _b !== void 0 ? _b : undefined;
            if (phoneNumber && ((_c = phoneNumber.match(/^\+?[0-9]{6,12}$/)) === null || _c === void 0 ? void 0 : _c.length)) {
                logger.info("DynamicsProvidor", `Making phone call to: ${phoneNumber}`);
                if (((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.WRAP_UP) {
                    Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({
                        message: `Cannot make call while in wrap-up`,
                        details: `You are currently in wrap-up state. You cannot make or receive calls in wrap-up state.`,
                    });
                    return;
                }
                try {
                    yield webex.makeCall(phoneNumber);
                }
                catch (error) {
                    logger.error("DynamicsProvidor", "Failed to make call. Error:", error);
                    Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: `Failed to make call`, details: JSON.stringify(error) });
                }
            }
            else {
                logger.error("DynamicsProvidor", "Invalid phone number:", phoneNumber);
                Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: `Invalid phone number`, details: `Phone number: ${phoneNumber} is not valid.` });
            }
        });
        // Register the handlers:
        Microsoft.CIFramework.addHandler(CIF_EVENT.OnClickToAct, handleClickToAct);
        // Unregister the handlers on unmount;
        return () => {
            Microsoft.CIFramework.removeHandler(CIF_EVENT.OnClickToAct, handleClickToAct);
        };
    }, [Microsoft, webex, isInitialising]);
    // Register Webex Event Handlers:
    useEffect(() => {
        if (!Microsoft || !Xrm || isInitialising)
            return;
        logger.log("DynamicsProvidor", "useEffect: Registering routing event handlers...");
        // Handle events: AgentOfferContact, AgentContact - Search for callerId and pop matching records
        const handleAgentOfferContact = (event) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const data = event.data;
                logger.info("DynamicsProvidor", "handleAgentOfferContact: event:", event);
                const direction = data.interaction.contactDirection.type;
                if (direction === CONTACT_DIRECTION.INBOUND) {
                    const callerId = data.interaction.callAssociatedDetails.ani;
                    const searchResults = yield findAndOpenRecords(callerId, data.interaction.interactionId, CallDirection.Incoming);
                    yield popFromSearchResults(searchResults, callerId);
                }
                else {
                    const callerId = data.interaction.callAssociatedDetails.dn;
                    yield findAndOpenRecords(callerId, data.interaction.interactionId, CallDirection.Outgoing);
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleAgentOfferContact: error:", error);
            }
        });
        // Handle event: AgentContactAssigned - Create phone call activity
        const handleTaskAccepted = (event) => __awaiter(void 0, void 0, void 0, function* () {
            const data = event.data;
            logger.info("DynamicsProvidor", "handleTaskAccepted: event:", event);
            yield createPhoneCallOnHandleAnswer(data);
        });
        // handle event ContactEnded - Update phone call activity to set actual end time
        const handleContactEnded = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                const phoneCall = (_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.phoneCall;
                if (phoneCall) {
                    logger.debug("DynamicsProvidor", "handleContactEnded: Have phone call to end, setting actual end time...");
                    yield endPhoneCallActivity(phoneCall.activityid);
                }
                else {
                    logger.debug("DynamicsProvidor", "handleContactEnded: No phone call to end");
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleContactEnded: failed to end phone call. Error:", error);
            }
        });
        // Handle event: AgentWrappedUp - Close phone call activity then clear the active records
        const handleWrappedUp = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const data = event.data;
            try {
                const phoneCall = (_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.phoneCall;
                if (phoneCall) {
                    const auxCode = data.wrapUpAuxCodeId ? webex.getWrapUpCodeById(data.wrapUpAuxCodeId) : undefined;
                    const codeToSave = auxCode ? auxCode.name : (_b = data.wrapUpAuxCodeId) !== null && _b !== void 0 ? _b : "No Aux Code";
                    logger.debug("DynamicsProvidor", `handleWrappedUp: Have phone call to wrap up, closing phone call activity with code: ${codeToSave}`);
                    yield closePhoneCallActivity(phoneCall.activityid, codeToSave);
                    Xrm.Page.ui.refresh();
                }
                else {
                    logger.debug("DynamicsProvidor", "handleWrappedUp: No phone call to wrap up");
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleWrappedUp: failed to wrap up and end phone call. Error:", error);
            }
            finally {
                updateActiveRecords(undefined);
            }
        });
        webex.registerEventListener(ROUTING_TYPE.AgentOfferContact, handleAgentOfferContact);
        webex.registerEventListener(ROUTING_TYPE.AgentContact, handleAgentOfferContact);
        webex.registerEventListener(ROUTING_TYPE.AgentContactAssigned, handleTaskAccepted);
        webex.registerEventListener(ROUTING_TYPE.ContactEnded, handleContactEnded);
        webex.registerEventListener(ROUTING_TYPE.AgentWrappedUp, handleWrappedUp);
        return () => {
            webex.unregisterEventListener(ROUTING_TYPE.AgentOfferContact, handleAgentOfferContact);
            webex.unregisterEventListener(ROUTING_TYPE.AgentContact, handleAgentOfferContact);
            webex.unregisterEventListener(ROUTING_TYPE.AgentContactAssigned, handleTaskAccepted);
            webex.registerEventListener(ROUTING_TYPE.ContactEnded, handleContactEnded);
            webex.registerEventListener(ROUTING_TYPE.AgentWrappedUp, handleWrappedUp);
        };
    }, [Microsoft, Xrm, isInitialising]);
    return (React.createElement(DynamicsContext.Provider, { value: { isInitialising, isError, isReady, matchingRecords, openRecord, callHistory, customParams } }, children));
};
export const useDynamics = () => {
    const context = useContext(DynamicsContext);
    if (!context) {
        logger.error("DynamicsProvidor", "useDynamics must be used within a DynamicsProvider");
    }
    return context;
};
