import React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { AptosFontFamily } from "../../utils/CustomTokens";
import { AGENT_STATE, CALL_STATE, PHONE_STATE } from "../../services/webex/contactcenter/models/Enums";
const styles = makeStyles({
    rootHorizontal: {
        width: "100vw",
        position: "fixed",
        bottom: 0,
        left: 0,
        margin: 0,
        fontFamily: AptosFontFamily,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        boxSizing: "border-box",
    },
    rootVertical: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: tokens.colorNeutralBackground1,
        overflow: "hidden",
    },
    messageVertical: {
        width: "100%", // Full width
        height: "100%", // Full height
        writingMode: "vertical-rl", // Vertical text flow
        textAlign: "center",
        fontSize: "16px",
        letterSpacing: "2px",
        fontFamily: AptosFontFamily,
        transform: "rotate(180deg)", // Corrects text orientation
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
    },
    messageHorizontal: {
        width: "100%",
        height: "20px",
        padding: "10px",
        textAlign: "center",
        fontFamily: AptosFontFamily,
    },
    ready: {
        backgroundColor: tokens.colorPaletteLightGreenForeground3,
    },
    notReady: {
        backgroundColor: tokens.colorStatusDangerBackground3,
    },
    wrapUp: {
        backgroundColor: tokens.colorStatusWarningBackground3,
    },
    onCall: {
        backgroundColor: tokens.colorBrandBackground2,
        display: "none",
    },
    unknown: {
        backgroundColor: tokens.colorNeutralBackground3,
    },
    initialising: {
        backgroundColor: tokens.colorNeutralBackground3,
        display: "none",
    },
    notLoggedIn: {
        backgroundColor: tokens.colorStatusDangerBackground3,
    },
});
export const StatusSummary = (props) => {
    const classes = styles();
    const webex = useWebexContactCentre();
    const getStatusClass = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (webex.phoneState === PHONE_STATE.ACTIVE && ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.CONFERENCE) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.CONSULT) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_e = webex.task) === null || _e === void 0 ? void 0 : _e.callState) === CALL_STATE.INBOUND_RINGING) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_f = webex.task) === null || _f === void 0 ? void 0 : _f.callState) === CALL_STATE.OUTBOUND_RINGING) {
            return classes.onCall;
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_g = webex.task) === null || _g === void 0 ? void 0 : _g.callState) === CALL_STATE.WRAP_UP) {
            return classes.wrapUp;
        }
        else if (webex.phoneState === PHONE_STATE.NOT_LOGGED_IN) {
            return classes.notLoggedIn;
        }
        else if (((_h = webex.presence) === null || _h === void 0 ? void 0 : _h.type) === AGENT_STATE.AVAILABLE) {
            return classes.ready;
        }
        else if (((_j = webex.presence) === null || _j === void 0 ? void 0 : _j.type) === AGENT_STATE.IDLE) {
            return classes.notReady;
        }
        else {
            return classes.unknown;
        }
    };
    const getStatusMessage = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (webex.phoneState === PHONE_STATE.ACTIVE && ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED) {
            return "You're on a call.";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD) {
            return "You're on a call (Call is on hold)";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.CONFERENCE) {
            return "You're on a call (Conference)";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.CONSULT) {
            return "You're on a call (Consulting)";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_e = webex.task) === null || _e === void 0 ? void 0 : _e.callState) === CALL_STATE.INBOUND_RINGING) {
            return "You're receiving a call...";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_f = webex.task) === null || _f === void 0 ? void 0 : _f.callState) === CALL_STATE.OUTBOUND_RINGING) {
            return "You're on a call.";
        }
        else if (webex.phoneState === PHONE_STATE.ACTIVE && ((_g = webex.task) === null || _g === void 0 ? void 0 : _g.callState) === CALL_STATE.WRAP_UP) {
            return "You're in call wrap-up and not ready!";
        }
        else if (webex.phoneState === PHONE_STATE.NOT_LOGGED_IN) {
            return "You are not logged in!";
        }
        else if (((_h = webex.presence) === null || _h === void 0 ? void 0 : _h.type) === AGENT_STATE.AVAILABLE) {
            return "You are ready to take calls";
        }
        else if (((_j = webex.presence) === null || _j === void 0 ? void 0 : _j.type) === AGENT_STATE.IDLE) {
            return "You are not ready to take calls!";
        }
        else {
            return "Unknown State";
        }
    };
    if (props.vertical) {
        return (React.createElement("div", { className: classes.rootVertical },
            React.createElement("div", { className: `${classes.messageVertical} ${getStatusClass()}` }, getStatusMessage())));
    }
    return (React.createElement("div", { className: classes.rootHorizontal },
        React.createElement("div", { className: `${classes.messageHorizontal} ${getStatusClass()}` }, getStatusMessage())));
};
