import React, { memo } from "react";
import { makeStyles } from "@fluentui/react-components";
import { SpeedTransferButton } from "./SpeedTransferButton";
import { Title } from "../../Title";
const styles = makeStyles({
    section: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        width: "90%",
        alignItems: "center",
    },
});
export const SpeedTransferSection = memo((props) => {
    const classes = styles();
    return (React.createElement("div", { className: classes.section },
        React.createElement(Title, null, props.title),
        props.options.map((speedTransferOption) => (React.createElement(SpeedTransferButton, Object.assign({ key: speedTransferOption.name }, speedTransferOption, { onBeginConsult: props.onBeginConsult, onForwardCall: props.onForwardCall }))))));
});
