import React, { memo } from "react";
import { makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, SplitButton, Text, tokens } from "@fluentui/react-components";
import { CallForwardRegular, CallTransferRegular } from "@fluentui/react-icons";
import { AptosFontFamily } from "../../../utils/CustomTokens";
import { TRANSFER_RECIPIENT_TYPE } from "../../../services/webex/contactcenter/models/Enums";
const styles = makeStyles({
    fullWidth: {
        width: "100%",
    },
    buttonTextContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "0.01rem",
        width: "100%",
        alignItems: "center",
    },
    menuList: {
        backgroundColor: tokens.colorBrandBackground,
    },
    menuItem: {
        color: "white",
        backgroundColor: tokens.colorBrandBackground,
    },
    text: {
        fontFamily: AptosFontFamily,
    },
});
export const SpeedTransferButton = memo((props) => {
    const classes = styles();
    const beginForward = (destination) => {
        props.onForwardCall(destination, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
    };
    const beginConsult = (destination) => {
        props.onBeginConsult(destination, props.name, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
    };
    const menuItems = () => {
        const destinations = props.destinations;
        if (destinations.length === 0) {
            return null;
        }
        if (destinations.length === 1) {
            const destination = destinations[0];
            return (React.createElement(React.Fragment, null,
                React.createElement(MenuItem, { key: destination.value + "warm", icon: React.createElement(CallTransferRegular, null), className: classes.menuItem, onClick: () => beginConsult(destination.value) }, "Consult Call"),
                React.createElement(MenuItem, { key: destination.value + "cold", icon: React.createElement(CallForwardRegular, null), className: classes.menuItem, onClick: () => beginForward(destination.value) }, "Forward Call")));
        }
        return destinations.map((destination) => (React.createElement(React.Fragment, { key: destination.value },
            React.createElement(MenuItem, { key: destination.value + "consult", className: classes.menuItem, icon: React.createElement(CallTransferRegular, null), onClick: () => beginConsult(destination.value) }, `Consult to ${destination.type}: ${destination.value}`),
            React.createElement(MenuItem, { key: destination.value + "forward", className: classes.menuItem, icon: React.createElement(CallForwardRegular, null), onClick: () => beginForward(destination.value) }, `Forward to ${destination.type}: ${destination.value}`))));
    };
    return (React.createElement("div", { className: classes.fullWidth },
        React.createElement(Menu, { positioning: "below-end" },
            React.createElement(MenuTrigger, { disableButtonEnhancement: true }, (triggerProps) => (React.createElement(SplitButton, { menuButton: Object.assign(Object.assign({}, triggerProps), { style: { width: "100%" } }), primaryActionButton: {
                    content: props.name,
                    icon: React.createElement(CallTransferRegular, null),
                    onClick: () => beginConsult(props.destinations[0].value),
                    className: classes.fullWidth,
                }, appearance: "primary", style: { width: "100%" } },
                React.createElement("div", { className: classes.buttonTextContainer },
                    React.createElement(Text, { className: classes.text, size: 400 }, props.name),
                    props.subtitle && (React.createElement(Text, { size: 200, className: classes.text }, props.subtitle)))))),
            React.createElement(MenuPopover, null,
                React.createElement(MenuList, { className: classes.menuList }, menuItems())))));
});
