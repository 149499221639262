import { WebStorageStateStore, Log } from "@repo/oidc-client-ts";
Log.setLevel(Log.DEBUG);
Log.setLogger(console);
// calling
// export const webexCallingAuthConfig: AuthProviderProps = {
//   client_id: "Ce41703a9e1aa9492387b226da5b89f3f5989cc9a5b4372b101d36c5b2a3d2a11",
//   redirect_uri: "http://localhost:3069/redirect",
//   authority: "https://webexapis.com/v1",
//   client_secret: "91163fb68b529a911f6794c675ea350953dcd886be6b2ad42c4296dbf23dd68f",
//   scope: "openid email profile phone spark:webrtc_calling spark:calls_read spark:calls_write spark:xsi cjp:user",
//   iframeNotifyParentOrigin: "http://localhost:3069",
//   iframeScriptOrigin: "http://localhost:3069",
//   userStore: new WebStorageStateStore({ store: window.localStorage }),
// };
// contact centre
export const webexCallingAuthConfig = {
    client_id: process.env.CC_CIFV1_WEBEX_ID,
    redirect_uri: `${process.env.CC_CIFV1_SERVER_URL}/redirect`,
    authority: "https://webexapis.com/v1",
    client_secret: process.env.CC_CIFV1_WEBEX_SECRET,
    scope: "openid email profile phone cjp:user",
    iframeNotifyParentOrigin: process.env.CC_CIFV1_DYNAMICS_URL,
    iframeScriptOrigin: process.env.CC_CIFV1_SERVER_URL,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    includeIdTokenInSilentSignout: true,
    validateSubOnSilentRenew: true,
    loadUserInfo: true,
};
// if too many tokens, then go  https://idbroker-eu.webex.com/idb/profile#/tokens
