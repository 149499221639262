import React from "react";
import { Persona } from "@fluentui/react-components";
import { SettingsDrawer } from "./SettingsDrawer";
export const UserAccount = () => {
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Persona, { onClick: () => {
                setIsSettingsOpen(true);
            } }),
        React.createElement(SettingsDrawer, { isOpen: isSettingsOpen, onClose: () => setIsSettingsOpen(false) })));
};
