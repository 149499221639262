import SDKConnector from '../SDKConnector';
import { ALLOWED_SERVICES, HTTP_METHODS, SORT, SORT_BY, CALLING_BACKEND, } from '../common/types';
import log from '../Logger';
import { serviceErrorCodeHandler, getVgActionEndpoint, getCallingBackEnd } from '../common/Utils';
import { APPLICATION_JSON, CALL_HISTORY_FILE, CONTENT_TYPE, FROM_DATE, HISTORY, LIMIT, NUMBER_OF_DAYS, UPDATE_MISSED_CALLS_ENDPOINT, SET_READ_STATE_SUCCESS_MESSAGE, VERSION_1, UNIFIED_COMMUNICATIONS, CONFIG, PEOPLE, LINES, ORG_ID, DELETE_CALL_HISTORY_RECORDS_ENDPOINT, SET_DELETE_CALL_RECORDS_SUCCESS_MESSAGE, SET_DELETE_CALL_RECORDS_INVALID_DATE_FORMAT_MESSAGE, } from './constants';
import { FAILURE_MESSAGE, STATUS_CODE, SUCCESS_MESSAGE, USER_SESSIONS } from '../common/constants';
import { COMMON_EVENT_KEYS, MOBIUS_EVENT_KEYS, } from '../Events/types';
import { Eventing } from '../Events/impl';
export class CallHistory extends Eventing {
    sdkConnector;
    webex;
    janusUrl = '';
    fromDate = '';
    loggerContext = {
        file: CALL_HISTORY_FILE,
        method: 'getCallHistoryData',
    };
    userSessions;
    constructor(webex, logger) {
        super();
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.janusUrl = this.webex.internal.services._serviceUrls.janus;
        this.registerSessionsListener();
        log.setLogger(logger.level, CALL_HISTORY_FILE);
    }
    async getCallHistoryData(days = NUMBER_OF_DAYS, limit = LIMIT, sort = SORT.DEFAULT, sortBy = SORT_BY.DEFAULT) {
        const date = new Date();
        date.setDate(date.getDate() - days);
        this.fromDate = date.toISOString();
        const sortByParam = Object.values(SORT_BY).includes(sortBy) ? sortBy : SORT_BY.DEFAULT;
        const sortParam = Object.values(SORT).includes(sort) ? sort : SORT.DEFAULT;
        log.log(`Janus API URL ${this.janusUrl}`, this.loggerContext);
        log.info(`Call history from date : ${this.fromDate}`, this.loggerContext);
        log.info(`Call history sort type : ${sortParam}`, this.loggerContext);
        log.info(`Call history sortby type : ${sortByParam}`, this.loggerContext);
        const url = `${this.janusUrl}/${HISTORY}/${USER_SESSIONS}${FROM_DATE}=${this.fromDate}&limit=${limit}&includeNewSessionTypes=true&sort=${sortParam}`;
        try {
            const janusResponse = await this.webex.request({
                uri: `${url}`,
                method: HTTP_METHODS.GET,
                service: ALLOWED_SERVICES.JANUS,
            });
            this.userSessions = janusResponse.body;
            if (sortByParam === SORT_BY.START_TIME) {
                if (sortParam === SORT.DESC) {
                    this.userSessions[USER_SESSIONS].sort((element, compareElement) => new Date(compareElement[sortByParam]).getTime() -
                        new Date(element[sortByParam]).getTime());
                }
                else if (sortParam === SORT.ASC) {
                    this.userSessions[USER_SESSIONS].sort((element, compareElement) => new Date(element[sortByParam]).getTime() -
                        new Date(compareElement[sortByParam]).getTime());
                }
            }
            const callingBackend = getCallingBackEnd(this.webex);
            if (callingBackend === CALLING_BACKEND.UCM) {
                if (this.userSessions[USER_SESSIONS] && this.userSessions[USER_SESSIONS].length > 0) {
                    const hasCucmDN = this.userSessions[USER_SESSIONS].some((session) => session.self.cucmDN && session.self.cucmDN.length > 0);
                    if (hasCucmDN) {
                        const ucmLinesResponse = await this.fetchUCMLinesData();
                        if (ucmLinesResponse.statusCode === 200 && ucmLinesResponse.data.lines?.devices) {
                            const ucmLinesData = ucmLinesResponse.data.lines.devices;
                            this.userSessions[USER_SESSIONS].forEach((session) => {
                                const cucmDN = session.self.cucmDN;
                                if (cucmDN) {
                                    ucmLinesData.forEach((device) => {
                                        device.lines.forEach((line) => {
                                            if (line.dnorpattern === cucmDN) {
                                                session.self.ucmLineNumber = line.index;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                }
            }
            const responseDetails = {
                statusCode: this.userSessions[STATUS_CODE],
                data: {
                    userSessions: this.userSessions[USER_SESSIONS],
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, this.loggerContext);
            return errorStatus;
        }
    }
    async updateMissedCalls(endTimeSessionIds) {
        const loggerContext = {
            file: CALL_HISTORY_FILE,
            method: 'updateMissedCalls',
        };
        const santizedSessionIds = endTimeSessionIds.map((session) => ({
            ...session,
            endTime: new Date(session.endTime).getTime(),
        }));
        const requestBody = {
            endTimeSessionIds: santizedSessionIds,
        };
        try {
            const updateMissedCallContentUrl = `${this.janusUrl}/${HISTORY}/${USER_SESSIONS}/${UPDATE_MISSED_CALLS_ENDPOINT}`;
            const response = await fetch(updateMissedCallContentUrl, {
                method: HTTP_METHODS.POST,
                headers: {
                    [CONTENT_TYPE]: APPLICATION_JSON,
                    Authorization: await this.webex.credentials.getUserToken(),
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const data = await response.json();
            log.info(`Missed calls are succesfully read by the user`, loggerContext);
            const responseDetails = {
                statusCode: data.statusCode,
                data: {
                    readStatusMessage: SET_READ_STATE_SUCCESS_MESSAGE,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async fetchUCMLinesData() {
        const loggerContext = {
            file: CALL_HISTORY_FILE,
            method: 'fetchLinesData',
        };
        const vgEndpoint = getVgActionEndpoint(this.webex, CALLING_BACKEND.UCM);
        const userId = this.webex.internal.device.userId;
        const orgId = this.webex.internal.device.orgId;
        const linesURIForUCM = `${vgEndpoint}/${VERSION_1}/${UNIFIED_COMMUNICATIONS}/${CONFIG}/${PEOPLE}/${userId}/${LINES}?${ORG_ID}=${orgId}`;
        try {
            const response = await this.webex.request({
                uri: `${linesURIForUCM}`,
                method: HTTP_METHODS.GET,
            });
            const ucmLineDetails = {
                statusCode: Number(response.statusCode),
                data: {
                    lines: response.body,
                },
                message: SUCCESS_MESSAGE,
            };
            log.info(`Line details fetched successfully`, loggerContext);
            return ucmLineDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async deleteCallHistoryRecords(deleteSessionIds) {
        const loggerContext = {
            file: CALL_HISTORY_FILE,
            method: 'deleteCallHistoryRecords',
        };
        const invalidSessions = deleteSessionIds.filter((session) => Number.isNaN(new Date(session.endTime).getTime()));
        if (invalidSessions.length > 0) {
            const invalidSessionIds = invalidSessions.map((session) => session.sessionId).join(', ');
            log.info(`The provided date is malformed or invalid for session IDs: ${invalidSessionIds}`, loggerContext);
            return {
                statusCode: 400,
                data: {
                    deleteStatusMessage: SET_DELETE_CALL_RECORDS_INVALID_DATE_FORMAT_MESSAGE,
                },
                message: FAILURE_MESSAGE,
            };
        }
        const santizedSessionIds = deleteSessionIds.map((session) => ({
            ...session,
            endTime: new Date(session.endTime).getTime(),
        }));
        const deleteRequestBody = {
            deleteSessionIds: santizedSessionIds,
        };
        try {
            const deleteCallHistoryRecordContentUrl = `${this.janusUrl}/${HISTORY}/${USER_SESSIONS}/${DELETE_CALL_HISTORY_RECORDS_ENDPOINT}`;
            const response = await fetch(deleteCallHistoryRecordContentUrl, {
                method: HTTP_METHODS.POST,
                headers: {
                    [CONTENT_TYPE]: APPLICATION_JSON,
                    Authorization: await this.webex.credentials.getUserToken(),
                },
                body: JSON.stringify(deleteRequestBody),
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const data = await response.json();
            log.info(`Call history records are succesfully deleted by the user`, loggerContext);
            const responseDetails = {
                statusCode: data.statusCode,
                data: {
                    deleteStatusMessage: SET_DELETE_CALL_RECORDS_SUCCESS_MESSAGE,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    handleSessionEvents = async (event) => {
        if (event && event.data.userSessions.userSessions) {
            this.emit(COMMON_EVENT_KEYS.CALL_HISTORY_USER_SESSION_INFO, event);
        }
    };
    handleUserReadSessionEvents = async (event) => {
        if (event && event.data.userReadSessions.userReadSessions) {
            this.emit(COMMON_EVENT_KEYS.CALL_HISTORY_USER_VIEWED_SESSIONS, event);
        }
    };
    handleUserSessionsDeletedEvents = async (event) => {
        if (event && event.data.deletedSessions) {
            this.emit(COMMON_EVENT_KEYS.CALL_HISTORY_USER_SESSIONS_DELETED, event);
        }
    };
    registerSessionsListener() {
        this.sdkConnector.registerListener(MOBIUS_EVENT_KEYS.CALL_SESSION_EVENT_INCLUSIVE, this.handleSessionEvents);
        this.sdkConnector.registerListener(MOBIUS_EVENT_KEYS.CALL_SESSION_EVENT_LEGACY, this.handleSessionEvents);
        this.sdkConnector.registerListener(MOBIUS_EVENT_KEYS.CALL_SESSION_EVENT_VIEWED, this.handleUserReadSessionEvents);
        this.sdkConnector.registerListener(MOBIUS_EVENT_KEYS.CALL_SESSION_EVENT_DELETED, this.handleUserSessionsDeletedEvents);
    }
}
export const createCallHistoryClient = (webex, logger) => new CallHistory(webex, logger);
