import React, { memo } from "react";
import { RecordCard } from "./RecordCard";
import { RecordsSection } from "./RecordsSection";
import { CallAddRegular } from "@fluentui/react-icons";
import { formatDateTime } from "../../../utils/FormatDateTime";
import { makeStyles, tokens } from "@fluentui/react-components";
import { EntityType } from "../../../services/dynamics/types/IDynamicsContext";
const useStyles = makeStyles({
    icon: {
        color: tokens.colorStatusDangerForeground1
    }
});
export const CallBackRequestsRecords = memo((props) => {
    var _a;
    const styles = useStyles();
    return (React.createElement(RecordsSection, { title: props.title }, (_a = props.callBackRequests) === null || _a === void 0 ? void 0 : _a.map((cbr) => {
        var _a, _b, _c;
        const isUrgent = ((_a = cbr["prioritycode@OData.Community.Display.V1.FormattedValue"]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "urgent";
        const dueDate = cbr.scheduledend ? formatDateTime(new Date(cbr.scheduledend)).formattedDateTime : undefined;
        const assignedTo = (_b = cbr["_ownerid_value@OData.Community.Display.V1.FormattedValue"]) !== null && _b !== void 0 ? _b : undefined;
        const subtitle = (_c = [isUrgent ? "URGENT!" : undefined, assignedTo, dueDate ? `Due: ${dueDate}` : undefined].filter(Boolean).join(" | ")) !== null && _c !== void 0 ? _c : undefined;
        return (React.createElement(RecordCard, { key: cbr.activityid, title: cbr.subject || "Call Back Request", subtitle: subtitle, entityDisplayName: "Callback Request", entityType: EntityType.rdo_callbackrequest, entityId: cbr.activityid, icon: React.createElement(CallAddRegular, { className: styles.icon }), onOpenRecord: props.onOpenRecord }));
    })));
});
