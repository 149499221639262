import * as Media from '@webex/internal-media-core';
import { Mutex } from 'async-mutex';
import { filterMobiusUris, handleCallingClientErrors, validateServiceData } from '../common/Utils';
import { LOGGER } from '../Logger/types';
import SDKConnector from '../SDKConnector';
import { Eventing } from '../Events/impl';
import { MOBIUS_EVENT_KEYS, SessionType, CALLING_CLIENT_EVENT_KEYS, } from '../Events/types';
import { ServiceIndicator, ALLOWED_SERVICES, HTTP_METHODS, RegistrationStatus, } from '../common/types';
import log from '../Logger';
import { getCallManager } from './calling/callManager';
import { CALLING_CLIENT_FILE, CALLS_CLEARED_HANDLER_UTIL, CALLING_USER_AGENT, CISCO_DEVICE_URL, DISCOVERY_URL, GET_MOBIUS_SERVERS_UTIL, IP_ENDPOINT, SPARK_USER_AGENT, URL_ENDPOINT, NETWORK_FLAP_TIMEOUT, API_V1, MOBIUS_US_PROD, MOBIUS_EU_PROD, MOBIUS_US_INT, MOBIUS_EU_INT, } from './constants';
import Line from './line';
import { METRIC_EVENT, REG_ACTION, METRIC_TYPE } from '../Metrics/types';
import { getMetricManager } from '../Metrics';
export class CallingClient extends Eventing {
    sdkConnector;
    webex;
    mutex;
    callManager;
    metricManager;
    sdkConfig;
    primaryMobiusUris;
    backupMobiusUris;
    mobiusClusters;
    mobiusHost;
    mediaEngine;
    lineDict = {};
    constructor(webex, config) {
        super();
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.mutex = new Mutex();
        this.webex = this.sdkConnector.getWebex();
        this.sdkConfig = config;
        const serviceData = this.sdkConfig?.serviceData?.indicator
            ? this.sdkConfig.serviceData
            : { indicator: ServiceIndicator.CALLING, domain: '' };
        const logLevel = this.sdkConfig?.logger?.level ? this.sdkConfig.logger.level : LOGGER.ERROR;
        log.setLogger(logLevel, CALLING_CLIENT_FILE);
        validateServiceData(serviceData);
        this.callManager = getCallManager(this.webex, serviceData.indicator);
        this.metricManager = getMetricManager(this.webex, serviceData.indicator);
        this.mediaEngine = Media;
        this.primaryMobiusUris = [];
        this.backupMobiusUris = [];
        let mobiusServiceHost = '';
        try {
            mobiusServiceHost = new URL(this.webex.internal.services._serviceUrls.mobius).host;
        }
        catch (error) {
            log.warn(`Failed to parse mobius service URL`, {
                file: CALLING_CLIENT_FILE,
                method: this.constructor.name,
            });
        }
        this.mobiusClusters =
            (mobiusServiceHost && this.webex.internal.services._hostCatalog[mobiusServiceHost]) ||
                this.webex.internal.services._hostCatalog[MOBIUS_US_PROD] ||
                this.webex.internal.services._hostCatalog[MOBIUS_EU_PROD] ||
                this.webex.internal.services._hostCatalog[MOBIUS_US_INT] ||
                this.webex.internal.services._hostCatalog[MOBIUS_EU_INT];
        this.mobiusHost = '';
        this.registerSessionsListener();
        this.registerCallsClearedListener();
    }
    async init() {
        await this.getMobiusServers();
        await this.createLine();
        this.detectNetworkChange();
    }
    async detectNetworkChange() {
        let retry = false;
        const line = Object.values(this.lineDict)[0];
        setInterval(async () => {
            if (!this.webex.internal.mercury.connected &&
                !retry &&
                !Object.keys(this.callManager.getActiveCalls()).length) {
                log.warn(`Network has flapped, waiting for mercury connection to be up`, {
                    file: CALLING_CLIENT_FILE,
                    method: this.detectNetworkChange.name,
                });
                line.registration.clearKeepaliveTimer();
                retry = true;
            }
            if (retry && this.webex.internal.mercury.connected) {
                if (line.getStatus() !== RegistrationStatus.IDLE) {
                    retry = await line.registration.handleConnectionRestoration(retry);
                }
                else {
                    retry = false;
                }
            }
        }, NETWORK_FLAP_TIMEOUT);
    }
    async getClientRegionInfo() {
        const regionInfo = {};
        for (const mobius of this.mobiusClusters) {
            this.mobiusHost = `https://${mobius.host}${API_V1}`;
            try {
                const temp = await this.webex.request({
                    uri: `${this.mobiusHost}${URL_ENDPOINT}${IP_ENDPOINT}`,
                    method: HTTP_METHODS.GET,
                    headers: {
                        [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                        [SPARK_USER_AGENT]: CALLING_USER_AGENT,
                    },
                    service: ALLOWED_SERVICES.MOBIUS,
                });
                const myIP = temp.body.ipv4;
                const response = await this.webex.request({
                    uri: `${DISCOVERY_URL}/${myIP}`,
                    method: HTTP_METHODS.GET,
                    addAuthHeader: false,
                    headers: {
                        [SPARK_USER_AGENT]: null,
                    },
                });
                const clientRegionInfo = response.body;
                regionInfo.clientRegion = clientRegionInfo?.clientRegion
                    ? clientRegionInfo.clientRegion
                    : '';
                regionInfo.countryCode = clientRegionInfo?.countryCode ? clientRegionInfo.countryCode : '';
                break;
            }
            catch (err) {
                handleCallingClientErrors(err, (clientError) => {
                    this.metricManager.submitRegistrationMetric(METRIC_EVENT.REGISTRATION_ERROR, REG_ACTION.REGISTER, METRIC_TYPE.BEHAVIORAL, clientError);
                    this.emit(CALLING_CLIENT_EVENT_KEYS.ERROR, clientError);
                }, { method: GET_MOBIUS_SERVERS_UTIL, file: CALLING_CLIENT_FILE });
                regionInfo.clientRegion = '';
                regionInfo.countryCode = '';
            }
        }
        return regionInfo;
    }
    async getMobiusServers() {
        let useDefault = false;
        let clientRegion;
        let countryCode;
        if (this.sdkConfig?.discovery?.country && this.sdkConfig?.discovery?.region) {
            log.info('Updating region and country from the SDK config', {
                file: CALLING_CLIENT_FILE,
                method: GET_MOBIUS_SERVERS_UTIL,
            });
            clientRegion = this.sdkConfig?.discovery?.region;
            countryCode = this.sdkConfig?.discovery?.country;
            this.mobiusHost = this.webex.internal.services._serviceUrls.mobius;
        }
        else {
            log.info('Updating region and country through Region discovery', {
                file: CALLING_CLIENT_FILE,
                method: GET_MOBIUS_SERVERS_UTIL,
            });
            const regionInfo = await this.getClientRegionInfo();
            clientRegion = regionInfo.clientRegion;
            countryCode = regionInfo.countryCode;
        }
        if (clientRegion && countryCode) {
            log.log(`Found Region: ${clientRegion} and country: ${countryCode}, going to fetch Mobius server`, '');
            try {
                const temp = await this.webex.request({
                    uri: `${this.mobiusHost}${URL_ENDPOINT}?regionCode=${clientRegion}&countryCode=${countryCode}`,
                    method: HTTP_METHODS.GET,
                    headers: {
                        [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                        [SPARK_USER_AGENT]: CALLING_USER_AGENT,
                    },
                    service: ALLOWED_SERVICES.MOBIUS,
                });
                log.log('Mobius Server found for the region', '');
                const mobiusServers = temp.body;
                const mobiusUris = filterMobiusUris(mobiusServers, this.mobiusHost);
                this.primaryMobiusUris = mobiusUris.primary;
                this.backupMobiusUris = mobiusUris.backup;
                log.info(`Final list of Mobius Servers, primary: ${mobiusUris.primary} and backup: ${mobiusUris.backup}`, '');
            }
            catch (err) {
                handleCallingClientErrors(err, (clientError) => {
                    this.metricManager.submitRegistrationMetric(METRIC_EVENT.REGISTRATION_ERROR, REG_ACTION.REGISTER, METRIC_TYPE.BEHAVIORAL, clientError);
                    this.emit(CALLING_CLIENT_EVENT_KEYS.ERROR, clientError);
                }, { method: GET_MOBIUS_SERVERS_UTIL, file: CALLING_CLIENT_FILE });
                useDefault = true;
            }
        }
        else {
            useDefault = true;
        }
        if (useDefault) {
            log.warn(`Couldn't resolve the region and country code. Defaulting to the catalog entries to discover mobius servers`, '');
            this.mobiusHost = `https://${this.mobiusClusters[0].host}${API_V1}`;
            this.primaryMobiusUris = [`${this.mobiusHost}${URL_ENDPOINT}`];
        }
    }
    registerCallsClearedListener() {
        const logContext = {
            file: CALLING_CLIENT_FILE,
            method: this.registerCallsClearedListener.name,
        };
        log.log('Registering listener for all calls cleared event', logContext);
        this.callManager.on(CALLING_CLIENT_EVENT_KEYS.ALL_CALLS_CLEARED, this.callsClearedHandler);
    }
    callsClearedHandler = async () => {
        const { registration } = Object.values(this.lineDict)[0];
        if (!registration.isDeviceRegistered()) {
            await this.mutex.runExclusive(async () => {
                if (registration.isReconnectPending()) {
                    log.log('All calls cleared, reconnecting', {
                        file: CALLING_CLIENT_FILE,
                        method: CALLS_CLEARED_HANDLER_UTIL,
                    });
                    await registration.reconnectOnFailure(CALLS_CLEARED_HANDLER_UTIL);
                }
            });
        }
    };
    getLoggingLevel() {
        return log.getLogLevel();
    }
    getSDKConnector() {
        return this.sdkConnector;
    }
    registerSessionsListener() {
        this.sdkConnector.registerListener(MOBIUS_EVENT_KEYS.CALL_SESSION_EVENT_INCLUSIVE, async (event) => {
            if (event && event.data.userSessions.userSessions) {
                const sessionArr = event?.data.userSessions.userSessions;
                if (sessionArr.length === 1) {
                    if (sessionArr[0].sessionType !== SessionType.WEBEX_CALLING) {
                        return;
                    }
                }
                for (let i = 0; i < sessionArr.length; i += 1) {
                    if (sessionArr[i].sessionType !== SessionType.WEBEX_CALLING) {
                        sessionArr.splice(i, 1);
                    }
                }
                this.emit(CALLING_CLIENT_EVENT_KEYS.USER_SESSION_INFO, event);
            }
        });
    }
    async createLine() {
        const line = new Line(this.webex.internal.device.userId, this.webex.internal.device.url, this.mutex, this.primaryMobiusUris, this.backupMobiusUris, this.getLoggingLevel(), this.sdkConfig?.serviceData, this.sdkConfig?.jwe);
        this.lineDict[line.lineId] = line;
    }
    getLines() {
        return this.lineDict;
    }
    getActiveCalls() {
        const activeCalls = {};
        const calls = this.callManager.getActiveCalls();
        Object.keys(calls).forEach((correlationId) => {
            const call = calls[correlationId];
            if (!activeCalls[call.lineId]) {
                activeCalls[call.lineId] = [];
            }
            activeCalls[call.lineId].push(call);
        });
        return activeCalls;
    }
    getConnectedCall() {
        let connectCall;
        const calls = this.callManager.getActiveCalls();
        Object.keys(calls).forEach((correlationId) => {
            if (calls[correlationId].isConnected() && !calls[correlationId].isHeld()) {
                connectCall = calls[correlationId];
            }
        });
        return connectCall;
    }
}
export const createClient = async (webex, config) => {
    const callingClientInstance = new CallingClient(webex, config);
    await callingClientInstance.init();
    return callingClientInstance;
};
