import React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
const useStyles = makeStyles({
    title: {
        width: "100%",
        textAlign: "center",
        margin: 0,
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
        fontFamily: tokens.fontFamilyBase,
    }
});
export const Title = ({ children }) => {
    const styles = useStyles();
    return (React.createElement("div", { className: styles.title }, children));
};
