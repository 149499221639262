import React from "react";
import { DIAL_TYPE, DialPad } from "../DialPad/DialPad";
import { Label } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import { AptosFontFamily } from "../../utils/CustomTokens";
const styles = makeStyles({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    label: {
        fontFamily: AptosFontFamily,
        fontSize: "22px",
        margin: "15px",
    },
});
export const CallChangeTransfer = (props) => {
    const classes = styles();
    if (props.transferType === DIAL_TYPE.FORWARD || props.transferType === DIAL_TYPE.CONSULT) {
        return (React.createElement("div", { className: classes.root },
            React.createElement(Label, { className: classes.label }, props.transferType == DIAL_TYPE.FORWARD ? "Forward Call" : props.transferType == DIAL_TYPE.CONSULT ? "Transfer Call" : ""),
            React.createElement(DialPad, { type: props.transferType, onCallAction: props.onCallAction })));
    }
    else {
        return null;
    }
};
