class Logger {
    constructor() {
        this.logs = [];
        this.maxLogs = 1000;
    }
    debug(logName, message, ...optionalParams) {
        this.addLog("DEBUG", logName, message, optionalParams);
        console.debug(this.logPrefix(logName), message, ...optionalParams);
    }
    log(logName, message, ...optionalParams) {
        this.addLog("INFO", logName, message, optionalParams);
        console.log(this.logPrefix(logName), message, ...optionalParams);
    }
    info(logName, message, ...optionalParams) {
        this.addLog("INFO", logName, message, optionalParams);
        console.info(this.logPrefix(logName), message, ...optionalParams);
    }
    warn(logName, message, ...optionalParams) {
        this.addLog("WARN", logName, message, optionalParams);
        console.warn(this.logPrefix(logName), message, ...optionalParams);
    }
    error(logName, message, ...optionalParams) {
        this.addLog("ERROR", logName, message, optionalParams);
        console.error(this.logPrefix(logName), message, ...optionalParams);
    }
    exportLogs() {
        const jsonString = JSON.stringify(this.logs).replace(/\\n/g, ' ');
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `WebexEmbeddedLogs-${this.getTimestamp()}.json`;
        a.click();
        URL.revokeObjectURL(url);
    }
    getTimestamp() {
        const date = new Date();
        const utcOffset = 10 * 60; // UTC+10 in minutes
        const localDate = new Date(date.getTime() + utcOffset * 60 * 1000);
        return localDate.toISOString().replace("Z", "+10:00");
    }
    logPrefix(logName) {
        return `[RDO] [${this.getTimestamp()}] [${logName}]`;
    }
    addLog(level, logName, message, ...optionalParams) {
        if (this.logs.length >= this.maxLogs) {
            this.logs.shift(); // Remove oldest log when at max capacity
        }
        const logEntry = {
            timestamp: this.getTimestamp(),
            level,
            logName,
            message: this.buildMessageString(message, optionalParams),
        };
        this.logs.push(logEntry);
    }
    buildMessageString(message, optionalParams) {
        let messageString = "";
        if (typeof message === "string") {
            messageString = message;
        }
        else {
            messageString = JSON.stringify(message, null, 2);
        }
        if (optionalParams.length > 0) {
            messageString += "\n" + optionalParams.map((param) => JSON.stringify(param, null, 2)).join("\n");
        }
        return messageString;
    }
}
const loggerInstance = new Logger();
export default loggerInstance;
