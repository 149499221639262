var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// DialPad.tsx
import React, { useState } from "react";
import { Button, makeStyles, shorthands, Option, Persona, Combobox, } from "@fluentui/react-components";
import { CallRegular } from "@fluentui/react-icons";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { OutboundANISelector } from "../OutboundANISelector";
import { DialPadDigits } from "./DialPadDigits";
import { TRANSFER_RECIPIENT_TYPE } from "../../services/webex/contactcenter/models/Enums";
export var DIAL_TYPE;
(function (DIAL_TYPE) {
    DIAL_TYPE["OUTBOUND"] = "OUTBOUND";
    DIAL_TYPE["FORWARD"] = "FORWARD";
    DIAL_TYPE["CONSULT"] = "CONSULT";
})(DIAL_TYPE || (DIAL_TYPE = {}));
const useStyles = makeStyles({
    container: Object.assign({ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }, shorthands.padding("20px")),
    inputBox: {
        width: "100%",
    },
    callButton: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#444444", // Slightly lighter on hover
            boxShadow: "0 0 1px rgba(108, 99, 255, 1)", // Stronger glow on hover
            color: "#6C63FF",
        },
    },
});
const RESULT_LIMIT = 10;
export const DialPad = (props) => {
    const webex = useWebexContactCentre();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedDisplayName, setSelectedDisplayName] = useState(undefined);
    const [hasOutboundNumberSelected, setHasOutboundNumberSelected] = useState(false);
    const classes = useStyles();
    const handleButtonClick = (digit) => {
        setPhoneNumber((prev) => prev + digit);
    };
    const startCall = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        (_a = props.onCallAction) === null || _a === void 0 ? void 0 : _a.call(props);
        switch (props.type) {
            case DIAL_TYPE.OUTBOUND:
                yield webex.makeCall(phoneNumber);
                break;
            case DIAL_TYPE.FORWARD:
                yield webex.forwardCall(webex.task.interaction.interactionId, phoneNumber, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
                break;
            case DIAL_TYPE.CONSULT:
                yield webex.consultCall(webex.task.interaction.interactionId, phoneNumber, selectedDisplayName, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
                break;
        }
    });
    const filteredContacts = () => {
        if (phoneNumber.length === 0) {
            return [];
        }
        const searchEntryPossiblePhoneNumber = phoneNumber.replace(/[+ ()]/g, "");
        if (searchEntryPossiblePhoneNumber.match(/^[0-9]+$/)) {
            const searchNumber = searchEntryPossiblePhoneNumber.startsWith("0")
                ? searchEntryPossiblePhoneNumber.substring(1)
                : searchEntryPossiblePhoneNumber.startsWith("61")
                    ? searchEntryPossiblePhoneNumber.substring(2)
                    : searchEntryPossiblePhoneNumber;
            return webex.contacts
                .filter((contact) => contact.phoneNumbers.some((p) => p.value.replace(/[+ ()]/g, "").includes(searchNumber)))
                .slice(0, RESULT_LIMIT)
                .sort((a, b) => { var _a; return ((_a = a.displayName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.displayName || "")) || 0; });
        }
        const byName = webex.contacts
            .filter((contact) => {
            var _a, _b, _c;
            return ((_a = contact.firstName) === null || _a === void 0 ? void 0 : _a.toLowerCase().startsWith(phoneNumber.toLowerCase())) ||
                ((_b = contact.lastName) === null || _b === void 0 ? void 0 : _b.toLowerCase().startsWith(phoneNumber.toLowerCase())) ||
                ((_c = contact.displayName) === null || _c === void 0 ? void 0 : _c.toLowerCase().startsWith(phoneNumber.toLowerCase()));
        })
            .slice(0, RESULT_LIMIT)
            .sort((a, b) => { var _a; return ((_a = a.displayName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.displayName || "")) || 0; });
        if (byName.length >= RESULT_LIMIT) {
            return byName;
        }
        const byOther = webex.contacts
            .filter((contact) => { var _a, _b; return ((_a = contact.branch) === null || _a === void 0 ? void 0 : _a.toLowerCase().startsWith(phoneNumber.toLowerCase())) || ((_b = contact.title) === null || _b === void 0 ? void 0 : _b.toLowerCase().startsWith(phoneNumber.toLowerCase())); })
            .slice(0, RESULT_LIMIT - byName.length)
            .sort((a, b) => { var _a; return ((_a = a.displayName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.displayName || "")) || 0; });
        return [...byName, ...byOther];
    };
    const convertPresenceToFluentUIPresence = (presence) => {
        if (!presence)
            return undefined;
        if (["busy", "out-of-office", "away", "available", "offline", "do-not-disturb", "unknown", "blocked"].includes(presence))
            return presence;
        // Translate Webex presence to FluentUI presence
        switch (presence) {
            case "call":
                return "busy";
            case "meeting":
                return "busy";
            case "presenting":
                return "busy";
            case "OutOfOffice":
                return "out-of-office";
            case "inactive":
                return "away";
            case "active":
                return "available";
            case "DoNotDisturb":
                return "do-not-disturb";
            case "pending":
                return "unknown";
            default:
                return "unknown";
        }
    };
    const options = filteredContacts().flatMap((contact) => {
        const phoneNumbers = contact.phoneNumbers.filter((p) => p.value.length > 5);
        return phoneNumbers.map((phoneNumber, index) => {
            var _a, _b, _c;
            const name = phoneNumbers.length > 1 || phoneNumber.type === "mobile"
                ? `${contact.displayName} (${phoneNumber.type.charAt(0).toUpperCase()}${phoneNumber.type.slice(1)})`
                : contact.displayName;
            return (React.createElement(Option, { key: `${contact.id}:${index}`, text: name !== null && name !== void 0 ? name : phoneNumber.value, value: phoneNumber.value },
                React.createElement(Persona, { avatar: { image: { src: (_a = contact === null || contact === void 0 ? void 0 : contact.avatar) !== null && _a !== void 0 ? _a : undefined } }, name: name, secondaryText: contact.branch && contact.title ? `${contact.branch} ${contact.title}` : (_c = (_b = contact.branch) !== null && _b !== void 0 ? _b : contact.title) !== null && _c !== void 0 ? _c : "", tertiaryText: phoneNumber.value, presence: contact.status ? { status: convertPresenceToFluentUIPresence(contact.status) } : undefined })));
        });
    });
    const onOptionSelect = (ev, data) => {
        var _a, _b;
        setSelectedOptions(data.selectedOptions);
        setPhoneNumber((_a = data.optionValue) !== null && _a !== void 0 ? _a : "");
        setSelectedDisplayName((_b = data.optionText) !== null && _b !== void 0 ? _b : undefined);
    };
    const isValidNumber = () => {
        const entry = phoneNumber.replace(/[^+0-9]/g, "");
        if (entry.match(/^(\+?61|0[2-9])[0-9]*$/)) {
            return !!entry.match(/^(\+61[2-9]|0[2-9])[0-9]{8}$/);
        }
        return entry.length >= 4;
    };
    const startCallLabel = !hasOutboundNumberSelected
        ? "Select outbound caller ID to call"
        : !isValidNumber()
            ? "Invalid number"
            : props.type === DIAL_TYPE.FORWARD
                ? "Forward Call"
                : props.type === DIAL_TYPE.CONSULT
                    ? "Start Consult"
                    : "Call";
    return (React.createElement("div", { className: classes.container },
        React.createElement(Combobox, { className: classes.inputBox, value: phoneNumber, onOptionSelect: onOptionSelect, selectedOptions: selectedOptions, placeholder: "Search", onInput: (e) => setPhoneNumber(e.target.value), autoComplete: "off", autoCorrect: "off" }, options),
        React.createElement(DialPadDigits, { handleButtonClick: handleButtonClick }),
        React.createElement(React.Fragment, null,
            React.createElement(Button, { className: classes.callButton, appearance: "primary", onClick: startCall, icon: React.createElement(CallRegular, null), disabled: !hasOutboundNumberSelected || !isValidNumber() }, startCallLabel),
            React.createElement(OutboundANISelector, { setHasOutboundNumberSelected: setHasOutboundNumberSelected }))));
};
