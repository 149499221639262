export const CONTACTS_FILE = 'Contacts';
export const CONTACT_FILTER = 'contacts';
export const GROUP_FILTER = 'groups';
export const ENCRYPT_FILTER = 'encrypt';
export const USERS = 'Users';
export const DEFAULT_GROUP_NAME = 'Other contacts';
export const CONTACTS_SCHEMA = 'urn:cisco:codev:identity:contact:core:1.0';
export const SCIM_ID_FILTER = 'id eq';
export const OR = ' or ';
export var encryptedFields;
(function (encryptedFields) {
    encryptedFields["ADDRESS_INFO"] = "addressInfo";
    encryptedFields["AVATAR_URL"] = "avatarURL";
    encryptedFields["COMPANY"] = "companyName";
    encryptedFields["DISPLAY_NAME"] = "displayName";
    encryptedFields["EMAILS"] = "emails";
    encryptedFields["FIRST_NAME"] = "firstName";
    encryptedFields["LAST_NAME"] = "lastName";
    encryptedFields["PHONE_NUMBERS"] = "phoneNumbers";
    encryptedFields["SIP_ADDRESSES"] = "sipAddresses";
    encryptedFields["TITLE"] = "title";
})(encryptedFields || (encryptedFields = {}));
