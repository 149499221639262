import React, { memo } from "react";
import { makeStyles, Button, Caption1, Text, tokens, Card, CardPreview, Tooltip } from "@fluentui/react-components";
import { OpenRegular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    card: {
        width: "100%",
        padding: "12px 0 12px 12px",
        backgroundColor: tokens.colorNeutralBackground1,
    },
    section: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },
    horizontalCardImage: {
        width: "32px",
        height: "32px",
        flexShrink: 0,
    },
    subtitleCaption: {
        color: tokens.colorNeutralForeground2,
    },
    entityTypeCaption: {
        color: tokens.colorNeutralForeground3,
        textOverflow: "ellipsis",
    },
    cardHeader: {
        flexShrink: 1,
        flexGrow: 1,
        gridAutoColumns: "min-content 1fr min-content",
        display: "grid",
        alignItems: "center",
        "--fui-CardHeader--gap": "12px",
    },
    cardHeaderTitle: {
        display: "flex",
        textOverflow: "ellipsis",
        gridColumnStart: 2,
        gridRowStart: 1,
    },
    cardHeaderDescription: {
        display: "flex",
        flexDirection: "column",
        textOverflow: "ellipsis",
        overflow: "hidden",
        gridRowStart: 2,
        gridColumnStart: 2,
    },
    cardHeaderDescriptionOld: {
        display: "flex",
        flexDirection: "column",
    },
    cardHeaderAction: {
        gridColumnStart: 3,
        gridRowStart: "span 2",
        marginLeft: "12px",
    },
});
export const RecordCard = memo((props) => {
    const styles = useStyles();
    return (React.createElement(Card, { className: styles.card, orientation: "horizontal", appearance: "filled" },
        React.createElement(CardPreview, { className: styles.horizontalCardImage }, props.icon),
        React.createElement("div", { className: styles.cardHeader },
            React.createElement("div", { className: styles.cardHeaderTitle },
                React.createElement(Text, { weight: "semibold" }, props.title)),
            React.createElement("div", { className: styles.cardHeaderDescription },
                props.subtitle && (React.createElement(Text, { weight: "regular", size: 200, block: true, truncate: true, wrap: false }, props.subtitle)),
                React.createElement(Caption1, { className: styles.entityTypeCaption }, props.entityDisplayName)),
            React.createElement("div", { className: styles.cardHeaderAction },
                React.createElement(Tooltip, { content: `Open ${props.entityDisplayName} record`, relationship: "description", appearance: "inverted" },
                    React.createElement(Button, { appearance: "transparent", icon: React.createElement(OpenRegular, null), "aria-label": "Open Record", onClick: () => props.onOpenRecord(props.entityType, props.entityId) }))))));
});
