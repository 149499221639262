;
export var SearchType;
(function (SearchType) {
    SearchType[SearchType["Relevance"] = 0] = "Relevance";
    SearchType[SearchType["Categorized"] = 1] = "Categorized";
})(SearchType || (SearchType = {}));
export var RelationshipType;
(function (RelationshipType) {
    RelationshipType[RelationshipType["OneToMany"] = 0] = "OneToMany";
    RelationshipType[RelationshipType["ManyToMany"] = 1] = "ManyToMany";
})(RelationshipType || (RelationshipType = {}));
export var RelationshipRoleType;
(function (RelationshipRoleType) {
    RelationshipRoleType[RelationshipRoleType["Referencing"] = 1] = "Referencing";
    RelationshipRoleType[RelationshipRoleType["AssociationEntity"] = 2] = "AssociationEntity";
})(RelationshipRoleType || (RelationshipRoleType = {}));
