import React from "react";
import { StatusBar } from "../components/statusbar/StatusBar";
import { makeStyles, tokens } from "@fluentui/react-components";
import { StatusSummary } from "../components/statusbar/StatusSummary";
import { ActiveCallHeader } from "../components/activeCall/ActiveCallHeader";
import { ActiveCallDetails } from "../components/activeCall/ActiveCallDetails";
import { ActionsHub } from "../components/activeCall/ActionsHub";
import { CallChangeTransfer } from "../components/activeCall/CallChangeTransfer";
import { useWebexContactCentre } from "../services/webex/contactcenter/WebexContactCentreProvider";
import { CONTACT_DIRECTION, MEDIA_MTYPE } from "../services/webex/contactcenter/models/IRoutingMessage";
import { DIAL_TYPE } from "../components/DialPad/DialPad";
import { HeldCallBar } from "../components/activeCall/HeldCallBar";
const styles = makeStyles({
    root: {
        width: "100%",
        flexGrow: 1, // Take up the remaining space
        overflow: "hidden", // Prevent content from overflowing
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
    },
    statusSummary: {
        flexShrink: 0, // Prevent it from shrinking
        width: "100%",
        boxSizing: "border-box",
    },
    activeCallDetailsContainer: {
        flexShrink: 0,
        padding: "5px 10px",
        boxSizing: "border-box",
        width: "100%",
    },
    activeCallDetails: {
        padding: "12px 20px",
        boxSizing: "border-box",
        backgroundColor: tokens.colorNeutralBackground1Hover,
        width: "100%",
        borderRadius: "6px",
        border: `1px solid ${tokens.colorBrandForegroundInverted}`,
    },
});
export var SELECTED_CALL_CONTROL;
(function (SELECTED_CALL_CONTROL) {
    SELECTED_CALL_CONTROL["CONSULT"] = "CONSULT";
    SELECTED_CALL_CONTROL["FORWARD"] = "FORWARD";
    SELECTED_CALL_CONTROL["DIAL_PAD"] = "DIAL_PAD";
})(SELECTED_CALL_CONTROL || (SELECTED_CALL_CONTROL = {}));
export const OnCallPage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    const classes = styles();
    const webex = useWebexContactCentre();
    const [selectedControl, setSelectedControl] = React.useState(undefined);
    const onTransfer = () => {
        if (selectedControl === SELECTED_CALL_CONTROL.CONSULT) {
            setSelectedControl(undefined);
        }
        else {
            setSelectedControl(SELECTED_CALL_CONTROL.CONSULT);
        }
    };
    const onForward = () => {
        if (selectedControl === SELECTED_CALL_CONTROL.FORWARD) {
            setSelectedControl(undefined);
        }
        else {
            setSelectedControl(SELECTED_CALL_CONTROL.FORWARD);
        }
    };
    const onDialPad = () => {
        if (selectedControl === SELECTED_CALL_CONTROL.DIAL_PAD) {
            setSelectedControl(undefined);
        }
        else {
            setSelectedControl(SELECTED_CALL_CONTROL.DIAL_PAD);
        }
    };
    const callerId = ((_a = webex.task) === null || _a === void 0 ? void 0 : _a.interaction.contactDirection.type) === CONTACT_DIRECTION.OUTBOUND
        ? (_c = (_b = webex.task) === null || _b === void 0 ? void 0 : _b.interaction.callAssociatedDetails.dn) !== null && _c !== void 0 ? _c : "Unknown"
        : ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.interaction.contactDirection.type) === CONTACT_DIRECTION.INBOUND
            ? (_f = (_e = webex.task) === null || _e === void 0 ? void 0 : _e.interaction.callAssociatedDetails.ani) !== null && _f !== void 0 ? _f : "Unknown"
            : "Unknown";
    const callFor = () => {
        var _a, _b, _c, _d, _e;
        const callVars = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.contactCenterVariables;
        if (((_b = webex.task) === null || _b === void 0 ? void 0 : _b.interaction.contactDirection.type) !== CONTACT_DIRECTION.INBOUND) {
            return undefined;
        }
        if (callVars === null || callVars === void 0 ? void 0 : callVars.CallFor) {
            return (_c = webex.task) === null || _c === void 0 ? void 0 : _c.contactCenterVariables.CallFor;
        }
        if ((callVars === null || callVars === void 0 ? void 0 : callVars.Branch) && (callVars === null || callVars === void 0 ? void 0 : callVars.Department)) {
            return `${callVars.Branch} ${callVars.Department}`;
        }
        if (callVars === null || callVars === void 0 ? void 0 : callVars.Branch) {
            return callVars.Branch;
        }
        return (_e = (_d = webex.task) === null || _d === void 0 ? void 0 : _d.interaction.callAssociatedDetails.dn) !== null && _e !== void 0 ? _e : "Unknown";
    };
    ((_g = webex.task) === null || _g === void 0 ? void 0 : _g.interaction.contactDirection.type) === CONTACT_DIRECTION.INBOUND
        ? (_l = (_j = (_h = webex.task) === null || _h === void 0 ? void 0 : _h.contactCenterVariables.CallFor) !== null && _j !== void 0 ? _j : (_k = webex.task) === null || _k === void 0 ? void 0 : _k.interaction.callAssociatedDetails.dn) !== null && _l !== void 0 ? _l : "Unknown"
        : undefined;
    return (React.createElement("div", { className: classes.root },
        React.createElement(StatusBar, null),
        React.createElement(HeldCallBar, null),
        React.createElement(ActiveCallHeader, { onForward: onForward, onTransfer: onTransfer, selectedControl: selectedControl, onDialPad: onDialPad }),
        selectedControl === SELECTED_CALL_CONTROL.CONSULT || selectedControl === SELECTED_CALL_CONTROL.FORWARD ? (React.createElement(CallChangeTransfer, { onCallAction: () => setSelectedControl(undefined), transferType: selectedControl === SELECTED_CALL_CONTROL.CONSULT ? DIAL_TYPE.CONSULT : DIAL_TYPE.FORWARD })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.activeCallDetailsContainer }, ((_m = webex.task) === null || _m === void 0 ? void 0 : _m.activeMedias) && ((_o = webex.task) === null || _o === void 0 ? void 0 : _o.activeMedias.length) > 1 ? (React.createElement(React.Fragment, null,
                React.createElement(ActiveCallDetails, { className: classes.activeCallDetails, callDirection: (_p = webex.task) === null || _p === void 0 ? void 0 : _p.interaction.contactDirection.type, customerDescription: (_q = webex.task) === null || _q === void 0 ? void 0 : _q.contactCenterVariables.CustomerDisplayName, callerId: callerId, customerAccount: (_r = webex.task) === null || _r === void 0 ? void 0 : _r.contactCenterVariables.CustomerAccountNumber, customerBranch: (_s = webex.task) === null || _s === void 0 ? void 0 : _s.contactCenterVariables.CustomerBranch, callFor: callFor() }),
                React.createElement("br", null),
                React.createElement(ActiveCallDetails, { className: classes.activeCallDetails, callDirection: (_t = webex.task) === null || _t === void 0 ? void 0 : _t.interaction.contactDirection.type, callerId: (_w = (_v = (_u = webex.task) === null || _u === void 0 ? void 0 : _u.consultCallDetails) === null || _v === void 0 ? void 0 : _v.destination) !== null && _w !== void 0 ? _w : "Unknown", toDescription: (_y = (_x = webex.task) === null || _x === void 0 ? void 0 : _x.consultCallDetails) === null || _y === void 0 ? void 0 : _y.displayName, isConsultLeg: true }))) : ((_z = webex.task) === null || _z === void 0 ? void 0 : _z.activeMedias[0].mType) === MEDIA_MTYPE.CONSULT ? (React.createElement(ActiveCallDetails, { className: classes.activeCallDetails, callDirection: (_0 = webex.task) === null || _0 === void 0 ? void 0 : _0.interaction.contactDirection.type, callerId: (_3 = (_2 = (_1 = webex.task) === null || _1 === void 0 ? void 0 : _1.consultCallDetails) === null || _2 === void 0 ? void 0 : _2.destination) !== null && _3 !== void 0 ? _3 : "Unknown", toDescription: (_5 = (_4 = webex.task) === null || _4 === void 0 ? void 0 : _4.consultCallDetails) === null || _5 === void 0 ? void 0 : _5.displayName, isConsultLeg: true })) : (React.createElement(ActiveCallDetails, { className: classes.activeCallDetails, callDirection: (_6 = webex.task) === null || _6 === void 0 ? void 0 : _6.interaction.contactDirection.type, customerDescription: (_7 = webex.task) === null || _7 === void 0 ? void 0 : _7.contactCenterVariables.CustomerDisplayName, callerId: callerId, customerAccount: (_8 = webex.task) === null || _8 === void 0 ? void 0 : _8.contactCenterVariables.CustomerAccountNumber, customerBranch: (_9 = webex.task) === null || _9 === void 0 ? void 0 : _9.contactCenterVariables.CustomerBranch, callFor: callFor() }))))),
        React.createElement(ActionsHub, null),
        React.createElement("div", { className: classes.statusSummary },
            React.createElement(StatusSummary, null))));
};
