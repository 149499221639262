import React from "react";
import { DrawerBody, DrawerHeader, DrawerHeaderTitle, Drawer, Button, makeStyles, tokens, Persona, Text, Link } from "@fluentui/react-components";
import { ArrowExitRegular, Dismiss24Regular } from "@fluentui/react-icons";
import { useAuth } from "@repo/react-oidc-context";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import logger from "../../utils/Logger";
import { AptosFontFamily, ScrollBarStyle } from "../../utils/CustomTokens";
const supportEmail = process.env.CC_CIFV1_SUPPORT_EMAIL || "itsolutions@rdo.com.au";
const useStyles = makeStyles({
    personaContainer: {
        display: "flex",
        gap: "10px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    persona: {
        flexGrow: 1,
    },
    settingsContainer: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        marginTop: "20px",
    },
    audioSection: {
        marginBottom: "15px",
    },
    audioLabelContainer: {
        fontFamily: AptosFontFamily,
        padding: "10px 0",
    },
    audioLabel: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "6px",
    },
    sectionHeader: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "20px",
    },
    audioSubLabel: {
        fontSize: "12px",
        color: tokens.colorNeutralForeground2,
    },
    audioSelectionSection: {
        padding: "10px",
        backgroundColor: "#262632",
        fontSize: "12px",
        maxHeight: "250px",
        overflowY: "auto",
        borderRadius: "4px",
        minHeight: "100px",
    },
    audioSelectionItem: {
        fontSize: "12px",
    },
    drawerBody: Object.assign({}, ScrollBarStyle),
    downloadLogsButton: {
        marginTop: "20px",
    }
});
export const SettingsDrawer = (props) => {
    var _a, _b, _c;
    const styles = useStyles();
    const auth = useAuth();
    const webex = useWebexContactCentre();
    const [hasDownloadedLogs, setHasDownloadedLogs] = React.useState(false);
    // useEffect(() => {
    //   media.refreshDevices;
    // }, []);
    // const onMicrophoneChange = (event: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
    //   media.setSelectedDevices({ microphoneId: data.value });
    // };
    // const onSpeakerChange = (event: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
    //   media.setSelectedDevices({ speakerId: data.value });
    // };
    // const onNotificationSpeakerChange = (event: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
    //   media.setSelectedDevices({ notificationSpeakerId: data.value });
    // };
    const downloadLogs = () => {
        logger.exportLogs();
        setHasDownloadedLogs(true);
    };
    return (React.createElement(Drawer, { position: "end", separator: true, size: "medium", open: props.isOpen, onOpenChange: (_, { open }) => {
            if (!open) {
                props.onClose();
            }
        } },
        React.createElement(DrawerHeader, null,
            React.createElement(DrawerHeaderTitle, { action: React.createElement(Button, { appearance: "subtle", "aria-label": "Close", icon: React.createElement(Dismiss24Regular, null), onClick: props.onClose }) },
                React.createElement("div", { className: styles.personaContainer },
                    React.createElement(Persona, { textAlignment: "center", className: styles.persona, name: (_c = (_b = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "Unknown User" }),
                    React.createElement(Button, { appearance: "subtle", icon: React.createElement(ArrowExitRegular, null), onClick: webex.logout }, "Logout")))),
        React.createElement(DrawerBody, { className: styles.drawerBody },
            React.createElement("div", { className: styles.settingsContainer },
                React.createElement("div", { className: styles.sectionHeader }, "Collect Logs"),
                React.createElement("div", { className: styles.audioSection },
                    React.createElement(Text, null, "If you are experiencing any issues with this application, please download the logs using the button below."),
                    React.createElement("br", null),
                    React.createElement(Text, null,
                        "Send the logs to ",
                        React.createElement(Link, { href: `MAILTO:${supportEmail}?subject=Issue with Dynamics Webex Embedded&body=See attached logs` }, supportEmail),
                        " with a detailed description of what went wrong and what you expect should have happened."),
                    React.createElement("br", null),
                    React.createElement(Text, null, "The more detail you provide the faster we can diagnose the issue for you."),
                    React.createElement("div", { className: styles.downloadLogsButton },
                        React.createElement(Button, { onClick: downloadLogs }, "Download Logs")))))));
};
