var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Button, makeStyles, Text, Spinner } from "@fluentui/react-components";
import { useAuth } from "@repo/react-oidc-context";
import { RocketRegular } from "@fluentui/react-icons";
import { useWebexContactCentre } from "../services/webex/contactcenter/WebexContactCentreProvider";
import { SwoopImage } from "../components/SwoopImage";
import logger from "../utils/Logger";
const styles = makeStyles({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        margin: "0",
        padding: "0",
    },
});
export const LoginPage = () => {
    const classes = styles();
    const auth = useAuth();
    const webex = useWebexContactCentre();
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    // handle logging into webex (Azure SSO)
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const width = 500;
        const height = 700;
        if (!auth.isAuthenticated) {
            logger.debug("LoginPage", "handleLogin. First, auth.signinPopup() as auth is not authenticated...");
            yield auth.signinPopup({
                popupWindowFeatures: { width, height, toolbar: false, location: false, menubar: false, resizable: false },
            });
            logger.debug("LoginPage", "handleLogin. Logged in successfully...");
        }
        else if (((_b = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.exp) && auth.user.profile.exp * 1000 < Date.now()) {
            auth.removeUser();
            yield auth.signinPopup({
                popupWindowFeatures: { width, height, toolbar: false, location: false, menubar: false, resizable: false },
            });
            logger.debug("LoginPage", "handleLogin. Logged in but ID Token expired so logging in again...");
        }
        else {
            logger.debug("LoginPage", "handleLogin - Already logged in and ready to go");
        }
        logger.debug("LoginPage", "handleLogin. Then, await webex.login()...");
        setTimeout(webex.login, 1000);
    });
    return (React.createElement("div", { className: classes.root },
        React.createElement(Button, { disabled: auth.isLoading, appearance: "primary", shape: "circular", size: "large", icon: React.createElement(RocketRegular, null), onClick: handleLogin }, auth.isLoading ? "Logging in..." : "Login with Webex"),
        auth.isLoading && (React.createElement(React.Fragment, null,
            React.createElement(Text, null, "A popup window has opened for you to log in."),
            React.createElement(Spinner, { size: "large" }))),
        React.createElement(SwoopImage, null)));
};
