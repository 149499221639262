import React from "react";
import { CallHistoryItem } from "./CallHistoryItem";
import { makeStyles, tokens } from "@fluentui/react-components";
import { AptosFontFamily, ScrollBarStyle } from "../../utils/CustomTokens";
import { useDynamics } from "../../services/dynamics/DynamicsProvidor";
const useStyles = makeStyles({
    title: {
        fontWeight: "semibold",
        fontSize: "14px",
        fontFamily: AptosFontFamily,
        backgroundColor: tokens.colorBrandBackground,
        width: "100%",
        padding: "14px",
        textAlign: "center",
    },
    section: Object.assign({ width: "100%", display: "flex", flexDirection: "column", gap: "0", margin: "0 20px 40px 20px", overflowX: "hidden" }, ScrollBarStyle),
});
export const CallHistoryList = () => {
    const styles = useStyles();
    const dynamics = useDynamics();
    const callHistory = dynamics.callHistory;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.title }, "Recent Call History"),
        React.createElement("section", { className: styles.section }, callHistory.map((call) => {
            return (React.createElement(CallHistoryItem, { key: call.timestamp, type: call.type, status: call.status, destination: call.destination, destinationDescription: call.destinationDescription, origin: call.origin, orginDescription: call.originDescription, timestamp: call.timestamp }));
        }))));
};
